.companyCalendar {
  &__categories-block {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: auto 0 0;
    padding: 8px;
  }

  &__categories {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 2px;
    column-gap: 4px;
    margin: 0;
  }

  &__category {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 5px 6.5px;
    min-width: 42px;
    border-radius: 40px;
    border: 1px solid $border-secondary-color;
    color: $main-text-color;
    background-color: $blocks-bg-color;
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
      transition: all 0.3s ease-in-out;
      background-color: $text-links-color;
      border: 1px solid $text-links-color;
      color: $blocks-bg-color;
    }
  }

  &__category-symbol {
    font-size: 11px;
  }

  &__category-item-title {
    font-size: 12px;
    line-height: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &--bold {
      font-weight: bold;
    }
  }

  &__cell {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  &__team-users {
    &:first-child {
      margin-bottom: 16px;
    }

    .user-avatar {
      width: 32px;
      height: 32px;
      min-width: 32px;
      margin: 0;
    }

    .activeStatusIndicator::after {
      width: 12px;
      height: 12px;
    }
  }

  &__team-users-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
    margin: 0;
  }

  &__team-users-item {
    border-radius: 10px;
    min-width: 51px;
    padding: 6px;
    gap: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $button-selected-color;
    cursor: pointer;

    &--anniversary {
      background-color: $highlight-soft-yellow-color;
    }

    &--highlight,
    &--highlight-birthday {
      background-color: $yellow-border-label-color;

      .companyCalendar__team-user-date {
        color: $blocks-bg-color;
        font-weight: bold;
        text-align: center;
      }
    }

    &--highlight-birthday {
      background-color: $blue-color;
    }

    &--current {
      cursor: default;
    }
  }

  &__team-users-title {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 16px 0 10px;

    h3 {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      color: $text-links-color;
    }
  }

  &__team-users-icon {
    width: 12px;
    height: 12px;
  }

  &__team-user-date {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: $text-links-color;
  }

  &__team-users-button {
    border: 1px solid $button-selected-color;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    line-height: 16px;
    color: $blue-label-color;
    height: 66px;
  }

  &__block-title {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 16px;
    position: sticky;
  }

  &__block-icon {
    width: 28px;
    height: 28px;
  }

  &__block-list {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .seeMoreReceivedItemsBtn {
      margin-top: 24px;
    }
  }

  &__block-item {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 6px 12px;

    .achievementCard__label {
      padding: 4px 10px;
      font-size: 12px;
      line-height: 14px;
      color: $main-text-color;
    }
  }

  &__block-name {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: $text-links-color;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
  }

  &__block-checkbox {
    height: 16px;
    color: $blue-label-text;
    background-color: $blocks-bg-color;
  }

  &__block-wrapper {
    padding: 16px 0 16px 24px;
    border-radius: 10px;
    background-color: $blocks-bg-color;
    position: relative;
    height: 300px;

    &--gradient,
    &--gradient-team {
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          #ffffff 72.5%,
          #ffffff 74.5%
        );
        width: 97%;
        height: 74px;
        pointer-events: none;
      }
    }
  }

  &__block {
    position: relative;
    max-height: 236px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: $border-secondary-color $blocks-bg-color;
    padding-right: 24px;
  }

  .title {
    position: sticky;
  }

  &__user-tooltip {
    visibility: hidden;
  }
}

@media screen and (max-width: 1900px) {
  .companyCalendar {
    &__block-wrapper {
      height: auto;
    }
  }
}

@media screen and (max-width: 1200px) {
  .companyCalendar {
    &__block-wrapper {
      width: 100%;
    }
  }
}
