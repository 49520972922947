@import "../theme-colors";

.pageTabs {
  width: fit-content;
  border-radius: 50px;
  border: 1px solid $border-secondary-color;
  padding: 4px;
  display: flex;
  background-color: #fff;

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 24px;
    cursor: pointer;
    border-radius: 50px;
    background-color: #fff;
    color: $text-links-color;
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    transition: background-color 0.3s, color 0.3s;

    &:hover {
      background-color: $border-secondary-color;
    }

    &:focus,
    &:active,
    &:hover {
      color: $text-links-color;
    }
  }
  &__link.selected {
    background: $text-links-color;
    color: #fff;
  }

  &__hiddenLink {
    display: none;
    background-color: $border-color;
  }
}

@media screen and (max-width: 600px) {
  .pageTabs {
    &__link {
      padding: 5px 16px;
    }
  }
}