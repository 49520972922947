@import "../../../theme-colors";

.merchDelivery {
  .filtersBlock {
    margin-bottom: 80px;

    .group-btn {
      flex-wrap: wrap;
    }
  }
}

.orderStatistics {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;

  &__block {
    border-radius: 8px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__block.created-orders {
    background-color: #ecf4ff;
  }

  &__block.in-progress-orders {
    background-color: #ffedc8;
  }

  &__block.delivered-orders {
    background-color: #dff5ee;
  }

  &__block.cancelled-orders {
    background-color: #faebeb;
  }

  &__title {
    font-size: 16px;
  }

  &__count {
    font-size: 20px;
    font-weight: bold;
  }
}

.ordersTable {
  margin-top: 32px;

  .table-wrap .table-inner {
    min-width: 1200px;

    .item-col:nth-child(1) {
      width: 100%;
      min-width: 5%;
    }
    .item-col:nth-child(2) {
      width: 100%;
      min-width: 17%;
    }
    .item-col:nth-child(3),
    .item-col:nth-child(4),
    .item-col:nth-child(5),
    .item-col:nth-child(6),
    .item-col:nth-child(7) {
      width: 100%;
      min-width: 12%;
    }
    .item-col:nth-child(8) {
      width: 100%;
      min-width: 18%;
      justify-content: flex-end;
      gap: 10px;
    }
    .btn-processing {
      background-color: #ecf4ff;
    }
    .btn-edit {
      background-color: #ffedc8;
    }
    .btn-delivered {
      background-color: #dff5ee;
    }
    .btn-cancelled {
      background-color: #faebeb;
    }
  }
}

.popup-order-view {
  max-width: 800px;
  width: 100%;

  .popup-footer .group-btn {
    display: flex;
  }
}

.popup-order-edit {
  .orderDetailsWrap {
    padding: 30px 20px 30px 0;
    border-radius: 20px;
  }
  .pageTabs {
    margin-top: 24px;
  }
}

.popup-order-edit .wrap .orderDetailBtn {
  max-width: max-content;
}

.popup-order-view,
.popup-order-edit {
  .order-view-wrap {
    justify-content: space-between;
    display: flex;
    align-items: initial;
    flex-direction: column;
    height: 100%;
    padding-bottom: 50px;
  }

  .userBlock {
    margin-top: 30px;
    justify-content: flex-start;
  }

  .merchDeliveryBlock,
  .orderInfo {
    margin-top: 30px;
  }

  .merchBlock {
    margin: 30px 0 20px;
    border: 1px solid #ebf2f7;
    padding: 16px;
    display: grid;
    grid-template-columns: 70px 30% 30% 1fr;
    gap: 20px;

    &__img {
      max-width: 70px;
      max-height: 70px;
      width: auto;
      height: auto;
    }

    &__name {
      font-size: 14px;
      line-height: 21px;
      font-weight: bold;
    }

    &__coins {
      font-size: 22px;
      line-height: 21px;
      font-weight: bold;
    }
  }
  .categoryText__aboutTitle {
    color: $text-links-color;
  }
  .categoryText__info {
    margin-top: 10px;
  }
}

@media screen and (max-width: 950px) {
  .orderStatistics {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 800px) {
  .merchDelivery {
    margin-top: 20px;
    overflow: hidden;
  }
  .popup-order-view,
  .popup-order-edit {
    .userBlock {
      margin-top: 0;
    }
    .merchBlock {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      gap: 15px;
    }
  }
}

@media screen and (max-width: 400px) {
  .orderStatistics {
    gap: 15px;

    &__title {
      font-size: 14px;
    }
  }
}
