.impersonation-panel {
  position: fixed;
  right: 0;
  top: 10px;
  display: flex;
  align-items: center;
  background: #fff;
  padding: 5px 30px 5px 5px;
  border-radius: 25px 0 0 25px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  cursor: pointer;
  z-index: 10;

  &:hover {
    box-shadow: none;
  }

  .ic-back {
    box-shadow: none;
    background: #3e58f0;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    transition: all 0.3s ease;
    cursor: pointer;
    color: #fff;

    svg {
      width: 24px;
      height: 24px;
    }
  }
}

@media screen and (max-width: 800px) {
  .impersonation-panel {
    top: 80px;
  }
}
