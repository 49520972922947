.uploadPhotos {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 24px;

  &__requirements {
    color: $secondary-text-color;
    font-size: 14px;
    line-height: 20px;

    p + p {
      margin-top: 4px;
    }
  }
}

.uploadPhotosWrap {
  margin-top: 32px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 32px;
  row-gap: 24px;

  .boxItemImg {
    justify-content: center;
  }

  .boxItemImg__imgWrap {
    padding: 0;
    position: relative;

    img {
      width: auto;
      border-radius: 10px;
    }
  }

  .uploadPhotosWrap__menu {
    position: absolute;
    padding: 10px;
    border-radius: 10px;
    top: 16px;
    right: 16px;
    background-color: $blocks-bg-color;

    &:hover {
      background-color: $border-color;
    }
  }

  &__cover-icon {
    position: absolute;
    top: 16px;
    left: 16px;
    border-radius: 10px;
    background-color: $text-links-color;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;

    .ic-setCover {
      color: $blocks-bg-color;
    }
  }
}

.customPopover.uploadPhotosWrap__popover {
  padding: 16px;
  z-index: 1000;

  .editCrop {
    max-width: 150px;
    text-align: left;
  }
}

@media screen and (max-width: 650px) {
  .uploadPhotos {
    display: flex;
    flex-direction: column-reverse;
    gap: 16px;
  }
  .uploadPhotosWrap {
    grid-template-columns: 250px;
    width: max-content;
    margin: 32px auto 0;
  }
}
