@import "../theme-colors";

.sidebar {
  width: 280px;
  background-color: $main-bg-color;
  position: fixed;
  border-right: 2px solid $border-color;
  height: 100%;
  overflow: auto;
  padding: 32px 19px 32px 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  scrollbar-width: thin;
  scrollbar-color: $border-secondary-color #fff;

  .magicButtonWrap {
    display: none;
  }

  &__policyLinkWrap {
    display: flex;
    flex-wrap: wrap;
  }

  &__policyLink {
    text-align: center;
    display: block;
    text-decoration: underline;
    color: $secondary-text-color;
    font-weight: 400;
    font-size: 12px;
  }

  &__policyLink:first-child {
    margin-right: 24px;
  }

  &__control {
    display: none;
  }

  .logo {
    display: block;
    margin-bottom: 48px;

    img {
      display: block;
      max-width: 100%;
      width: auto;
      max-height: 56px;
      height: auto;
    }
  }
  .user {
    display: flex;
    align-items: center;
    gap: 10px;

    p + p {
      margin-top: 0;
    }
  }
  .user-avatar {
    min-width: 90px;
    margin-right: 0;
    width: 90px;
    height: 90px;
  }
  .user-name {
    font-size: 16px;
    line-height: 24px;
    max-width: 130px;
  }
  .activeStatusIndicator::after {
    width: 25px;
    height: 25px;
    bottom: 0;
  }
  .profileLink {
    display: block;
    margin-top: 8px;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $secondary-text-color;
  }
  .close-sidebar {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }

  .logout-btn {
    color: $text-links-color;
    margin-bottom: 24px;
  }

  .menu {
    margin-top: 48px;

    &__item {
      color: $text-links-color;
      margin-bottom: 32px;
    }

    &__link {
      cursor: pointer;
    }

    .menu__link:hover, .menu__link:active,
    .menu__link:focus {
      .menu__icon {
        color: $text-links-color;
      }
    }

    &__groupLink {
      cursor: pointer;
      display: block;
      margin-top: 16px;
      position: relative;
    }

    &__groupLink::before {
      content: url("../../sprite/linkMenuVector.svg");
      position: absolute;
      width: 25px;
      height: 54px;
      fill: none;
      left: -37px;
      bottom: 8px;
    }

    &__groupLink:first-child::before {
      content: url("../../sprite/firstLinkMenuVector.svg");
      height: 25px;
      bottom: 11px;
    }

    &__icon {
      width: 24px;
      height: 24px;
      display: inline-block;
      margin-right: 24px;
    }

    &__groupLink,
    &__linkName {
      font-family: "Open Sans", sans-serif;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
    }

    .arrowIcon {
      width: 24px;
      height: 24px;
      transition: transform 0.3s;
    }

    .arrowIcon.active {
      transform: rotate(180deg);
    }

    &__itemContent {
      display: flex;
      align-items: center;
    }

    &__navLinks {
      margin-left: 48px;
    }
  }
}

.sidebar::-webkit-scrollbar {
  width: 16px;
}
.sidebar::-webkit-scrollbar-track {
  background: #fff;
}
.sidebar::-webkit-scrollbar-thumb {
  width: 10px;
  background: $border-secondary-color;
  border-radius: 10px;
}

@media screen and (max-width: 1000px) {
  .sidebar {
    width: 225px;
    padding: 32px 19px;

    .user-avatar {
      width: 60px;
      min-width: 60px;
      height: 60px;
    }
    .user-name {
      font-size: 14px;
      line-height: 20px;
      max-width: 120px;
    }
    .activeStatusIndicator::after {
      width: 16px;
      height: 16px;
    }
    .menu {
      &__linkName {
        font-size: 14px;
        line-height: 20px;
      }

      &__groupLink {
        font-size: 12px;
        line-height: 18px;
      }

      &__groupLink::before {
        left: -28px;
        bottom: 6px;
      }

      &__groupLink:first-child::before {
        bottom: 8px;
      }

      &__icon {
        width: 20px;
        height: 20px;
        margin-right: 14px;
      }

      &__navLinks {
        margin-left: 36px;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .sidebar {
    width: 100%;
    opacity: 0;
    z-index: -1;
    transition: opacity 0.3s;
    height: 100vh;

    &__control {
      display: flex;
    }

    .logo {
      display: none;
    }
    .user {
      margin-top: 20px;
      justify-content: flex-start;
      gap: 20px;

      .userInfo {
        display: flex;
        gap: 4px;
      }
      .user-name {
        max-width: 100%;
      }
    }
    .user-avatar {
      width: 62px;
      min-width: 62px;
      height: 62px;
    }
    .user-avatar:after {
      width: 22px;
      height: 22px;
    }
    .menu {
      margin-top: 30px;

      &__linkName,
      &__groupLink {
        font-size: 16px;
        line-height: 24px;
      }

      &__groupLink::before {
        left: -37px;
        bottom: 8px;
      }

      &__groupLink:first-child::before {
        bottom: 11px;
      }

      &__icon {
        width: 24px;
        height: 24px;
        margin-right: 24px;
      }

      &__navLinks {
        margin-left: 48px;
      }
    }
  }
  .sidebar.active {
    z-index: 6;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: 1;

    .magicButtonWrap {
      display: block;

      .magicButton {
        width: 100%;
        margin: 32px 0;
      }
    }
  }
}
