@import "../theme-colors";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
  gap: 50px;

  &__info {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  &__btn {
    position: relative;
    border-radius: 50%;
    background-color: #fff;
    border: 2px solid $border-secondary-color;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__badge {
    position: absolute;
    top: -7px;
    right: -7px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    border: 2px solid #fff;
    background: $yellow-border-label-color;
  }

  &__count {
    margin-left: 22px;
    border: 2px solid $border-secondary-color;
    min-width: 87px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
    border-radius: 20px;
    color: $text-links-color;
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.95px;

    .ic-coins {
      margin-right: 10px;
    }
  }

  &__notification {
    width: 22px;
    height: 22px;
  }

  .search-label {
    display: flex;
    align-items: center;

    .ic-person,
    .ic-category,
    .ic-calendar  {
      min-width: 24px;
      margin-right: 16px;
    }
  }

  .asyncSelect__container.layoutSearchBar {
    max-width: 720px;
  }
  .layoutSearchBar {
    .asyncSelect__control {
      border-radius: 20px;
      box-shadow: 0 0 1px 0 #e9eff5 inset;
      border: 2px solid $border-color;
  
      &:hover {
        border: 2px solid $border-color;
      }
    }
    .asyncSelect__value-container {
      padding: 8px 18px 10px;
    }
    .asyncSelect__placeholder {
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.438px;
    }
    .asyncSelect__single-value {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.438px;
    }
    .asyncSelect__input-container {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.438px;
    }
    .asyncSelect__menu {
      border: 2px solid $border-color;
    }
  }
  
  .burger {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}

.orgChartHeader {
  margin-bottom: 20px;
  padding: 0 44px;
}

@media screen and (max-width: 1200px) {
  .header {
    gap: 22px;
  }
}

@media screen and (max-width: 800px) {
  .orgChartHeader {
    margin-bottom: 20px;
    padding: 0 19px;
  }

  .header .magicButtonWrap {
    display: none;
  }
}

@media screen and (max-width: 400px) {
  .header {
    gap: 15px;
  }
}
