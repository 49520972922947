@import "../theme-colors";

.cd-popup {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(21, 39, 68, 0.4);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s 0s, visibility 0s 0.3s;

  &.is-visible {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s 0s, visibility 0s 0s;
    z-index: 20;
  }

  &-container {
    position: relative;
    padding: 48px 32px 32px;
    width: 90%;
    max-width: 440px;
    margin: 4em auto;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    transform: translateY(-40px);
    transition-duration: 0.3s;

    .cd-popup-close {
      background: transparent;
      position: absolute;
      top: 12px;
      right: 12px;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    @media only screen and (min-width: 1170px) {
      margin: 8em auto;
    }
  }

  &__title {
    text-align: center;
    font-size: 24px;
    line-height: 32px;
    font-weight: bold;
    font-family: "Open Sans", sans-serif;
    color: #000;
  }

  &__divider {
    margin: 10px 0 24px;
    width: 100%;
    height: 1px;
    background-color: $border-secondary-color;
  }

  &__content {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $text-links-color;
    text-align: center;
  }

  .popup-footer {
    margin-top: 56px;
  }

  .popup-footer .group-btn {
    justify-content: center;
  }
}
.popup-footer .btn-regular {
  margin: 0;
}
.is-visible .cd-popup-container {
  transform: translateY(0);
}
