.celebrationModal {
  max-width: 534px;
  width: 100%;
  margin: 80px auto 0;

  .rs-modal-body {
    display: grid;
    place-items: center;
    max-height: 614px !important;
  }

  &__text {
    margin-top: 32px;
    text-align: center;
    max-width: 410px;
  }
  &__img {
    margin: 60px 0 32px;

    &--margin {
      margin: 32px 0;
    }
  }
  &__img-coins {
    margin: 24px 0;
  }
  &__coins {
    font-size: 24px;
    line-height: 34px;
    font-weight: 700;
    color: $text-links-color;
    margin-bottom: 32px;
  }
  .main-title {
    max-width: 357px;
    text-align: center;

    &--m-width {
      max-width: 234px;
    }

    &--max-width {
      max-width: 100%;
    }
  }

  .rs-modal-body {
    max-height: 600px;

    @media screen and (max-width: 800px) {
      max-height: 550px !important;
    }
  }
}
