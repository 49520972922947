@import "../../../theme-colors";

.accessManagement {
  margin-top: 24px;
  display: flex;
  gap: 20px;

  &__rightColumn {
    max-width: 75%;
    width: 100%;
  }

  &__controlBlock {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__addUser {
    max-width: max-content;
    width: 100%;
  }

  &__filtersBlock {
    max-width: 25%;
    width: 100%;
  }

  &__filtersTitle {
    margin-bottom: 20px;
  }

  &__addRole {
    margin-bottom: 10px;
    text-align: left;
  }

  &__roles {
    margin-bottom: 10px;
    min-height: 44px;
  }

  &__editRoleBtn {
    margin-left: 8px;
    width: 40px;
    min-width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__editRole {
    display: block;
    width: 20px;
    height: 16px;
  }

  .table-wrap .table-inner {
    min-width: 720px;
  }

  .table-wrap .table-inner .item-col:nth-child(1) {
    width: 100%;
    min-width: 45%;
    justify-content: flex-start;
  }
  .table-wrap .table-inner .item-col:nth-child(2) {
    min-width: 20%;
    width: 100%;
  }
  .table-wrap .table-inner .item-col:nth-child(3) {
    min-width: 20%;
    width: 100%;
  }
  .table-wrap .table-inner .item-col:nth-child(4) {
    justify-content: flex-end;
  }
  .moreInfo {
    background-color: transparent;
    color: $text-links-color;
  }
}

@media screen and (max-width: 1300px) {
  .accessManagement {
    flex-direction: column;

    &__rightColumn {
      max-width: 100%;
    }

    .searchBar {
      margin-left: 0;
    }

    &__filtersBlock {
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 500px) {
  .accessManagement {
    &__controlBlock {
      flex-direction: column-reverse;
    }

    &__addUser {
      max-width: 100%;
    }

    .searchBar {
      margin-left: 0;
      max-width: 100%;
    }
  }
}