@import "../../../../theme-colors";

.addNewRole {
  .basicInfo {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 127px;
    row-gap: 32px;

    label + label {
      margin-top: 0;
    }

    .customRole {
      grid-column: 2/2;
    }
  }

  &__editRoleName {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    color: $text-links-color;
    font-size: 18px;
  }
}

@media  screen and (max-width: 1600px) {
  .addNewRole {
    .basicInfo {
      column-gap: 50px;
    }
  }
}

@media  screen and (max-width: 1000px) {
  .addNewRole {
    .basicInfo {
      grid-template-columns: 1fr;

      .customRole {
        grid-column: 1/1;
      }
    }
  }
}