.timePickerWrap {
  .customTimePicker {
    width: 100%;
  
    .rs-input-group.rs-input-group-inside .rs-input-group-addon {
      padding: 8px 12px;
    }
  }
  
  .rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active {
    background-color: $text-links-color;
    color: $blocks-bg-color;
  }
  
  .rs-calendar-time-dropdown-cell:hover {
    background-color: $border-secondary-color;
    color: $text-links-color;
  }
}