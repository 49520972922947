@import "../../../theme-colors";

.merchPage {
  .ql-editor {
    padding: 0;
  }

  &__content {
    display: grid;
    grid-template-columns: 40% auto;
    column-gap: 126px;
  }
  &__contentInfoUser {
    padding-right: 90px;
  }
  &__contentInfoAdmin {
    display: grid;
    grid-template-columns: 1fr 105px;
    column-gap: 80px;
  }
  .outOfStockLabel {
    width: max-content;
    margin-bottom: 32px;
  }
  &__statisticWrap {
    margin-top: 12px;
    justify-content: flex-start;
    gap: 16px;
    flex-wrap: wrap;
  }
  &__quantityLabel {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 150px;
    box-shadow: 0 4px 9px -2px rgba(16, 24, 40, 0.10);
  }
  &__quantityLabelZero {
    background-color: $secondary-text-color;
    color: $main-text-color;
  }

  .merchDesc {
    margin-top: 48px;
  }

  .editMerchItemMobileBtn {
    display: none;
  }
  .merchItemOrderCounter,
  &__coins {
    box-shadow: 0 4px 9px -2px #1018281a;
  }
  .merchItemOrderCounter {
    min-width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: max-content;
  }
  &__coins {
    cursor: auto;
    padding: 10px 25px;
    font-weight: bold;
    color: $text-links-color;
    .ic-coins {
      margin-right: 10px;
    }
  }
  &__slider {
    width: 100%;
    position: relative;
    .slider-arrow {
      position: absolute;
      top: 100%;
      width: 100%;
      margin-top: 8px;
    }
    .slick-btn-next,
    .slick-btn-prev {
      position: absolute;
      width: 36px;
      height: 36px;
      background-color: #ffffffe5;
      border-radius: 18px;
      z-index: 1;

      &:hover {
        box-shadow: 0 4px 9px -2px #1018281a;
      }
      .ic-arrow {
        margin-top: 5px;
      }
    }
    .slick-btn-prev {
      left: 0;
    }
    .slick-btn-next {
      left: 94%;
    }
    .slider-arrowLeft {
      rotate: 90deg;
    }
    .slider-arrowRight {
      rotate: 270deg;
    }
    .slick-dots {
      bottom: -38px;

      li {
        margin: 8px 10px 8px 0;
        width: 8px;
        height: 8px;

        button {
          width: 8px;
          height: 8px;
          &:before {
            font-size: 8px;
            line-height: 10px;
            width: 8px;
            height: 8px;
            opacity: 1;
            color: $border-secondary-color;
          }
        }
      }
      li.slick-active button:before {
        color: $text-links-color !important;
      }
    }
  }
  &__imgWrap {
    height: 630px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    border: 1px solid $border-secondary-color;
    border-radius: 10px;
  }
  &__sliderImg {
    cursor: zoom-in;
    max-height: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    display: block;
    width: 100%;
  }

  &__buttons {
    margin-top: 48px;
  }

  &__groupBtns {
    display: flex;
    align-items: flex-start;

    .btn-regular {
      &:last-child {
        margin-left: 16px;
      }
    }
  }
  .customBreadcrumbs {
    margin-bottom: 32px;
  }
  .popup-footer {
    margin-right: 8px;
  }
}

.merchPhotosPopup.cd-popup {
  background-color: $main-bg-color;
  overflow-x: hidden;
  overflow-y: auto;

  .sliderWrap {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;

    &__imgWrap {
      display: flex !important;
      align-items: center;
      justify-content: center;
      height: 100vh;
    }

    &__img {
      height: 100%;
    }
  }

  .merchPhotosPopup__counter,
  .merchPhotosPopupCloseBtn {
    position: absolute;
    top: 15px;
    z-index: 10;
  }

  .merchPhotosPopup__counter {
    left: 15px;
    box-shadow: 0 4px 9px -2px rgba(16, 24, 40, 0.1019607843)
  }

  .merchPhotosPopupCloseBtn {
    right: 12px;
    border: none;
  }

  .slick-btn-prev,
  .slick-btn-next {
    z-index: 10;
    border: none;
    position: absolute;
    top: 50%;
  }

  .slick-btn-prev {
    left: 15px;

    .slider-arrowLeft {
      transform: rotate(90deg);
    }
  }

  .slick-btn-next {
    right: 15px;

    .slider-arrowRight {
      transform: rotate(270deg);
    }
  }
}

@media screen and (max-width: 1700px) {
  .merchPage {
    &__content {
      column-gap: 64px;
      grid-template-columns: 45% auto;
    }
    &__contentInfoUser {
      padding-right: 50px;
    }
    &__contentInfoAdmin {
      column-gap: 32px;
    }
  }
}

@media screen and (max-width: 1400px) {
  .merchPage {
    &__content {
      grid-template-columns: 100%;
      row-gap: 64px;
    }
    &__imgWrap {
      width: 100%;
      height: 500px;
    }
    &__infoBlock {
      margin-top: 50px;
    }
  }

  .merchPhotosPopup.cd-popup {
    .sliderWrap {
      &__img {
        height: auto;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .merchPage {
    &__contentInfoAdmin {
      display: block;
    }
    &__groupBtns {
      display: none;
    }
    .editMerchItemMobileBtn {
      display: flex;
    }
  }
}

@media screen and (max-width: 600px) {
  .merchPage {
    &__contentInfoUser {
      padding-right: 0;
    }
    &__slider {
      .slider-arrow {
        margin-top: 8px;
      }
      .slick-btn-next {
        left: 93%;
      }
    }
    &__imgWrap {
      height: 350px;
    }
  }
}
