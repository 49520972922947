@import "../theme-colors";

.tagLabel {
  font-size: 20px;
  line-height: 30px;
  padding: 4px 12px;
  border-radius: 40px;
  border: 1px solid $blue-color;
  background-color: $blue-soft;
  width: max-content;
  height: 38px;
  color: $text-links-color;
  word-break: break-all;
  cursor: pointer;

  &--yellow {
    border-color: $yellow-border-label-color;
    background-color: $yellow-label-color;
  }

  &--light_yellow {
    border-color: $yellow-border-label-color;
    background-color: $light-yellow;
  }

  &--pink {
    border-color: $pink-border-label-color;
    background-color: $pink-label-color;
  }

  &--gray {
    border-color: $secondary-text-color;
    background-color: $border-secondary-color;
  }

  &--light-pink {
    border-color: $red-color;
    background-color: $light-pink;
  }

  &--soft_yellow {
    border-color: $yellow-border-label-color;
    background-color: $yellow-label-color;
  }
  &--soft_orange {
    background-color: $orange-soft;
    border-color: $orange-border-soft;
  }
  &--soft_red {
    background-color: $red-soft;
    border-color: $red-border-soft;
  }
  &--light_red {
    background-color: $light-pink;
    border-color: $red-color;
  }
  &--soft_pink {
    background-color: $pink-soft;
    border-color: $pink-border-soft;
  }
  &--soft_purple {
    background-color: $purple-soft;
    border-color: $purple-border-soft;
  }
  &--soft_blue {
    background-color: $blue-soft;
    border-color: $blue-color;
  }
  &--soft_green {
    border-color: $green-color;
    background-color: $green-soft;
  }
  &--light_green {
    border-color: $green-color;
    background-color: $green-light;
  }
  &--soft_primrose {
    background-color: $primrose-soft;
    border-color: $primrose-border-soft;
  }

  button {
    background: inherit;
  }
}

.tagLabel-small,
.tagLabel-normal {
  padding: 2px 10px;
}

.tagLabel-small {
  height: 22px;
  font-size: 12px;
  line-height: 18px;
}

.tagLabel-normal {
  height: 28px;
  font-size: 16px;
  line-height: 24px;
}

@media screen and (max-width: 599px) {
  .tagLabel {
    height: 32px;
  }
}
