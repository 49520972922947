@import "../theme-colors.scss";

/*  BUTTON
------------------------------------------------- */
.btn-primary,
.btn-regular,
.btn-tertiary,
.btn-additional {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  padding: 10px 32px;
  border-radius: 40px;
  border: 1px solid #e4e4e4;
  font-weight: bold;
  line-height: 24px;
  font-size: 16px;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;

  &:hover {
    background-color: #272727;
    color: #ffffff;
    border: 1px solid #272727;
    box-shadow: 0 4px 9px -2px rgba(16, 24, 40, 0.10), 0px 4px 9px -2px rgba(16, 24, 40, 0.20);;
  }

  &:disabled {
    color: #e4e4e4;
    background-color: transparent;
    border: 1px solid #e4e4e4;
    pointer-events: none;
  }
}
.btn-primary {
  background-color: $button-primary-bg;
  color: $button-primary-text;
  border: 1px solid $button-primary-bg;
}
.btn-tertiary {
  background-color: $button-tertiary-bg;
  color: $button-tertiary-text;
  border: 1px solid $button-tertiary-bg;

  &:disabled {
    border: none;
  }
}
.btn-regular {
  background-color: $button-regular-bg;
  color: $button-regular-text;
}

.btn-regular.selected {
  background-color: $button-selected-color;
  border-color: transparent;
  color: $text-links-color;
  box-shadow: none;
}

.btn-additional {
  color: $button-primary-bg;
  height: 24px;
  background-color: transparent;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
  &::before {
    content: url("../../sprite/plus.svg");
    display: block;
    margin-right: 10px;
    width: 24px;
    height: 24px;
  }

  &.minusIcon::before {
    content: url("../../sprite/minus.svg");
    margin-top: 3px;
  }
  &:hover {
    background-color: transparent;
    color: $button-primary-bg;
    border: none;
    box-shadow: none;
  }
}

.actionBtn-tertiary,
.actionBtn-regular {
  display: flex;
  width: 44px;
  padding: 0;
  border-radius: 10px;

  .buttonIcon {
    width: 24px;
    height: 24px;
    display: block;
  }
}
.actionBtn-tertiary {
  border: 1px solid transparent;

  &:hover {
    box-shadow: 0 4px 9px -2px #1018281a;
    background-color: #f1f1f1;
    border: 1px solid #f1f1f1;
    color: $button-tertiary-text;
  }
}
.actionBtn-regular {
  border: 1px solid #e4e4e4;
  padding: 10px;
  &:hover {
    box-shadow: 0 4px 9px -2px #1018281a;
    background-color: $button-tertiary-text;
    svg {
      fill: $blocks-bg-color;
    }
  }
}

/* Buttons from Layout right panel */
.svg-icon {
  display: inline-block;
  vertical-align: middle;
}
.ic-close-popup {
  width: 24px;
  height: 24px;
}

.ic-btn-loader {
  width: 24px;
  height: 24px;

  animation: rotate 2s linear infinite;
}

.ic-file-upload {
  width: 24px;
  height: 24px;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 600px) {
  .btn-primary,
  .btn-regular,
  .btn-tertiary {
    height: 32px;
    padding: 10px 24px;
    font-size: 14px;
    line-height: 20px;
  }
  .btn-additional {
    padding: 0;
  }
  .actionBtn-regular,
  .actionBtn-tertiary {
    padding: 10px;
  }
}
