@import "../theme-colors";

/*  01. login-page
------------------------------------------------- */
.logo {
  margin-bottom: 63px;
}
.login-page {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 40px 0 20px;
}
.login-page .logo {
  max-width: 155px;
}

.login-page .login-group-btn {
  display: flex;
  justify-content: space-between;

  &--margin-top {
    margin-top: 16px;
  }

  .btn-regular,
  .btn-primary,
  .btn-tertiary {
    margin-top: 32px;
    width: 50%;
    white-space: nowrap;
  }
  .btn-regular,
  .btn-tertiary {
    margin-right: 32px;
  }
}
.form-login .login-box-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-page .checkboxLabel {
  margin-top: 24px;
  margin-bottom: 10px;
}
.login-page .policyLink {
  color: $links-secondary-color;
  text-decoration: underline;
  font-weight: 400;
}
.form-login {
  padding: 48px;
  max-width: 534px;
  width: 100%;
}
.form-login .label .login-icon {
  position: relative;

  svg {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
  }
}
.form-login .login-title,
.login-subtitle {
  text-align: center;
  margin-bottom: 8px;
}
.form-login .login-title {
  font-weight: 700;
}
.form-login span.label-title {
  font-size: 16px;
  line-height: 24px;
  color: $text-links-color;
}
.form-login .login-divider {
  width: 100%;
  border-bottom: 1px solid $border-secondary-color;
  margin-bottom: 24px;

  &--vertical-margin {
    margin: 24px 0 40px;
  }
}
.form-login .login-auth-message {
  max-width: 320px;
  margin-bottom: 48px;
  text-align: center;
  color: $text-links-color;
}
.form-login .btn-tertiary {
  max-width: 176px;
}

.sso-auth {
  margin-top: 32px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .btn-regular svg {
    margin-right: 10px;
    width: 24px;
    height: 24px;
  }
  .btn-regular .azureIcon {
    display: block;
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  .btn-regular {
    margin: 20px auto 0 auto;
    display: flex;
    max-width: 270px;
  }
  .sso-text {
    display: block;
  }
  .google-auth-text {
    max-width: 250px;
    margin: 24px 0 4px;
    text-align: center;
  }
}

@media screen and (max-width: 800px) {
  .logo {
    margin-bottom: 24px;
  }
  .form-login {
    padding: 24px;
  }
  .form-login .check span {
    font-size: 12px;
  }
}

@media screen and (max-width: 550px) {
  .login-page {
    .box {
      box-shadow: none;
    }
  }
}

@media screen and (max-width: 400px) {
  .login-page .btn-primary {
    padding: 10px 32px;
  }
  .login-page .login-group-btn {
    .btn-regular,
    .btn-tertiary {
      margin-right: 16px;
    }
  }
}
