@import "../../../theme-colors";

.personInfoPage {
  &__main {
    display: grid;
    grid-template-columns: 1fr 535px;
    column-gap: 127px;
  }

  .structureBlock {
    margin: 48px 0 120px;

    .pageTabs {
      margin-bottom: 0;
    }

    &__users {
      margin-top: 24px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 32px;
      row-gap: 24px;
    }
  }

  .blockTablet {
    display: none;
  }

  p + p {
    margin-top: 0;
  }

  .basicInfoForm {
    &__pronouns {
      align-self: center;
    }
    .asyncSelect__input {
      min-height: max-content;
    }
    .personInfoPage__label {
      display: block;
    }
    .btn-additional {
      margin-bottom: 16px;
    }
    .addNewEntityGroup {
      grid-column: 2/2;
    }
  }

  .contactsBlock {
    &__icon {
      width: 24px;
      height: 24px;

      &--big {
        width: 32px;
        height: 32px;
        margin-right: 16px;
      }
    }
    &__icons-block {
      grid-column: 2/3;
      display: flex;
      align-items: flex-start;
    }
    .contactsBlock__form-item {
      grid-template-columns: 24px 1fr;
      align-items: center;
      gap: 16px;
    }
    .personInfoPage__item {
      grid-template-columns: 15% auto;
    }
  }

  .significantDatesBlock {
    margin-bottom: 32px;
  }

  .listPersonGroups {
    .table-wrap .table-inner .item-col:nth-child(1) {
      width: 100%;
      min-width: 30%;
      justify-content: flex-start;
    }
    .table-wrap .table-inner .item-col:nth-child(2) {
      width: 100%;
      min-width: 50%;
      margin-right: 16px;
    }
    .table-wrap .table-inner .item-col:nth-child(3) {
      width: 100%;
      min-width: 7%;
      text-align: left;
    }
  }

  .addPersonGroupModal {
    &__btn-container {
      display: flex;
      justify-content: flex-end;

      .btn-primary {
        width: max-content;
      }
    }
    .groupsList {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-top: 24px;
      width: 100%;

      &__name {
        flex: 25%;
      }

      &__desc {
        flex: 60%;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        margin: 0 16px;
      }

      &__btn {
        flex: 15%;
      }
    }
    .no-content-message {
      text-align: center;
      margin-top: 24px;
    }
  }
}

@media screen and (min-width: 1600px) and (max-width: 1920px) {
  .personInfoPage {
    .listPersonGroups {
      .table-wrap .table-inner {
        min-width: 700px;
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .personInfoPage {
    &__main {
      grid-template-columns: 60% 37%;
      column-gap: 32px;
    }
  }
}

@media screen and (max-width: 1500px) {
  .personInfoPage {
    &__main {
      grid-template-columns: 100%;
      row-gap: 32px;
    }

    .structureBlock {
      &__users {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
      }

      .structureCard {
        width: 250px;
      }
    }

    .blockDesktop {
      display: none;
    }

    .blockTablet {
      display: block;
    }
  }
}

@media screen and (max-width: 800px) {
  .personInfoPage {
    .structureBlock {
      margin: 48px 0 150px;
    }
  }
}

@media screen and (max-width: 500px) {
  .personInfoPage {
    .btn-additional {
      margin-top: 16px;
    }
    .contactsBlock__form-item {
      display: grid !important;
      .form-input {
        margin: 0;
      }
    }
    .basicInfoForm__employmentType {
      margin-bottom: 16px;
    }
    .significantDatesBlock {
      &__hireDate {
        margin-bottom: 16px;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .personInfoPage {
    .structureHeader {
      flex-direction: column;
      gap: 16px;
    }
  }
}
