@import "../theme-colors";

.customPopover {
  position: absolute;
  filter: drop-shadow(0px 4px 16px rgba(16, 24, 40, 0.10)) drop-shadow(0px 8px 8px rgba(16, 24, 40, 0.03)) drop-shadow(0px 20px 24px rgba(16, 24, 40, 0.08));
  background-color: #fff;
  padding: 14px;
  transform: translate(-18px, 16px);
  z-index: 10;

  &.placement-bottom-end {
    border-radius: 10px 0 10px 10px;
    z-index: 999;
  }

  &.placement-bottom-start {
    border-radius: 0 10px 10px 10px;
  }

  &::after {
    display: block;
    position: absolute;
    filter: drop-shadow(0px 4px 16px rgba(16, 24, 40, 0.10)) drop-shadow(0px 8px 8px rgba(16, 24, 40, 0.03)) drop-shadow(0px 20px 24px rgba(16, 24, 40, 0.08));
  }

  &.placement-bottom-end::after {
    content: url("../../sprite/popoverLeftArrow.svg");
    display: block;
    position: absolute;
    top: -17px;
    right: 0;
    filter: drop-shadow(0px 4px 16px rgba(16, 24, 40, 0.10)) drop-shadow(0px 8px 8px rgba(16, 24, 40, 0.03)) drop-shadow(0px 20px 24px rgba(16, 24, 40, 0.08));
  }

  &.placement-bottom-start::after {
    content: url("../../sprite/popoverRightArrow.svg");
    top: -17px;
    left: 0;
  }

  &__btn {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    gap: 10px;
    background-color: #fff;
    color: $text-links-color;
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;

    &:last-child {
      margin-bottom: 0;
    }

    &--margin-bottom {
      margin-bottom: 10px;

      &:last-child {
        margin: 0;
      }
    }

    .btnIcon {
      width: 24px;
      height: 24px;
    }
  }
}