@import "../../../theme-colors";

.merchCard {
  position: relative;
  display: flex;
  flex-direction: column;
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 0 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
  }

  &__header {
    width: 100%;
    position: absolute;
    padding: 0 20px 0 24px;
    top: 16px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
  }

  &__imgWrap {
    position: relative;
    border-radius: 10px;
    border: 1px solid $border-secondary-color;
    height: 392px;
    margin-bottom: 24px;
  }

  &__img {
    caret-color: transparent;
    border-radius: 10px;
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
    cursor: pointer;
  }

  &__price {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 700;
  }

  &__menu {
    margin-left: auto;
    background-color: $blocks-bg-color;
    border: 1px solid $border-color;

    &:hover {
      background-color: $border-color;
    }
  }
}

.outOfStockLabel {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 16px 4px 8px;

  .outOfStockIcon {
    width: 20px;
    color: $red-label-text-color;
    height: 20px;
  }
}