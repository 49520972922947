@import "../../theme-colors";

.badgesAnalytics {
  .rs-picker-default .rs-picker-toggle {
    z-index: 0;
  }
  .save-data {
    margin-top: 34px;
    margin-left: auto;
    width: max-content;
  }
}
.top-users-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 120px;
  margin-top: 48px;

  .user-card.box {
    box-shadow: 0 4px 9px -2px rgba(16, 24, 40, 0.10);
  }

  .user-avatar {
    margin-right: 0;
  }

  .user-count {
    gap: 30px;
  }
}
.graphics-activity {
  margin-top: 80px;
}
.graphics-activity .react-tabs__tab-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 1500px) {
  .page-analytics {
    .top-users-wrapper {
      gap: 50px;
    }
  }
}

@media screen and (max-width: 1300px) {
  .top-users-wrapper {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 450px) {
  .page-analytics {
    .top-users-wrapper {
      .user-card.box {
        gap: 10px;

        .user-avatar {
          min-width: 42px;
          width: 42px;
          height: 42px;
        }

        .user-count {
          gap: 20px;
        }
      }
    }
  }
}
