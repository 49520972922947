@import "../theme-colors";

// For this component React Suits library is used, minimal css-style imported in root style file

/* Date range picker */
.date-range-picker {
  width: 100%;
}
.rs-calendar-table-cell-in-range:before {
  background-color: $border-secondary-color;
}
.rs-picker-popup .rs-picker-toolbar-ranges .rs-btn-link {
  color: $text-links-color;
  text-decoration: none;
}
.rs-picker-popup .rs-picker-toolbar-right .rs-btn-primary {
  background-color: $text-links-color;
}
.rs-picker-daterange-panel {
  min-width: max-content !important;
}

/* Date picker */
.date-picker {
  width: 100%;
}
.rs-picker-date-menu .rs-calendar .rs-calendar-body {
  padding-top: 16px;
}
.rs-picker-date-menu .rs-calendar .rs-calendar-body::before {
  margin-bottom: 13px;
  content: "";
  display: block;
  width: 100%;
  height: 0.8px;
  background-color: $border-secondary-color;
}
.rs-picker-date-menu .rs-calendar-month-view .rs-calendar-body {
  padding-top: 4px;
}
.rs-picker-date-menu .rs-calendar-month-view .rs-calendar-body::before {
  display: none;
}

.rs-picker-popup
  .rs-calendar
  .rs-calendar-month-dropdown-cell-active
  .rs-calendar-month-dropdown-cell-content:hover {
  background-color: $text-links-color;
}

.rs-calendar-month-dropdown-row-wrapper {
  overflow-x: hidden;
}

// Common styles for both pickers

//Styles for input of pickers
.rs-picker .rs-input-group {
  border-radius: 8px;
  border: 2px solid $border-secondary-color;
  color: $text-links-color;

  .rs-input::placeholder {
    color: $secondary-text-color;
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  .rs-input {
    border-radius: 8px;
    color: $text-links-color;
  }
}
.rs-picker-error > .rs-input-group {
  border-color: $error-text-color !important;
}
.rs-picker:not(.rs-picker-disabled):hover {
  border: none;
}
.rs-picker {
  .rs-input-group:focus-within,
  .rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus,
  .rs-input-group:not(.rs-input-group-disabled):hover {
    border-color: $border-secondary-color;
  }
  .rs-input-group:focus-within,
  .rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus {
    outline: none;
  }
}

.rs-picker-toggle-active,
.rs-picker.rs-picker-focused {
  box-shadow: none;
}
.rs-picker-default .rs-picker-toggle.rs-btn-lg {
  padding-bottom: 8px;
  padding-top: 8px;
}
.rs-picker-default .rs-picker-toggle {
  color: $text-links-color;
}
.datePickers__calendarIcon {
  width: 24px;
  height: 24px;
}
.rs-picker-disabled {
  opacity: 1;

  .rs-input-group {
    border-color: $border-color;
  }

  .rs-input-group:not(.rs-input-group-disabled):hover {
    border-color: $border-color;
  }

  .rs-input:disabled {
    background-color: $blocks-bg-color;
    color: $secondary-text-color;
  }

  .rs-input-group-addon {
    display: none;
  }
}

// Styles for calendar of pickers
.rs-picker-popup {
  z-index: 903;
  border-radius: 16px;
  border: 2px solid $border-secondary-color;
  background: #fff;
  box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
}
.rs-calendar-header-title {
  color: $main-text-color;
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}
.rs-calendar .rs-btn-icon.rs-btn-xs {
  font-size: 16px;
  line-height: 16px;
}
.rs-calendar .rs-btn-icon.rs-btn-xs > .rs-icon {
  font-size: 16px;
}
.rs-calendar .rs-calendar-table-header-row .rs-calendar-table-cell-content {
  color: $secondary-text-color;
  text-transform: uppercase;
}
.rs-calendar-table-cell-content {
  border-radius: 40px;
}
.rs-calendar-month-dropdown-cell-active
  .rs-calendar-month-dropdown-cell-content,
.rs-calendar .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  border-radius: 40px;
  background: $text-links-color;
  color: #fff;
}
.rs-calendar
  .rs-calendar-table-cell-un-same-month
  .rs-calendar-table-cell-content {
  color: $secondary-text-color;
}
.rs-picker-popup .rs-calendar .rs-calendar-month-dropdown-cell-content:hover,
.rs-picker-popup
  .rs-calendar
  .rs-calendar-table-cell:hover
  .rs-calendar-table-cell-content {
  background-color: $main-bg-color;
  color: $text-links-color;
}
.rs-calendar-month-view .rs-calendar-header-title-date,
.rs-calendar-month-dropdown-year-active {
  color: $main-text-color;
}
.rs-calendar-month-dropdown-row-wrapper::-webkit-scrollbar {
  width: 16px;
}
.rs-calendar-month-dropdown-row-wrapper::-webkit-scrollbar-track {
  background: #fff;
}
.rs-calendar-month-dropdown-row-wrapper::-webkit-scrollbar-thumb {
  height: 50px;
  width: 10px;
  background: $border-secondary-color;
  border-radius: 10px;
}
.rs-calendar .rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  box-shadow: inset 0 0 0 1px $text-links-color;
}

@media screen and (max-width: 600px) {
  .rs-picker-daterange-calendar-group {
    display: flex;
    flex-direction: column;
    min-width: 100%;
    height: max-content;
  }
  .rs-picker-daterange-menu .rs-calendar {
    margin: 0;
  }
}
