@import "../theme-colors";

/*  table which is used on Account Settings, Company management and System settings pages
------------------------------------------------- */
.table-wrap {
  margin-bottom: 24px;
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    background: $blocks-bg-color;
  }
  &::-webkit-scrollbar-thumb {
    width: 10px;
    background: $border-secondary-color;
    border-radius: 10px;
  }

  .table-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.3s ease;
    position: relative;
    min-width: 927px;
  }

  .table-header {
    border-bottom: 1px solid $text-links-color;
    padding-bottom: 10px;
    margin-bottom: 8px;
  }

  .item-col {
    &:last-child {
      padding-right: 16px;
      width: 100%;
      text-align: right;
    }
  }

  .item-col-first-el {
    transition: padding 0.5s ease;
  }

  .item-th {
    font-weight: bold;
    color: $secondary-text-color;

    &:hover {
      text-decoration: none;
    }
  }

  .new-table-inner {
    padding: 8px 0;
    border-radius: 10px;
    cursor: pointer;
    transition: box-shadow 0.5s ease, background-color 0.5s ease;

    &:hover {
      box-shadow: 0px 4px 9px -2px #1018281a;
      background-color: $blocks-bg-color;

      .item-col-first-el {
        padding-left: 16px;
        transition: padding 0.6s ease;
      }
    }
  }

  .table-inner .btn-regular .svg-icon {
    width: 20px;
    height: 16px;
    display: block;
  }

  .group-btn {
    display: flex;
    justify-content: flex-end;
  }

  .group-btn .btn-regular + .btn-regular {
    margin-left: 10px;
  }
}

// r-suite table

.customTableWrap {
  .rs-table-header-row-wrapper {
    color: $secondary-text-color;
    font-size: 16px;
    font-weight: 700;
  }
  .rs-table-cell-header .rs-table-cell-content {
    display: flex;
    align-items: center;
    line-height: 24px;
    position: relative;
  }

  .rs-table-row-header,
  .rs-table-row-header .rs-table-cell,
  .rs-table-cell {
    background-color: $main-bg-color;
  }

  .rs-table-hover .rs-table-row:not(.rs-table-row-header):hover,
  .rs-table-hover .rs-table-row:hover .rs-table-cell-group,
  .rs-table-hover .rs-table-row:hover .rs-table-cell {
    background-color: $blocks-bg-color;
  }

  .rs-table-hover .rs-table-row-header:hover,
  .rs-table-hover .rs-table-row-header:hover .rs-table-cell,
  .rs-table-hover .rs-table-row-header:hover .rs-table-cell-group {
    background-color: $main-bg-color;
  }

  .rs-table-cell-first .rs-table-cell-content {
    padding-left: 0;
    transition: padding 0.6s ease;
  }

  .rs-table-row:not(.rs-table-row-header):hover {
    .rs-table-cell-first .rs-table-cell-content {
      padding-left: 16px;
    }
  }

  .rs-table-cell-full-text:hover {
    box-shadow: inset $links-secondary-color 0 0 2px;
  }

  .rs-table-scrollbar,
  .rs-table-scrollbar-vertical {
    background-color: $blocks-bg-color;
    opacity: 1;
  }

  .rs-table-scrollbar-handle {
    background-color: $border-secondary-color;
  }

  .rs-table-cell-last .rs-table-cell-content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 10px;
  }

  .rs-table-cell-error {
    background-color: $light-pink;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .tableLoader {
    height: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 10px;
    width: 100%;
    padding: 24px 0;
    background-color: rgba(250, 250, 250, 0.5);
  }

  .overflowColumnContent {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.importOrdersError {
  justify-content: flex-start;
  gap: 24px;
  margin-bottom: 24px;
  padding: 10px 20px;
}
