.accordion {
  &__item {
    border: 1px solid rgba(0,0,0,.125);

    .accordion__button {
      max-width: 100%;
      font-size: 16px;
      background-color: #fff;
      color: #17294d;
      text-align: left;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      padding: 18px !important;
      cursor: pointer;
      border: none;
      border-radius: 0;
      transition: background-color 0.3s, color 0.3s;
    }

    .accordion__control {
      color: #17294d;
    }

    .accordion__chevron {
      width: 20px;
      height: 20px;
      transition: transform 0.3s;
    }

    .accordion__wrapper {
      height: 0;
      overflow: hidden;
      transition: height 0.6s ease;
    }

    .accordion__content {
      padding: 20px;
    }

    .accordion__id {
      display: inline-block;
      width: 60px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__item:first-of-type {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    .accordion__button {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }

  &__item:last-of-type {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;

    .accordion__button {
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
    }
  }

  &__item:last-of-type.active .accordion__button {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  &__item:not(:first-of-type) {
    border-top: 0;
  }

  &__item.active {
    .accordion__button {
      color: $blocks-bg-color;
      background-color: $main-text-color;
      box-shadow: inset 0 -1px 0 rgba(0,0,0,.125);
    }

    .accordion__control {
      color: $blocks-bg-color;
    }

    .accordion__chevron {
      transform: rotate(180deg);
    }
  }
}