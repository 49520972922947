.integrations {
  .main-title {
    margin-bottom: 32px;
  }

  .wrap {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 440px));
    column-gap: 32px;
    row-gap: 24px;
  }

  .integration-item {
    max-width: 440px;
    width: 100%;
    justify-content: flex-start;
    padding: 24px;
    background: $blocks-bg-color;
    border-radius: 10px;
    box-shadow: 0px 4px 9px -2px #1018281a;
  }
  .integration-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 280px;
    padding: 24px;
    background-color: $main-bg-color;
    border-radius: 10px;
    border: 1px solid $border-secondary-color;

    img {
      max-height: 100%;
    }
  }

  .integration-description {
    font-size: 14px;
    line-height: 22px;
    margin-top: 16px;
  }
  .integration-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
  }

  .btn-regular {
    max-width: 209px;
  }
}

.integration-title {
  margin-top: 24px;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
}

@media screen and (max-width: 1600px) {
  .integrations {
    .wrap {
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
  }
}

@media screen and (max-width: 500px) {
  .integrations {
    .integration-image {
      padding: 0 24px;
      height: 250px;
    }
  }
}
