/*  11. company-management page
------------------------------------------------- */
.company-management__controlBlock {
  margin-bottom: 40px;
  gap: 20px;
  display: flex;
  justify-content: space-between;
}

.company-management .table-wrap .table-inner {
  min-width: 1400px;
}

.company-management .table-wrap .table-inner .item-col:nth-child(1) {
  width: 22%;
}
.company-management .table-wrap .table-inner .item-col:nth-child(2) {
  width: 10%;
}
.company-management .table-wrap .table-inner .item-col:nth-child(3) {
  width: 10%;
}
.company-management .table-wrap .table-inner .item-col:nth-child(4) {
  width: 14%;
}
.company-management .table-wrap .table-inner .item-col:nth-child(5) {
  width: 22%;
}
.company-management .table-wrap .table-inner .item-col:nth-child(6) {
  width: 12%;
}
.company-management .table-wrap .table-inner .item-col:nth-child(7) {
  width: 10%;
  text-align: right;
}

.company-management form label:nth-child(4),
.company-management form label:nth-child(7) {
  margin-bottom: 60px !important;
}

@media screen and (max-width: 450px) {
  .company-management__controlBlock {
    flex-direction: column;
  }

  .company-management .searchBar {
    margin-left: 0;
    max-width: 100%;
  }
}