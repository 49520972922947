@import "../../theme-colors";

.addEditTimeOffPolicyPage {
  .pageTitleBlock {
    .customBreadcrumbs {
      margin-bottom: 16px;
    }
    .btn-primary {
      margin-bottom: 24px;
    }
  }

  .marginTop {
    margin-top: 16px;
  }

  &__stepper {
    width: 100%;

    .rs-steps-item-icon-wrapper {
      font-size: 14px;
      font-weight: 700;
      line-height: normal;
    }

    .rs-steps-item-status-process .rs-steps-item-icon-wrapper {
      background-color: $text-links-color;
      border-color: $text-links-color;
      color: $blocks-bg-color;
    }

    .rs-steps-item-content {
      color: $text-links-color;
    }

    .rs-steps-item-status-process .rs-steps-item-title:after,
    .rs-steps-item-title:after {
      border-color: $border-secondary-color;
    }

    .rs-steps-item-title:after {
      border-top-width: 2px;
    }

    .rs-steps-item-status-wait .rs-steps-item-icon-wrapper {
      background-color: $secondary-text-color;
      color: $blocks-bg-color;
    }

    .rs-steps-item-status-finish .rs-steps-item-icon-wrapper {
      color: #fff;
      background-color: $secondary-text-color;
    }

    .rs-steps-vertical .rs-steps-item-tail {
      border-left-width: 2px;
      border-color: $border-secondary-color;
    }
  }

  &__form {
    margin: 80px auto 0;
    padding: 32px 48px;
    max-width: 1100px;
    width: 100%;

    &--peopleStep {
      max-width: 1425px;
    }

    .ql-editor {
      height: 236px;
      min-height: auto;
    }

    .label-title {
      margin-bottom: 2px;
    }

    .badge-label-tertiary-border {
      height: 35px;
      min-height: auto;
      width: max-content;
    }

    label + label {
      margin-top: 0;
    }
  }

  &__form-divider {
    height: 2px;
    width: 100%;
    background-color: $border-secondary-color;
    margin: 48px 0;
  }

  &__form-item {
    display: grid;
    grid-template-columns: 440px 1fr;
    column-gap: 32px;
    margin-bottom: 45px;
    
    &--height {
      min-height: 84px;
    }

    &--m-btm {
      margin-bottom: 24px;
    }
  }

  &__btnGroup {
    margin-top: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    .btn-primary {
      margin-left: auto;

      &:disabled {
        background-color: $secondary-text-color;
        color: $button-primary-text;
        border: 1px solid $secondary-text-color;
      }
    }
  }
  .field-label-required {
    margin-bottom: 0;
  }
  .rs-radio-inline {
    margin: 0 32px 16px 0;
  }

  .secondary-text {
    max-width: 400px;
    margin-top: 8px;
  }

  &__request {
    .boldLabelTitle {
      font-weight: 700;
    }
  }
  &__input-number {
    display: flex;
    align-items: center;
    gap: 16px;

    .customInputNumber {
      flex-grow: 1;
    }

    .unitLabel {
      font-weight: 700;
    }
  }

  &__people {
    .field-label-required {
      margin-bottom: 20px;
    }
    .addEditTimeOffPolicyPage__form-item {
      column-gap: 80px;
      grid-template-columns: 28% 66%;
    }
    .filtersBlock .selects-wrap {
      flex-direction: column;
      width: 100%;
      .actionBtn-regular {
        border: 2px solid $border-secondary-color;
      }
    }
    .select__control,
    .select__container,
    .btn-info,
    .additional-filters-select,
    .main-filter-select {
      width: 100%;
    }
    .select__multi-value {
      color: $text-links-color;
      background: $border-secondary-color;
      border: 1px solid $secondary-text-color;

      &__label {
        color: $text-links-color;
      }
    }
    .select__container .select__multi-value__remove:hover {
      color: $text-links-color;
      background: $border-secondary-color;
      svg {
        fill: $text-links-color;
      }
    }
    .table-wrap .table-header {
      border-bottom: 1px solid $secondary-text-color;
      padding-right: 12px;
    }

    .table-wrap .table-inner {
      min-width: 550px;

      .item-col:nth-child(1) {
        width: 35%;
        margin-right: 8px;
      }
      .item-col:nth-child(2),
      .item-col:nth-child(3) {
        width: 22%;
        margin-right: 8px;
      }
      .item-col:nth-child(4) {
        width: 23%;
      }
    }
    .table-wrap .table-header {
      margin: 32px 0 20px;
      border-bottom: 2px solid $border-secondary-color;
    }
    .item-th {
      display: flex;
      align-items: center;
    }
    .table-inner {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 8px;
      }
    }

    .table-wrap.borderBottom {
      border-bottom: 2px solid $border-secondary-color;
    }

    .badge-label-primary {
      box-shadow: 0 4px 9px -2px rgba(16, 24, 40, 0.1019607843);
      margin-left: 16px;
    }

    .searchBar {
      min-width: 380px;
    }

    .filter-message {
      margin-top: 8px;
      font-size: 12px;
      line-height: 16px;
      color: $green-color;
    }

    .table-content {
      overflow-y: auto;
      overflow-x: clip;
      max-height: 500px;
      min-width: 550px;
      padding-bottom: 24px;

      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-track {
        background: $blocks-bg-color;
      }
      &::-webkit-scrollbar-thumb {
        width: 10px;
        background: $border-secondary-color;
        border-radius: 10px;
      }
    }
  }

  &__select-block {
    display: flex;
    align-items: flex-start;
    gap: 8px;
  }

  &__people-btns {
    .btn-regular {
      margin: 20px 0;
      width: 100%;
    }
  }

  &__people-search-block {
    display: flex;
    align-items: center;
    gap: 80px;
  }

  &__people-title {
    display: flex;
    align-items: center;
  }

  &__approval {
    .alignCenter {
      align-items: center;
    }
  }
}

.policyPage {
  &__content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;
  }

  &__column {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .policyPageBlock {
    .title {
      margin-bottom: 16px;
    }

    &__item {
      display: grid;
      grid-template-columns: 35% auto;
      gap: 16px;
    }

    .blockLabel {
      width: max-content;
      height: max-content;
    }

    .badge-label-primary-border {
      background-color: $main-bg-color;
    }

    .labelWraps {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .ql-editor {
      padding: 0;
    }

    p + p {
      margin-top: 0;
    }

    &__label {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      color: $secondary-text-color;
    }

    .actionBtn-regular {
      margin-top: 24px;
      margin-left: auto;

      .svg-icon {
        width: 24px;
        height: 24px;
      }
    }
  }

  .labelList {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    .changeCountLabel {
      color: $secondary-text-color;
    }
  }
}

@media screen and (max-width: 1800px) {
  .addEditTimeOffPolicyPage {
    &__people-search-block {
      justify-content: space-between;
      gap: 20px;
    }
    &__people-title {
      flex-direction: column;
      align-items: flex-start;
    }
    .searchBar {
      min-width: 320px;
    }
    .badge-label-primary {
      margin: 8px 0 0;
    }
  }
}

@media screen and (max-width: 1600px) {
  .addEditTimeOffPolicyPage {
    &__people-search-block {
      justify-content: flex-start;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
    }
    &__people-title {
      flex-direction: row;
      align-items: flex-start;
    }
    .searchBar {
      min-width: 320px;
    }
    .badge-label-primary {
      margin: 0 0 0 16px;
    }
    &__people {
      .addEditTimeOffPolicyPage__form-item {
        gap: 32px;
      }
    }
  }

  .policyPage {
    &__content {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media screen and (max-width: 1500px) {
  .addEditTimeOffPolicyPage {
    &__people {
      .addEditTimeOffPolicyPage__form-item {
        grid-template-columns: 100%;
      }

      .table-content,
      .table-wrap .table-inner {
        min-width: 1200px;
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .addEditTimeOffPolicyPage {
    &__form-item {
      grid-template-columns: 35% 65%;
      gap: 24px;
    }
  }
  .policyPage {
    &__content {
      grid-template-columns: 1fr;
    }
  }
}

@media screen and (max-width: 600px) {
  .addEditTimeOffPolicyPage {
    .pageTitleBlock {
      flex-direction: column;
      align-items: flex-start;

      .btn-primary {
        width: 100%;
      }
    }
    &__form {
      padding: 24px 15px;
      margin-top: 32px;
    }

    &__form-item {
      grid-template-columns: 1fr;
      margin-bottom: 24px;
    }
  }
}

@media screen and (max-width: 500px) {
  .policyPage {
    .policyPageBlock {
      &__item {
        display: block;

        .policyPageBlock__label {
          margin-top: 10px;
          margin-bottom: 8px;
        }
      }
    }
  }
}
