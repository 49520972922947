.groupManagement {
  &__controlBlock {
    margin-bottom: 32px;
    gap: 20px;
    display: flex;
    justify-content: space-between;
  }
  .table-wrap {
    margin-top: 32px;
  }
  .table-wrap .table-inner .item-col:nth-child(1) {
    width: 100%;
    min-width: 20%;
    justify-content: flex-start;
  }
  .table-wrap .table-inner .item-col:nth-child(3) {
    width: 100%;
    min-width: 40%;
    margin-right: 16px;
  }
  .table-wrap .table-inner .item-col:nth-child(2),
  .table-wrap .table-inner .item-col:nth-child(4) {
    width: 100%;
    min-width: 15%;
  }

  .table-wrap .item-col:last-child {
    width: 100%;
    min-width: 4%;
    text-align: right;
    display: flex;
    justify-content: flex-end;
  }
}

.userGroup {
  &__groupBtns {
    display: flex;
    align-items: flex-start;

    .btn-regular {
      &:last-child {
        margin-left: 16px;
      }
    }
  }

  &__description {
    margin: 32px 0 24px;
  }

  &__criteria-listLabel {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
}

.addEditUserGroupForm {
  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 127px;
  }

  &__block {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__block:last-child {
    justify-content: space-between;
  }

  .rs-radio-checker {
    min-height: 44px;
  }

  .group-btn {
    margin-top: 32px;
    justify-content: flex-end;
    gap: 32px;
  }

  .filtersBlock .selects-wrap {
    flex-grow: 1;
  }
  .filtersBlock .additional-filters-wrap,
  .filtersBlock .main-filter-select {
    width: 50%;
  }
  .filtersBlock .btn-info,
  .filtersBlock .additional-filters-select {
    width: 100%;
  }

  .staticUsersField {
    margin-bottom: 16px;

    .field-label-required {
      margin-bottom: 0;
    }

    .usersCounter {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: max-content;
      box-shadow: 0 4px 9px -2px #1018281a;
    }
  }

  .asyncSelect__container .asyncSelect__value-container {
    max-height: 300px;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: $border-secondary-color $blocks-bg-color;
  }
}

@media screen and (max-width: 1600px) {
  .addEditUserGroupForm {
    &__content {
      column-gap: 50px;
    }
  }
}

@media screen and (max-width: 1400px) {
  .addEditUserGroupForm {
    &__content {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    &__block:last-child {
      gap: 32px;
    }

    .filtersBlock .selects-wrap {
      flex-direction: row;
    }
  }
}

@media screen and (max-width: 600px) {
  .addEditUserGroupForm {
    .filtersBlock .selects-wrap {
      flex-direction: column;
    }

    .filtersBlock .additional-filters-wrap,
    .filtersBlock .main-filter-select {
      width: 100%;
    }
  }
}
