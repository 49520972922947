.directory {
  &__control {
    gap: 8px;

    .form-style {
      flex-grow: 1;
    }

    .searchBar {
      max-width: 720px;
    }
  }
  &__menu {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  &__menuBtn {
    background-color: #f1f1f1;
    border-color: transparent;
  }
  .cardWrap {
    display: grid;
    padding: 0 5px;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    column-gap: 40px;
    row-gap: 24px;
  }
  .new-table-inner {
    letter-spacing: 0;

    .user-name {
      transition: color 0.3s;
    }

    &:hover .user-name {
      color: var(--textButtonsColor);
    }
  }

  .table-wrap {
    margin-top: 50px;

    .table-inner .item-col:nth-child(1) {
      width: 100%;
      min-width: 25%;
      justify-content: flex-start;
    }
    .table-inner .item-col:nth-child(2) {
      width: 100%;
      min-width: 20%;
    }
    .table-inner .item-col:nth-child(3) {
      width: 100%;
      min-width: 20%;
    }
    .table-inner .item-col:nth-child(4) {
      width: 100%;
      min-width: 18%;
    }
    .item-col:last-child {
      text-align: left;
    }
  }
}

@media screen and (max-width: 800px) {
  .directory {
    margin-top: 15px;

    .table-wrap {
      .table-inner .item-col:nth-child(2) {
        width: 100%;
        min-width: 22%;
      }
      .table-inner .item-col:nth-child(3) {
        width: 100%;
        min-width: 22%;
      }
    }
  }
}