@import "../theme-colors";

.asyncSelect__container {
  width: 100%;

  .asyncSelect__control {
    background-color: $blocks-bg-color;
    border: 2px solid $border-secondary-color;
    border-radius: 8px;
    box-shadow: none;
    cursor: pointer;

    &:hover {
      border: 2px solid $border-secondary-color;
    }
  }

  .asyncSelect__value-container {
    padding: 8px 14px;
  }

  .asyncSelect__dropdown-indicator {
    display: none;
  }

  .asyncSelect__clear-indicator {
    padding-right: 14px;
    color: $text-links-color;
  }

  .asyncSelect__single-value {
    margin: 0;
    color: $main-text-color;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .asyncSelect__input-container {
    margin: 0;
    padding: 0;
    color: $main-text-color;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .asyncSelect__menu {
    border-radius: 8px;
    border: 2px solid $border-secondary-color;
    box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.03), 0 12px 16px -4px rgba(16, 24, 40, 0.08);
  }

  .asyncSelect__menu-list {
    border-radius: 8px;
    padding: 0;
  }

  .asyncSelect__option {
    padding: 10px 14px;
    font-family: "Open Sans", sans-serif;
    color: $text-links-color;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    background-color: $blocks-bg-color;
    transition: background-color 0.3s;
    cursor: pointer;

    &:hover {
      background-color: $main-bg-color;
    }
  }

  .asyncSelect__option--is-disabled,
  .asyncSelect__option--is-disabled:hover {
    background-color: $border-color;
    color: $gray-color;
    cursor: not-allowed;
  }

  .asyncSelect__menu-notice {
    padding: 10px 14px;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: $secondary-text-color;
  }

  .asyncSelect__indicator-separator {
    display: none;
  }

  .asyncSelect__clear-indicator:hover {
    color: $text-links-color;
  }

  .asyncSelect__placeholder {
    font-family: "Open Sans", sans-serif;
    color: $secondary-text-color;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding: 0;
    margin: 0;
  }

  .asyncSelect__value-container--is-multi.asyncSelect__value-container--has-value {
    padding: 6px;
  }

  .asyncSelect__value-container--is-multi {
    gap: 10px;
  }

  .asyncSelect__value-container--is-multi + .asyncSelect__indicators {
    align-items: flex-start;
  }

  .asyncSelect__multi-value {
    margin: 0;
    border-radius: 40px;
    background-color: $main-text-color;
    color: $blocks-bg-color;
  }

  .asyncSelect__multi-value__label {
    color: $blocks-bg-color;
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding: 2px 0 2px 10px;
  }

  .asyncSelect__multi-value__remove {
    padding-right: 10px;
    border-radius: 0 40px 40px 0;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  .asyncSelect__multi-value__remove:hover {
    background-color: $main-text-color;
    color: $blocks-bg-color;
  }

  .asyncSelect__multi-value__remove:hover svg {
    fill: $blocks-bg-color;
  }
}

.asyncSelect__container.asyncSelect--is-disabled {
  pointer-events: auto;

  .asyncSelect__control {
    border-color: $border-color;
    cursor: not-allowed;
  }

  .asyncSelect__single-value {
    color: $secondary-text-color;
  }
}

.parent-company .asyncSelect__container {
  z-index: 3;
}
