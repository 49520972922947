/* Color themes */

/* Default values of these variables - blue color theme,
we change values of  these variables on useEffect Layout.tsx, based on data from server */
:root {
  --mainBgColor: #f6f4fc;
  --secondaryBgColor: #dde0fb;
  --formBgColor: #f2f3fd;
  --textButtonsColor: #3e58f0;
  --barChartMainColor: #8884d8;
  --barChartSecondaryColor: #b0b5ff;
  --boxShadowMainColor: rgba(108, 99, 255, 0.3);
  --boxShadowSecondaryColor: rgba(108, 99, 255, 0.1);
}

/* scss variables for default theme-blue */
$theme-blue-mainBgColor: #f6f4fc;
$theme-blue-secondaryBgColor: #dde0fb;
$theme-blue-formBgColor: #f2f3fd;
$theme-blue-textButtonsColor: #3e58f0;

$error-text-color: #d32f2f;

/* scss vars for primary button */
$button-primary-bg: #272727;
$button-primary-text: #ffffff;
/* scss vars for regular button */
$button-regular-bg: transparent;
$button-regular-text: #272727;

/* scss vars for tertiary button */
$button-tertiary-bg: transparent;
$button-tertiary-text: #272727;

$button-selected-color: #d4edfd;

$main-bg-color: #fafafa;
$border-color: #f1f1f1;
$border-secondary-color: #e4e4e4;
$box-shadow-color: #e9eff5;
$secondary-text-color: #bababa;
$blocks-bg-color: #fff;
$text-links-color: #272727;
$main-text-color: #000;
$links-color: #457b9d;
$links-secondary-color: #69aeda;
$tooltip-text-color: #344054;
$green-label-bg-color: #b1f0c2;
$green-color: #34a853;
$green-light: #cce9d4;
$red-color: #e04f49;
$red-label-bg-color: #ffa09c;
$red-label-text-color: #ce0e35;
$online-indicator-color: #7ed321;
$blue-label-color: #95d3fa;
$blue-border-label-color: #69aeda;
$yellow-label-color: #ffe6a9;
$pink-label-color: #ffd3d3;
$pink-border-label-color: #ff98b0;
$purple-border-label-color: #a67bd1;
$purple-label-color: #e4c9ff;
$light-pink: #f7d3d1;
$gray-color: #adadad;
$deep-blue-color: #3e465b;
$blue-color: #4da0d6;
$blue-light: #d2e7f4;
$light-green-color: #c7f6d7;
$blue-birthday-color: #92d5ff;
$beige-color: #fffdf6; // colors of text in color-labels
$yellow-label-text: #d89a00;
$yellow-border-label-color: #ffc639;
$light-yellow: #fff1cd;
$orange-label-text: #de7400;
$blue-label-text: #5495bd;
$purple-label-text: #811de5;
$indigo-label-text: #2c1ed5;
$pink-label-text: #b90c6b;
$turquoise-label-text: #0f928a;
$primrose-label-text: #818b15;

$orange-indicator: #f38e1f;
$pink-indicator: #eb2b94;
$indigo-indicator: #5243ff;
$turquoise-indicator: #19bfb5;
$primrose-indicator: #cfde1f;

//color category label
$pink-soft: #ffafdb;
$purple-soft: #c0b4fe;
$primrose-soft: #f2feb4;
$green-soft: #c4f5c3;
$blue-soft: #b4e0fe;
$red-soft: #ffb9b5;
$orange-soft: #facfa0;
$orange-border-soft: #ffa94a;
$red-border-soft: #ff5c53;
$pink-border-soft: #ff49ad;
$purple-border-soft: #6143ff;
$primrose-border-soft: #b3ce28;
$highlight-soft-yellow-color: #ffefc6;
