@import "../theme-colors";

/*  04. popup-feedback
------------------------------------------------- */
.popup-feedback {
  max-width: 630px;
  width: 100%;
}
.popup.popup-feedback {
  padding-left: 16px;

  .popup-head {
    padding-left: 16px;
  }
}
.popup-feedback .wrap {
  padding: 0 16px 30px 16px;
  margin-bottom: 16px;
}

/* ------------------------------------------------- */
.popup-feedback-next {
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-left: 16px;

  .boxItemImg {
    padding: 24px;
  }

  .new-wrap {
    padding-right: 16px;
  }

  .receiverName {
    font-size: 24px;
    font-weight: 700;
    color: $text-links-color;
  }

  .totalSpentCoins {
    margin: 24px 0;

    .totalCount {
      color: $text-links-color;
      font-weight: bold;
      margin-left: 10px;
    }
  }
}

@media screen and (max-width: 800px) {
  .popup-feedback {
    max-width: 100%;
  }

  .popup-feedback .feedbackBadge.active:hover {
    .feedbackBadge__icon {
      &::after {
        left: 5px;
        top: 3px;
        content: url("../../sprite/badgeCheckmark.svg");
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .popup-feedback-next {
    padding-bottom: 90px;
  }
}
