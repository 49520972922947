.Toastify {
  &__toast-container {
    width: 540px;
    padding: 0px;
  }
  &__toast {
    min-height: 68px;
    padding: 10px 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 9px -2px #1018280d;
    align-items: center;
  }

  &__toast-body {
    margin: 0;
    padding: 0;
  }
}
.Toastify__toast-theme--light {
  color: $text-links-color;
  font-weight: 400;
  line-height: 24px;
  font-family: "Open Sans", sans-serif;
}
.Toastify__toast-icon {
  width: 28px;
  height: 28px;
}

.Toastify__close-button--light {
  color: $secondary-text-color;
  opacity: 1;
  height: 24px;
  width: 24px;
  align-self: center;
  margin-left: 16px;
}

.Toastify__toast--error .Toastify__toast-icon svg {
  fill: #d92d20;
}

.Toastify__toast--warning .Toastify__toast-icon svg {
  fill: #f1c40f;
}

.Toastify__toast--success .Toastify__toast-icon svg {
  fill: #039855;
}

.Toastify__toast--error .Toastify__close-button svg,
.Toastify__toast--warning .Toastify__close-button svg,
.Toastify__toast--success .Toastify__close-button svg {
  fill: $secondary-text-color;
}

@media screen and (max-width: 800px) {
  .Toastify__toast-container {
    width: 440px !important;
  }
}

@media screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 320px !important;
    top: 1em !important;
    right: 1em !important;
    left: auto;
    width: 300px !important;
  }
}
