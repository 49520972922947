.achievementsArchivePage {
  &__content {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
  }
  .date-range-picker {
    width: 450px;
  }
  .achievementsArchiveTable {
    margin-top: 20px;

    .customTable_row:hover {
      cursor: pointer;
    }
  }
}

.achievementArchivePage {
  .customBreadcrumbs {
    margin-bottom: 24px;
  }

  .archiveAchievementCategory {
    cursor: default;

    .croppedCategoryName:hover {
      cursor: pointer;
    }
  }

  .sideBtn {
    margin-bottom: 32px;
  }
}

@media screen and (max-width: 1300px) {
  .achievementsArchivePage {
    &__content {
      flex-direction: column;
    }
  }
}

@media screen and (max-width: 800px) {
  .achievementsArchivePage {
    .searchBar {
      max-width: 100%;
    }
    
      .date-range-picker {
        max-width: 100%;
      }
  }
}
