@import "../theme-colors";

.adsBlock {
  position: relative;
  padding: 0;

  &__link {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px;

    &:hover {
      color: $main-text-color;
    }
  }

  &__name {
    margin-top: 10px;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
  }

  &__coins {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 31px;
    color: $text-links-color;
  }

  &__iconWrap {
    position: absolute;
    top: -10px;
    left: 0;
    background-color: #fff;
    width: 60px;
    min-width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 9px -2px rgba(16, 24, 40, 0.1);
  }

  &__icon {
    width: 36px;
    height: 36px;
    display: block;
    fill: $text-links-color;
  }

  &__btn {
    margin: 20px auto 0;
  }

  &__img-wrap {
    max-height: 280px;
    height: 100%;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
}

@media screen and (max-width: 1300px) {
  .adsBlock {
    height: 100%;
    &__name {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__coins {
      font-size: 18px;
      line-height: 28px;
    }

    &__iconWrap {
      width: 40px;
      min-width: 40px;
      height: 40px;
    }

    &__icon {
      width: 24px;
      height: 24px;
    }

    &__img-wrap {
      max-height: 100%;
      height: 200px;
    }
  }
}

@media screen and (max-width: 600px) {
  .adsBlock {
    &__img {
      margin: 0 auto;
    }
  }
}
