.achievements {
  &__header-block {
    flex-wrap: wrap;
    gap: 16px;
  }
  &__header-title {
    display: flex;
    align-items: center;
    gap: 32px;
  }
  .pageTabs {
    margin: 0 32px 0 0;
  }
  &__btns {
    gap: 8px;
  }

  &__links {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__divider {
    height: 24px;
    border: 0.5px solid $secondary-text-color;
    margin: 0 16px;
  }

  .ic-close-popup.tagLabelCloseIcon {
    width: 16px;
    height: 16px;
    margin-left: 10px;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
    row-gap: 24px;
    column-gap: 32px;
    margin-top: 24px;
  }
}

@media screen and (min-width: 2400px) {
  .achievements {
    &__list {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

@media screen and (max-width: 800px) {
  .achievements {
    &__control {
      width: 100%;

      &--calendar-page {
        width: max-content;
      }
    }
    &__header-block {
      align-items: flex-start;
    }
    &__header-title {
      flex-direction: column;
      align-items: flex-start;
    }
    .pageTabs {
      &__hiddenLink {
        display: block;
      }
      &__popoverLink {
        display: none;

        &--block {
          display: block;
        }
      }
    }
    &__list {
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
  }
}
