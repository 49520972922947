@import "../theme-colors";

.notFoundPage {
  max-width: 1800px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  &__content {
    display: flex;
    align-items: center;
    text-align: center;
  }

  &__code {
    font-weight: 700;
    font-size: 188px;
    line-height: 70%;
    color: $theme-blue-textButtonsColor;
    margin-bottom: 15px;
  }

  &__error {
    font-weight: 700;
    font-size: 48px;
    line-height: 70px;
    color: $theme-blue-textButtonsColor;
  }

  &__img {
    display: block;
    max-width: 300px;
    max-height: 400px;
    width: auto;
    height: auto;
  }
}

@media screen and (max-width: 800px) {
  .notFoundPage {
    &__content {
      flex-direction: column;
    }

    &__code {
      font-size: 110px;
      line-height: 70%;
    }

    &__error {
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 20px;
    }

    &__img {
      max-width: 130px;
      max-height: 160px;
      width: auto;
      height: auto;
    }
  }
}