.errorRoute {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 128px;
  margin-top: 48px;
  img {
    width: 100%;
    max-width: 100%;
    height: auto;
  }

  &__text {
    margin-top: 32px;
    max-width: 535px;
    width: 100%;
  }

  &__time {
    color: $secondary-text-color;
    font-size: 12px;
    line-height: 18px;
  }
}
@media (max-width: 1500px) {
  .errorRoute {
    gap: 64px;
  }
}

@media (max-width: 1300px) {
  .errorRoute {
    flex-direction: column-reverse;

    &__text {
      max-width: 100%;
      text-align: center;
    }

    .main-title {
      text-align: center;
    }

    &__time {
      text-align: right;
    }
  }
}
