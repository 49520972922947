@import "../theme-colors";

/* FORM
------------------------------------------------- */
.form-style .label {
  display: block;
  position: relative;
}
.form-style .label .icon {
  position: relative;
}
.form-style .icon svg {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}
.form-style .icon.file-download svg {
  width: 36px;
  height: 36px;
  right: 2px;
  background: #fff;
  padding: 10px;
  border-radius: 0 20px 20px 0;
}
.form-style .label + .label {
  margin-top: 24px;
}
.form-style .label-title {
  display: block;
  margin-bottom: 16px;
  color: $main-text-color;
  font-size: 24px;
  line-height: 32px;
}
.form-style label + label {
  margin-top: 10px;
}
.form-style .field-label-required {
  position: relative;
  margin-bottom: 16px;
  color: $main-text-color;
  font-size: 24px;
  line-height: 32px;
}
.form-style .field-star {
  display: inline-block;
  margin-left: 4px;
  position: absolute;
  color: $red-color;
  font-size: 16px;
  cursor: pointer;
}
.custom-tooltip.field-label-tooltip {
  padding: 8px 12px;
  box-shadow: 0 12px 16px -4px #10182814;
}
.custom-tooltip.field-label-tooltip.placement-right-start {
  transform: translate(3px, -5px) !important;

  &::after {
    margin-top: 0;
    border-right-color: $blocks-bg-color;
    box-shadow: 0 4px 6px -2px #10182808;
  }
}
.custom-tooltip.field-label-tooltip.placement-top {
  &::after {
    border-top-color: $blocks-bg-color;
  }
}

input[type="file"] {
  display: none;
}
.form-style .file-upload {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  &__image-block {
    background-color: $blocks-bg-color;
    width: 251px;
    height: 251px;
    padding: 24px;
    border-radius: 10px;
    box-shadow: 0 4px 9px -2px #1018281a;
  }

  &__image-preview {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    background-color: $main-bg-color;
    border: 1px solid $border-secondary-color;
  }

  &__image {
    border-radius: 10px;
    max-height: 100%;
    max-width: 100%;
    height: auto;
    width: auto;
  }

  &__name {
    margin-left: 10px;
  }

  .btn-regular {
    margin-top: 16px;
    min-width: 209px;
  }
}
label.upload-img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 160px;
  border: 1px dashed var(--textButtonsColor);
  border-radius: 20px;
  background: #fff;
  box-shadow: 0 0 30px rgba(108, 99, 255, 0.1);
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.875px;
  color: var(--textButtonsColor);
  cursor: pointer;
}
.form-style .form-input,
.form-textarea {
  width: 100%;
  background-color: $blocks-bg-color;
  border: 2px solid $border-secondary-color;
  color: $main-text-color;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;

  &::placeholder {
    color: $secondary-text-color;
  }
}
.form-style .form-input {
  height: 44px;
  padding: 10px 14px;
  border-radius: 8px;

  &:disabled {
    border-color: $border-color;
    color: $secondary-text-color;
  }
}
.form-style .upload-label input + .fields {
  background: #fff;
  border: 2px solid var(--secondaryBgColor);
  box-shadow: inset 0 0 1px #e9eff5;
  font-size: 14px;
  border-radius: 20px;
  padding: 0 18px;
  min-height: 40px;
  display: block;
  width: 100%;
  transition: all 0.3s ease;
  color: #5d5d5d;
  -moz-appearance: none;
  -webkit-appearance: none;
}
.form-style .disabled {
  opacity: 0.3;
}
.form-textarea {
  position: relative;
  padding: 16px 15px;
  margin-bottom: 4px;
  border-radius: 10px;
  resize: none;

  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &--between {
      justify-content: space-between;
    }
  }
}
.form-style .upload-label input + .fields {
  display: flex;
  align-items: center;
  overflow: hidden;
}
.form-style .column {
  display: flex;
  flex-wrap: wrap;
}
.form-style .column .left {
  width: 50%;
  padding-right: 10px;
}
.form-style .column .right {
  width: 50%;
  padding-left: 10px;
}
.form-style .textBlock {
  display: grid;
  column-gap: 15px;
  row-gap: 20px;
  grid-template-columns: auto auto;
  margin-bottom: 20px;

  p + p {
    margin-top: 0;
  }

  &__title {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.4375px;
    color: $secondary-text-color;
    display: block;
  }

  &__value {
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.375px;
    color: $text-links-color;
  }
}

.form-style .text-counter {
  display: flex;
  justify-content: flex-end;
  margin-left: 8px;
}

@media screen and (max-width: 800px) {
  .form-style .column .left,
  .form-style .column .right {
    width: 100%;
    padding: 0;
  }

  .form-style .label-title,
  .form-style .field-label-required  {
    font-size: 20px;
  }
}

@media screen and (max-width: 400px) {
  .form-style .textBlock {
    display: block;

    .textBlock__value {
      margin: 8px 0 20px 0;
    }
  }
}
