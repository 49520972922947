@import "../../../theme-colors";

/*  SYSTEM-SETTINGS
------------------------------------------------- */
.assign-role .wrap,
.setting-role .wrap {
  margin: 32px 0;
}
// TODO remove
// .assign-role form {
//   display: grid;
//   grid-template-columns: 1fr 1fr 250px;
//   column-gap: 50px;

//   label + label {
//     margin-top: 0;
//   }
// }
// .assign-role form .assign-role-btn {
//   align-self: center;
// }
// .assign-role-err {
//   min-height: 45px;
// }
.assign-role .table-wrap .table-inner .item-col:nth-child(1) {
  width: 60%;
  min-width: 35%;
  justify-content: flex-start;
}
.assign-role .table-wrap .table-inner .item-col:nth-child(2) {
  width: 31%;
  min-width: 200px;
}
.assign-role .table-wrap .table-inner .item-col:nth-child(3) {
  margin-left: auto;
}
.setting-role .table-wrap .item-col {
  width: 100%;
}
.setting-role .table-wrap .item-col:last-child {
  width: auto;
}
.setting-role .table-wrap .new-table-inner {
  min-height: 60px;
}

.create-role-err {
  margin-left: 30px;
  margin-top: 10px;
  color: $error-text-color;
}

// TODO remove
// @media screen and (max-width: 1300px) {
//   .assign-role form {
//     margin-bottom: 50px;
//     display: flex;
//     flex-direction: column;

//     label + label {
//       margin-top: 24px;
//     }
//   }

//   .assign-role-err {
//     min-height: 0;
//   }

//   .assign-role form .assign-role-btn {
//     align-self: flex-start;
//     margin-top: 32px;
//   }
// }

@media screen and (max-width: 500px) {
  .system-settings {
    .pageTabs {
      width: 100%;
      justify-content: space-between;

      &__hiddenLink {
        display: block;
      }
      &__popoverLink {
        display: none;
      }
    }
    &__popover.placement-bottom-end {
      padding: 8px;
      transform: translate(-28px, 16px);
    }
  }
}