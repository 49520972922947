@import "../theme-colors";

/* Custom toggle */
.toggle {
  display: flex;
  align-items: center;
  gap: 16px;

  &__container {
    position: relative;
    display: inline-block;
    min-width: 36px;
    height: 20px;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    input:checked + .toggle__slider {
      background: $text-links-color;
    }
    input:checked + .toggle__slider::before {
      -webkit-transform: translateX(16px);
      -ms-transform: translateX(16px);
      transform: translateX(16px);
    }
    input:disabled + .toggle__slider {
      background-color: $main-bg-color;
      cursor: not-allowed;
    }
    input:disabled + .toggle__slider::before {
      content: url("../../sprite/toggleBtnDisabled.svg");
    }
  }

  &__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    background: $border-color;
    box-sizing: border-box;
    border-radius: 12px;
  }

  &__slider:before {
    position: absolute;
    content: url("../../sprite/toggleBtn.svg");
    height: 16px;
    width: 16px;
    bottom: 4px;
    border-radius: 50%;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
}
