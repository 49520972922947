.balanceResetBlock {
  margin-top: 48px;

  .label-title {
    font-weight: 400;
    margin-bottom: 24px;
  }

  .scheduleBtn {
    width: 100%;
  }
}

.popup.balance-reset-popup {
  max-width: 600px;
  width: 100%;

  .balanceResetWarning {   
    padding: 12px;
    border-radius: 10px;
    background-color: $red-soft;
    margin-bottom: 32px;

    &__wrap {
      justify-content: flex-start;
      gap: 10px;
      margin-bottom: 16px;
    }

    &__title {
      font-size: 24px;
      font-weight: 400;
      line-height: 32px;
    }

    &__icon {
      width: 32px;
      height: 32px;
    }
  }

  .balanceResetForm {
    padding-bottom: 0;

    .wrap {
      padding-right: 16px;
    }
  }

  .balanceResetForm__time {
    margin-top: 32px;
  }
}

.cd-popup.confirmResetBalancesPopup {
  .cd-popup-container  {
    max-width: 534px;
    padding: 32px 48px;
  }

  .cd-popup__title {
    font-weight: 400;

    p + p {
      margin-top: 0;
    }

    .resetDateTime {
      font-weight: 700;
    }
  }

  .popup-footer {
    margin-top: 32px;
  }

  .popup-footer .group-btn {
    justify-content: space-between;
  }
}