@import "../theme-colors";

// TODO remove after all changes of design

.language-toggle-container.layout-toggle {
  margin-left: auto;
}

.language-toggle-container {
  .language-toggle {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 1px;
    color: $text-links-color;

    .svg {
      fill: $text-links-color;
    }
  }
}

.sidebar-language-toggle-container {
  display: flex;

  .language-toggle-container {
    position: relative;
    top: -3px;
  }
}

@media screen and (max-width: 800px) {
  .language-toggle-container {
    margin-left: auto;
  }
}
