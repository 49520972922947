@import "../theme-colors";

.leaderboardPage {
  display: flex;
  gap: 30px;

  .advertising {
    width: 25%;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
}

.leadersList {
  width: 75%;

  .filtersBlock {
    margin-bottom: 32px;

    label + label {
      margin-top: 0;
    }
  }

  .leaderCard {
    &__leader {
      justify-content: flex-start;
      gap: 20px;
    }

    &__positionWrap {
      width: 60px;
      height: 60px;
      position: relative;
    }

    &__icon {
      color: $text-links-color;
      display: block;
      width: 100%;
      height: 100%;
    }

    &__topPosition {
      position: absolute;
      top: 15%;
      left: 40%;
      font-weight: bold;
      font-size: 18px;
      color: $text-links-color;
    }

    &__position {
      margin-left: 25px;
    }
  }
}

@media screen and (max-width: 1300px) {
  .leaderboardPage {
    flex-direction: column-reverse;

    .advertising {
      width: 100%;
      display: grid;
      grid-template-columns: 30% 30% 30%;
      gap: 20px;
    }

    .leadersList {
      width: 100%;
    }
  }
}

@media screen and (max-width: 800px) {
  .leaderboardPage {
    margin-top: 20px;
  }

  .leadersList {
    .leaderCard {
      &__leader {
        gap: 10px;
      }

      &__positionWrap {
        width: 40px;
        height: 40px;
      }

      &__topPosition {
        font-size: 16px;
        left: 37%;
      }

      &__position {
        margin-left: 14px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .leaderboardPage {
    .advertising {
      display: flex;
      width: 300px;
      margin: 0 auto;
    }
  }
}

@media screen and (max-width: 400px) {
  .leaderboardPage {
    .advertising {
      width: 100%;
    }
  }
  .leadersList {
    .leaderCard {
      &__leader {
        flex-direction: column;
        justify-content: center;

        .user-avatar {
          margin-right: 0;
        }
      }

      &__position {
        margin-left: 0;
      }
    }
  }
}
