@import "../theme-colors";

.chartTooltip {
  position: relative;
  padding: 8px 12px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);

  &__dateLabel {
    color: $secondary-text-color;
    font-family: Open Sans, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  &__label {
    color: $text-links-color;
    font-family: Open Sans, sans-serif;
    font-size: 12px;
    line-height: 18px;
  }

  &__labelTitle {
    font-weight: 400;
  }

  &__labelValue {
    font-weight: 700;
  }
}

.customPieChart {
  display: grid;
  grid-template-columns: auto 320px;
  column-gap: 100px;

  .customLegend {
    &__item {
      display: grid;
      grid-template-columns: 20px 1fr auto;
      align-items: center;
      margin-bottom: 16px;
      color: $text-links-color;
      font-family: Open Sans, sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }

    &__icon {
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }

    &__count {
      margin-left: 5px;
    }
  }

  .chartTooltip {
    &__label {
      p + p {
        margin-top: 4px;
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .customPieChart {
    grid-column-gap: 30px;
  }
}

@media screen and (max-width: 1400px){
  .customPieChart {
    column-gap: 100px;
  }
}

@media screen and (max-width: 900px){
  .customPieChart {
    column-gap: 30px;
  }
}

@media screen and (max-width: 600px){
  .customPieChart {
    grid-template-columns: 100%;

    .customLegend {
      &__item {
        margin-bottom: 15px;
      }
    }
  }
}