@import "../../../theme-colors";

$header-height-desktop: 225px;
$header-height-mobile: 250px;

.orgChart {
  border: none !important;
  cursor: grab !important;
  overflow: visible !important;
  height: max-content !important;
  background-color: transparent !important;
}

.orgChart .orgchart {
  background: none !important;
  cursor: grab !important;
  padding: 500px;
}

.orgChart .orgchart > ul > li > ul li::before {
  border-top-color: $text-links-color;
}

.orgChart .orgchart > ul > li > ul li .oc-node::before,
.orgChart .orgchart > ul li .oc-node:not(:only-child)::after {
  background-color: $text-links-color;
}

.orgChart .orgchart ul li .oc-node {
  padding: 2px 16px;
}

.orgChart .orgchart ul li .oc-node:hover {
  background-color: transparent;
}

.orgChart .orgchart ul li .oc-node.selected {
  background-color: transparent;
}

.orgChart .orgchart .oc-node .oc-node-card {
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 280px;
  border-radius: 10px;
  background: $blocks-bg-color;
  box-shadow: 0 4px 9px -2px rgba(16, 24, 40, 0.1);

  &__avatarPlaceholder {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    background-color: $border-secondary-color;
    margin-bottom: 10px;
  }

  &__logoPlaceholder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 140px;
    height: 140px;
    margin-bottom: 10px;
  }

  &__logo {
    display: block;
    width: auto;
    max-width: 100%;
    max-height: 100%;
    height: auto;
  }

  &__avatar {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__name {
    color: $text-links-color;
    text-align: center;
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 8px;
  }

  &__position {
    min-height: 18px;
    color: $secondary-text-color;
    text-align: center;
    font-family: Open Sans, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 10px;
  }

  &__children-btn {
    padding: 10px 32px;
    margin-top: 10px;
    border-radius: 40px;
    background-color: $text-links-color;
    color: $blocks-bg-color;
    display: flex;
    gap: 10px;
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    justify-content: center;
    align-items: center;

    &__arrow {
      width: 24px;
      height: 24px;
      fill: $blocks-bg-color;
      transition: transform 0.3s;
    }
  }

  &__children-active-btn {
    .oc-node-card__children-btn__arrow {
      transform: rotate(180deg);
    }
  }
}

.orgChart .orgchart .oc-node .oc-node-card-active {
  box-shadow: 0 2px 13px -2px rgba(84, 148, 189, 0.75),
    0px 4px 9px -2px rgba(84, 148, 189, 0.25);
}

.zoom-wrapper {
  width: 100% !important;
  height: calc(100vh - $header-height-desktop) !important;
}

.orgStructure {
  .asyncSelect__container {
    width: 0;
    opacity: 0;
    transition: opacity 0.3s, width 0.3s;

    .asyncSelect__control {
      border: none;
    }
  }

  .asyncSelect__dropdown-indicator {
    display: none;
  }

  &__mobileWrap {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__tools {
    padding: 0 44px 20px 44px;
    width: 100%;
    display: flex;
    gap: 32px;
    justify-content: flex-end;
    margin: 0 auto;
    border-bottom: 2px solid $border-color;
  }

  &__toolsSearch {
    display: flex;
    border-radius: 10px;
  }

  &__toolsSearch-active {
    border: 1px solid $border-secondary-color;
    background-color: $blocks-bg-color;
    width: max-content;
    height: 44px;
    gap: 8px;
    justify-content: center;
    align-items: center;

    .asyncSelect__container {
      opacity: 1;
      width: 250px;
    }

    .orgStructure__tools-btn {
      border: none;
      background-color: transparent;
    }
  }

  &__tools-btn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $blocks-bg-color;
    min-width: 44px;
    height: 44px;
    border-radius: 10px;
    border: 1px solid $border-secondary-color;
    color: $text-links-color;
    transition: background-color 0.3s, border-color 0.3s, color 0.3s;

    &:hover {
      background-color: $text-links-color;
      border: 1px solid transparent;
      color: $blocks-bg-color;

      .orgStructure__tooltip {
        visibility: visible;
      }
    }
  }

  &__tools-btn-active {
    background-color: $border-color;
  }

  &__tooltip {
    visibility: hidden;
    width: max-content;
    background-color: $text-links-color;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: $blocks-bg-color;
    border-radius: 8px;
    padding: 8px 12px;
    position: absolute;
    z-index: 1;
    bottom: -40px;
  }

  &__tools-icon {
    width: 24px;
    height: 24px;
  }

  &__zoomTools {
    min-width: max-content;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 13px;
    border-radius: 10px;
    background-color: $blocks-bg-color;

    .zoomBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
    }
  }
}

.edit-zoom {
  color: $text-links-color;
  text-align: center;
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.95px;

  &__input {
    width: 32px;
  }
}

@media screen and (max-width: 900px) {
  .orgStructure {
    &__toolsSearch-active {
      .asyncSelect__container {
        opacity: 1;
        width: 190px;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .orgChart .orgchart {
    padding: 0;
  }
  .zoom-wrapper {
    width: 100% !important;
    height: calc(100vh - $header-height-mobile) !important;
  }

  .orgStructure {
    &__tools {
      flex-direction: column;
      justify-content: flex-start;
      padding: 0 19px 20px 19px;
    }

    &__toolsSearch-active {
      .asyncSelect__container {
        width: 230px;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .orgStructure {
    &__toolsSearch-active {
      .asyncSelect__container {
        width: 200px;
      }
    }
  }
}
