@import "../theme-colors";

.select__container {
  .select__control {
    border: 2px solid $border-secondary-color;
    box-shadow: none;
    background-color: $blocks-bg-color;
    border-radius: 8px;
    cursor: pointer;
  }

  .select__control:hover {
    border: 2px solid $border-secondary-color;
  }

  .select__value-container {
    padding: 8px 14px;
  }

  .select__clear-indicator {
    color: $text-links-color;

    &:hover {
      color: $text-links-color;
    }
  }

  .select__dropdown-indicator {
    color: $text-links-color;
    padding-right: 14px;

    &:hover {
      color: $text-links-color;
    }
  }

  .select__indicator-separator {
    display: none;
  }

  .select__single-value {
    margin: 0;
    color: $text-links-color;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .select__input-container {
    margin: 0;
    padding: 0;
    color: $text-links-color;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .select__placeholder {
    font-family: "Open Sans", sans-serif;
    color: $secondary-text-color;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding: 0;
    margin: 0;
  }

  .select__menu {
    border-radius: 8px;
    border: 2px solid $border-secondary-color;
    background: $blocks-bg-color;
    box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.03),
      0px 12px 16px -4px rgba(16, 24, 40, 0.08);
    margin: 4px 0 0 0;
    padding: 0;
    z-index: 904;
  }

  .select__menu-list {
    border-radius: 8px;
    padding: 0;
    margin: 0;
    scrollbar-width: thin;
    scrollbar-color: $border-secondary-color $blocks-bg-color;
  }

  .select__menu-list::-webkit-scrollbar {
    width: 16px;
  }
  .select__menu-list::-webkit-scrollbar-track {
    background: $blocks-bg-color;
    border-radius: 10px;
  }
  .select__menu-list::-webkit-scrollbar-thumb {
    width: 10px;
    background: $border-secondary-color;
    border-radius: 10px;
  }

  .select__option {
    padding: 10px 14px;
    background-color: $blocks-bg-color;
    color: $text-links-color;
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: $main-bg-color;
    }
  }

  .select__option--is-focused,
  .select__option--is-selected,
  .select__option:active {
    background-color: $main-bg-color;
  }

  .select__option--is-disabled {
    background-color: $border-color;
    color: $gray-color;
    cursor: not-allowed;
  }

  .select__menu-notice {
    padding: 10px 14px;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: $secondary-text-color;
  }

  .select__value-container--is-multi.select__value-container--has-value {
    padding: 6px;
  }

  .select__value-container--is-multi {
    gap: 10px;
  }

  .select__value-container--is-multi + .select__indicators {
    align-items: flex-start;
  }

  .select__option--is-selected {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .select__check {
    width: 20px;
    height: 20px;
  }

  .select__multi-value {
    margin: 0;
    border-radius: 40px;
    background-color: $main-text-color;
    color: $blocks-bg-color;
  }

  .select__multi-value__label {
    color: $blocks-bg-color;
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding: 2px 0 2px 10px;
  }

  .select__multi-value__remove {
    padding-right: 10px;
    border-radius: 0 40px 40px 0;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  .select__multi-value__remove:hover {
    background-color: $main-text-color;
    color: $blocks-bg-color;
  }

  .select__multi-value__remove:hover svg {
    fill: $blocks-bg-color;
  }
}

.select__container.select--is-disabled {
  pointer-events: auto;

  .select__control {
    border-color: $border-color;
    cursor: not-allowed;
  }

  .select__single-value {
    color: $secondary-text-color;
  }

  .select__dropdown-indicator {
    display: none;
  }

  .select__multi-value__remove {
    display: none;
  }

  .select__multi-value__label {
    padding: 2px 10px;
  }
}

.select-collapse-header {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  margin: 0 14px;
  padding: 10px 0;
  .ic-arrowBold.down {
    transform: rotate(180deg);
  }

  .select__group-heading {
    color: $main-text-color;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    cursor: pointer;
    margin: 0;
    padding: 0;
  }
}

.select-menu-list {
  .select__group {
    padding: 10px 0;
    cursor: pointer;

    .select__option {
      padding-left: 40px !important;
    }
  }
}

.select__country-option {
  display: flex;
  align-items: center;
  gap: 10px;

  img {
    width: 20px;
  }
}

.colorSelect {
  .select__option {
    display: flex;
  }

  &__option {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    border-radius: 20px;
  }

  &__placeholder {
    display: flex;
    align-items: center;
  }

  &__singleValue {
    display: flex;
  }

  .colorSelect__option.selectColor {
    border: 1px solid $border-secondary-color;
  }

  .colorSelect__option.yellow {
    background-color: $yellow-border-label-color;
  }
  .colorSelect__option.orange {
    background-color: $orange-indicator;
  }
  .colorSelect__option.red {
    background-color: $red-color;
  }
  .colorSelect__option.pink {
    background-color: $pink-indicator;
  }
  .colorSelect__option.purple {
    background-color: $purple-label-text;
  }
  .colorSelect__option.indigo {
    background-color: $indigo-indicator;
  }
  .colorSelect__option.blue {
    background-color: $blue-color;
  }
  .colorSelect__option.turquoise {
    background-color: $turquoise-indicator;
  }
  .colorSelect__option.green {
    background-color: $green-color;
  }
  .colorSelect__option.primrose {
    background-color: $primrose-indicator;
  }
  .colorSelect__option.soft_yellow {
    background-color: $yellow-label-color;
  }
  .colorSelect__option.soft_orange {
    background-color: $orange-soft;
  }
  .colorSelect__option.soft_red {
    background-color: $red-soft;
  }
  .colorSelect__option.soft_pink {
    background-color: $pink-soft;
  }
  .colorSelect__option.soft_purple {
    background-color: $purple-soft;
  }
  .colorSelect__option.soft_blue {
    background-color: $blue-soft;
  }
  .colorSelect__option.soft_green {
    background-color: $green-soft;
  }
  .colorSelect__option.soft_primrose {
    background-color: $primrose-soft;
  }
}
