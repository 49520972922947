.select-emoji-section {
  position: relative;
}

.selectEmoji {
  min-width: 250px;
  height: 44px;
  border-radius: 8px;
  border: 2px solid $border-secondary-color;
  padding: 10px 14px;
  font-size: 16px;
  line-height: 24px;
  color: $secondary-text-color;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  cursor: pointer;
  background-color: $blocks-bg-color;

  &__title-block {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__default {
    font-size: 23px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid $border-secondary-color;
  }

  &__default.selected {
    border: none;
    margin-left: -2px;
  }
}

.emoji-picker {
  position: absolute;
  padding-top: 4px;
  z-index: 10;

  em-emoji-picker {
    --border-radius: 10px;
    --category-icon-size: 24.2px;
    --color-border-over: rgba(0, 0, 0, 0.1);
    --color-border: $secondary-border-color;
    --font-family: "Open Sans", sans-serif;
    --font-size: 16px;
    --rgb-accent: 39, 39, 39;

    min-height: 350px;
    max-height: 350px;
    margin-left: 3px;
  }

  .bar,
  .spacer {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .select-emoji-section {
    width: 100%;
  }

  .selectEmoji {
    min-width: 100%;
  }

  .emoji-picker {
    right: 0;
  }
}
