@import "../theme-colors";

.custom-tooltip {
  background-color: $blocks-bg-color;
  padding: 6px 12px;
  box-shadow: 0 4px 6px -2px #10182808;
  border-radius: 8px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: $tooltip-text-color;

  .ql-editor {
    padding: 0;
    font-size: 12px;
    line-height: 18px;
  }
}

.custom-tooltip.placement-bottom:after {
  border-bottom-color: $blocks-bg-color;
}

.custom-tooltip.placement-bottom-start:after {
  border-bottom-color: $blocks-bg-color !important;
}

.custom-tooltip.placement-top-start:after {
  border-top-color: $blocks-bg-color !important;
  border-width: 6px 8px 0 !important;
}

.rs-tooltip.placement-top-end:after {
  right: 15px;
  border-top-color: $blocks-bg-color;
}

.rs-tooltip[class*="placement-left"]:after {
  border-left-color: $blocks-bg-color;
}

.rs-tooltip[class*="placement-top"]:after {
  bottom: -5px;
  border-top-color: $blocks-bg-color;
}

.custom-tooltip-white {
  color: $main-text-color;
  box-shadow: 0px 4px 9px -2px #1018281a;
}
