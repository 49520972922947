@import "../../../theme-colors";

.playerProfilePage {
  .countsBlocks {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;

    &__item {
      min-width: 230px;
    }

    &__title {
      font-size: 16px;
      line-height: 32px;
      font-weight: 400;
      text-align: left;
    }

    &__value {
      font-size: 48px;
      line-height: 56px;
      text-align: left;
      margin: 0;
    }

    &__block-value,
    &__title-block {
      display: flex;
      align-items: center;
    }
    &__block-value {
      gap: 10px;
    }
    &__title-block {
      padding-bottom: 8px;
    }
  }

  .playerProfileMain {
    margin-top: 32px;

    .playerAnalytics {
      width: 40%;

      .totalEvents-label,
      .countEvents-label {
        color: $text-links-color;
        font-weight: 400;
        text-align: center;
      }

      .totalEvents-label {
        font-size: 12px;
        line-height: 18px;
      }

      .countEvents-label {
        font-size: 36px;
        line-height: 44px;
        letter-spacing: -0.72px;
      }

      .categoriesChartLegend {
        text-align: center;

        &__item {
          display: inline-flex;
          margin-right: 13px;
          align-items: center;
          color: $text-links-color;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          gap: 8px;
        }

        &__icon {
          width: 10px;
          height: 10px;
          border-radius: 50%;
        }
      }

      .activityChart {
        .activityChartTitleWrap {
          p + p {
            margin-top: 0;
          }
        }
        &__title {
          color: $text-links-color;
          font-family: Open Sans, sans-serif;
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
        }
        &__subtitle {
          color: $border-secondary-color;
          text-align: right;
          font-family: Open Sans, sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
        }
      }
      .tabs-block {
        margin-bottom: 64px;

        &--margin-bottom-small {
          margin-bottom: 24px;
        }
      }
      .pageTabs {
        margin: 0;
      }
      .no-content-message {
        margin-bottom: 16px;
      }
    }
  }

  .userAchievementsEventsList {
    width: 100%;
    .table-wrap .table-inner {
      min-width: 1200px;

      .item-col:nth-child(1) {
        width: 100%;
        min-width: 10%;
      }

      .item-col:nth-child(2) {
        width: 100%;
        min-width: 37%;
      }
      .item-col:nth-child(3) {
        width: 100%;
        min-width: 20%;
        margin-right: 8px;
      }
      .item-col:nth-child(4) {
        width: 100%;
        min-width: 16%;
      }
      .item-col:nth-child(5) {
        width: 100%;
        min-width: 10%;
      }
    }
    .table-wrap .item-col:last-child {
      text-align: left;
    }

    .table-wrap .new-table-inner {
      padding: 16px 0;
    }

    &__list-label {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      margin-bottom: 4px;
    }
  }
}

@media screen and (max-width: 1600px) {
  .playerProfilePage {
    .userAchievementsEventsList {
      width: 100%;
    }
  }
}

@media screen and (max-width: 800px) {
  .playerProfilePage {
    .countsBlocks {
      flex-wrap: nowrap;
      display: block;

      &__item {
        max-width: 100%;
        margin-bottom: 20px;
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .playerProfilePage .playerProfileMain .playerAnalytics .tabs-block {
    flex-direction: column;
    gap: 24px;
  }
}
