@import "../theme-colors";

// reusable user block with avatar and name

.user-info {
  display: flex;
  align-items: center;
  gap: 24px;
}
.user-avatar {
  border-radius: 50%;
  width: 40px;
  min-width: 40px;
  height: 40px;
  margin-right: 10px;
  align-items: center;
  justify-content: center;
  position: relative;
  display: flex;
  background: $border-color;

  .avatarSign {
    color: $text-links-color;
    font-weight: 400;
  }
}
.user-avatar img {
  border-radius: 50%;
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.user-name {
  font-family: "Open Sans", sans-serif;
  display: block;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: $text-links-color;
}
.user-card.box {
  box-shadow: 0 4px 9px -2px rgba(16, 24, 40, 0.10);
  padding: 16px;
  margin-bottom: 16px;
  transition: box-shadow 0.3s;

  .user-avatar {
    width: 62px;
    height: 62px;
    min-width: 62px;
  }
  .user-name {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }
  .activeStatusIndicator {
    &::after {
      border: 2px solid $blocks-bg-color;
      width: 22px;
      height: 22px;
      bottom: -3px;
    }
  }
}
.user-card.box.active {
  box-shadow: 0 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
}
.user-additionalInfo {
  color: $secondary-text-color;
  font-family: Open Sans, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}
.activeStatusIndicator {
  &::after {
    box-sizing: border-box;
    border: 1.3px solid $blocks-bg-color;
    content: "";
    display: block;
    width: 14px;
    height: 14px;
    position: absolute;
    bottom: -2px;
    right: 0;
    border-radius: 50%;
    background-color: $border-secondary-color;
  }
}
.activeStatusIndicator.online {
  &:after {
    background-color: $online-indicator-color;
  }
}