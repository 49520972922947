// TODO remove: used for Language Toggle

.rs-dropdown-menu {
  background-color: var(--formBgColor);
  border: 2px solid var(--secondaryBgColor);
  box-shadow: none;
  border-radius: 0 0 15px 15px;
  margin: 0;
  margin-top: 20px;
  padding: 0;
  z-index: 904;

  .rs-dropdown-item {
    background-color: var(--formBgColor);
    color: #595959;
    font-family: Open Sans, sans-serif;
    padding-right: 60px;
    cursor: pointer;

    &:last-child {
      border-radius: 0 0 10px 10px;
    }

    &.rs-dropdown-item-active {
      background-color: var(--secondaryBgColor);
    }
  }
}

.rs-button-toggle-dropdown-menu .rs-dropdown-menu {
  border-radius: 15px 15px 15px 15px;

  .rs-dropdown-item:first-child {
    border-radius: 10px 10px 0 0;
  }
}
