@import "../theme-colors";

.customInputNumber {
  .rs-input-number {
    border-radius: 8px;
    border: 2px solid $border-secondary-color;
    background-color: $blocks-bg-color;
  }

  .rs-input {
    background-color: $blocks-bg-color;
    font-size: 16px;
    line-height: 24px;
    color: $main-text-color;
    padding: 10px 14px;
  }

  .rs-input::placeholder {
    color: $secondary-text-color;
  }

  .rs-input-group:focus-within, .rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus,
  .rs-input-group:not(.rs-input-group-disabled):hover {
    border-color: $border-secondary-color;
  }

  .rs-input-group:focus-within, .rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus {
    outline: none;
  }

  .rs-input-number-btn-group-vertical {
    flex: 0 0 35px;
    width: 35px;
  }

  .rs-input-group:not(.rs-input-group-inside) > :last-child {
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
  }

  .rs-btn-subtle {
    color: $text-links-color;
    background-color: $blocks-bg-color;
  }

  .rs-btn-subtle:hover,
  .rs-btn-subtle:focus,
  .rs-btn-subtle:active {
    background-color: $blocks-bg-color;
  }

  .rs-btn:focus-visible {
    outline: none;
  }

  .rs-input-group-disabled {
    border-color: $border-color;

    .rs-input {
      border-radius: 8px;
      color: $secondary-text-color;
    }

    .rs-input-number-btn-group-vertical {
      display: none;
    }
  }
}

