@import "./theme-colors";

* {
  margin: 0;
  padding: 0;
  outline: none;
  list-style: none;
  border: none;
  text-decoration: none;

  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

body {
  background-color: $main-bg-color;
  font-family: "Open Sans", sans-serif;
  color: $main-text-color;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.preventScroll {
  overflow-y: hidden;
}

.positionRel {
  position: relative;
}

.privacySettingsForm {
  .radioWrap {
    display: flex;
    align-items: center;
  }
  .radioWrap:first-of-type {
    margin-right: 22px;
  }

  .rs-radio-inline {
    margin-right: 6px;
  }

  .ic-info {
    margin-left: 0;
  }

  .audience {
    margin-top: 14px;
  }
}

.ql-editor {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 24px;
  padding: 16px 15px;
}
.ql-editor u {
  text-decoration: underline;
}
.ql-editor s {
  text-decoration: line-through;
}
.ql-bubble .ql-editor a,
.ql-editor a {
  color: $links-color !important;
}
.ql-editor.ql-blank::before {
  color: $secondary-text-color;
  font-style: normal;
}

.svg-width {
  width: 0;
  height: 0;
  position: absolute;
}

.main-title,
.title-block {
  color: $text-links-color;
  font-family: "Open Sans", sans-serif;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.72px;
}
.main-title {
  font-weight: 700;
}
.title-block {
  font-weight: 400;
}
.title {
  color: $main-text-color;
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  word-wrap: break-word;
}
.secondary-text {
  color: $secondary-text-color;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}
.secondary-title {
  color: $secondary-text-color;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
}
.statisticCount {
  color: $text-links-color;
  font-family: "Open Sans", sans-serif;
  font-size: 60px;
  font-weight: 400;
  line-height: 72px;
  letter-spacing: -1.2px;
}
a {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: $text-links-color;
}
a,
a:hover,
a:active,
a:focus {
  text-decoration: none;
}
a:hover,
a:active,
a:focus {
  color: $links-color;
}
img {
  max-width: 100%;
  height: auto;
}

svg {
  fill: $text-links-color;
}

.t-center {
  text-align: center;
}

.not-mb {
  margin-bottom: 0 !important;
}
.m-top-small {
  margin-top: 16px;
}
.m-top {
  margin-top: 24px;
}

.m-btm {
  margin-bottom: 24px;
}
.m-btm-lg {
  margin-bottom: 32px;
}

@mixin d-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.d-flex {
  @include d-flex();
}

.d-flex-center {
  @include d-flex();
  justify-content: center;
}

.box {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 9px -2px rgba(16, 24, 40, 0.1);
  padding: 24px;
}

// reusable class for box with image inside gray wrapper (for example, badges analytics - top badge)
.boxItemImg {
  &__imgWrap {
    padding: 10px;
    justify-content: center;
    width: 200px;
    height: 200px;
    border-radius: 10px;
    border: 1px solid $border-secondary-color;
    background: $main-bg-color;

    img {
      max-height: 100%;
    }
  }
}

.hidden-desktop {
  display: none;
}
.hidden {
  display: none !important;
}
.visible-mobile {
  display: none;
}
.hidden-mobile {
  display: block;
}

.coverImageWrap {
  width: 100%;
  height: 300px;
  position: relative;
  border-radius: 20px;
  margin-bottom: 24px;

  &--border {
    border: 2px solid $secondary-text-color;
  }

  .coverImage {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    object-fit: cover;
  }

  .editCoverBtn {
    position: absolute;
    top: 24px;
    right: 24px;
    background-color: #fff;
  }
  .achievementStatusLabel {
    position: absolute;
    bottom: 32px;
    left: 32px;
  }

  .edit-image-text {
    color: $text-links-color;
    margin-left: 10px;
  }
  .btn-regular {
    width: max-content;

    &:hover {
      span {
        color: $blocks-bg-color;
      }
    }
  }
}

.pageTitleBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__icon {
    width: 25px;
    height: 25px;
  }
}

.statisticBlock {
  &__title {
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
  }
  &__value {
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 24px;
  }
}

.filters-form {
  height: auto;
}
.filters-form.filterBlockHidden {
  height: 0;
  overflow: hidden;
}

.badge-label-primary,
.badge-label-secondary,
.badge-label-tertiary,
.badge-label-blue,
.badge-label-primary-border,
.badge-label-red,
.badge-label-gray,
.badge-label-tertiary-border,
.badge-label-blue-border,
.badge-label-light-blue {
  background-color: $blocks-bg-color;
  padding: 4px 16px;
  border-radius: 40px;
  font-size: 16px;
  line-height: 24px;
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 0 4px 9px -2px #1018281a;
  }
}
.badge-label-secondary {
  background-color: $text-links-color;
  color: $blocks-bg-color;
}
.badge-label-tertiary {
  background-color: $border-secondary-color;
  box-shadow: 0px 4px 9px -2px #1018281a;
}
.badge-label-tertiary-border {
  padding: 2px 16px;
  color: $text-links-color;
  background-color: $border-secondary-color;
  border: 1px solid $secondary-text-color;
}

.badge-label-gray {
  border: 1px solid $secondary-text-color;
  background-color: $secondary-text-color;
  box-shadow: 0px 4px 9px -2px #1018281a;
  color: $text-links-color;
}

.badge-label-primary-border {
  border: 1px solid $border-color;
}
.badge-label-red {
  background-color: $red-label-bg-color;
  box-shadow: 0 4px 9px -2px rgba(16, 24, 40, 0.1);
}
.badge-label-blue {
  background-color: $links-color;
  color: $blocks-bg-color;
}

.badge-label-light-blue {
  background-color: $button-selected-color;
  color: $blue-label-text;
}
//reusable component for display count of received badges and coins
.badge-count {
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  background-color: $yellow-border-label-color;
  border-radius: 40px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  color: #fff;
}

/*  STYLE COLOR
------------------------------------------------- */
.error {
  color: $error-text-color;
  font-family: "Open Sans", sans-serif;

  &-auth {
    margin-bottom: 20px;
  }

  &-validation {
    margin-top: 3px;
  }
}

.first-err {
  padding-right: 10px;
}

.end-message {
  font-family: "Open Sans", sans-serif;
  margin-top: 44px;
  margin-bottom: 24px;
  height: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: $secondary-text-color;
}

.no-content-message {
  color: $secondary-text-color;
}

.d-none {
  display: none !important;
}

.new-wrapper {
  text-align: center;
}
.text-center {
  text-align: center !important;
}

.page-loader {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding-top: 25px;
  padding-bottom: 25px;
}

.loader-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding-top: 25px;
  padding-bottom: 25px;
}

.iconSortWrap {
  color: $secondary-text-color;
  display: flex;
  align-items: center;
  cursor: pointer;

  .ic-singleSortIcon {
    margin-left: 8px;
  }
  .ic-leftSingleSortIcon {
    rotate: 180deg;
  }
  .ic-leftSortIcon {
    position: relative;
    left: 6px;
    rotate: 180deg;
  }
}
.iconSortWrap.active {
  color: $text-links-color;
}

.title-wrap {
  display: flex;
  align-items: baseline;

  .ic-info {
    height: 16px;
  }
}

.coinsImg {
  width: 42px;
  height: 42px;
}

.userCountryWarn {
  width: 28px;
  height: 28px;
  flex-shrink: 0;
  margin-right: 10px;
}

.userCountryWarn__text {
  font-size: 16px;
  line-height: 24px;
  color: $text-links-color;
}

.slack-button {
  gap: 10px;
  padding: 10px 32px 10px 24px !important;
}

.modal {
  margin: 180px auto 0;

  .rs-modal-header {
    padding: 0;
  }

  .rs-modal-header .rs-modal-header-close {
    display: none;
  }

  .modalCloseBtn {
    display: block;
    background-color: transparent;
    width: 24px;
    margin-left: auto;
  }

  .rs-modal-content {
    padding: 12px 12px 0;
    border-radius: 10px;
    background: $main-bg-color;
  }

  .rs-modal-body {
    margin-top: 0;
    padding: 0 14px 12px;
    max-height: 500px !important;
    scrollbar-width: thin;
    scrollbar-color: $border-secondary-color $blocks-bg-color;
  }
  .rs-modal-footer {
    height: 20px;
  }
}

@media screen and (max-width: 1300px) {
  .statisticBlock {
    &__value {
      font-size: 42px;
    }
  }
}

@media screen and (max-width: 1000px) {
  .managementTooltip {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .box {
    padding: 24px 15px;
  }

  .main-title,
  .title-block {
    font-size: 24px;
    line-height: 32px;
  }

  .visible-mobile {
    display: block !important;
  }
  .hidden-mobile {
    display: none !important;
  }

  .statisticBlock {
    &__value {
      font-size: 36px;
    }
  }

  .badge-label-tertiary-border {
    font-size: 14px;
    line-height: 20px;
  }
}
