.timeOffRequests {
  .title-block {
    margin-bottom: 32px;
  }
  .filtersBlock__wrap {
    display: flex;
    margin-bottom: 32px;
  }

  &__tableContent {
    padding-bottom: 24px;
  }
  
  .table-wrap .table-inner {
    min-width: 1200px;
  }
  .table-wrap .table-inner .item-col:nth-child(1) {
    width: 100%;
    min-width: 29%;
    justify-content: flex-start;
  }
  .table-wrap .table-inner .item-col:nth-child(2) {
    width: 100%;
    min-width: 20%;
  }
  .table-wrap .table-inner .item-col:nth-child(3) {
    width: 100%;
    min-width: 25%;
  }
  .table-wrap .table-inner .item-col:nth-child(4) {
    width: 100%;
    min-width: 15%;
  }
  .table-wrap .table-inner .item-col:nth-child(5) {
    width: 100%;
    min-width: 5%;
    gap: 8px;
    padding: 0;
  }
  .user-avatar {
    width: 48px;
    height: 48px;
  }
  .activeStatusIndicator::after {
    width: 17px;
    height: 17px;
  }
}

@media screen and (max-width: 600px) {
  .timeOffRequests {
    .filtersBlock__wrap {
      flex-wrap: wrap;
      flex-direction: row;
    }
  }
}

@media screen and (max-width: 400px) {
  .timeOffRequests {
    .filtersBlock__wrap {
      flex-wrap: nowrap;
      flex-direction: column;
      .btn-regular,
      .btn-primary {
        width: 100%;
      }
    }
  }
}
