@import "../../theme-colors";

.activityAnalytics {
  .insightsBlock__box-count {
    margin-top: 0;
  }

  .activeUsers,
  .peopleOverBlock {
    &__menu {
      gap: 20px;
      margin-bottom: 24px;

      .ic-info {
        width: 16px;
        height: 16px;
        margin-left: 0;
      }

      .activeUsers__title,
      .peopleOverBlock__title {
        color: $text-links-color;
        margin-bottom: 0;

        span {
          display: inline-flex;
          align-items: center;
          white-space: nowrap;
          margin-left: 16px;
        }
      }
    }
  }

  .activeUsers {
    .table-wrap .table-inner .item-col:nth-child(1) {
      min-width: 50%;
    }
    .table-wrap .table-inner .item-col:nth-child(2) {
      min-width: 25%;
    }
    .table-wrap .table-inner .item-col:nth-child(2) {
      min-width: 25%;
    }
  }
  .peopleOverEvents {
    .table-wrap .table-inner .item-col:nth-child(2) {
      min-width: 10%;
    }
  }
}

@media screen and (max-width: 800px) {
  .activityAnalytics {
    .activeUsers,
    .peopleOverBlock {
      &__menu {
        .activeUsers__title,
        .peopleOverBlock__title {
          span {
            display: inline;
          }
        }
      }
    }
  }
}
