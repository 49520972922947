@import "../theme-colors";

.customTextEditor {
  .text-counter {
    margin-left: auto;
  }
  .ql-editor {
    min-height: 300px;
  }

  .ql-toolbar.ql-snow,
  .ql-container.ql-snow {
    border: 2px solid $border-secondary-color;
    font-size: 16px;
    line-height: 24px;
  }
  .ql-container.ql-snow {
    margin-bottom: 10px;
  }
  .ql-toolbar.ql-snow {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    padding: 10px;
  }
  .ql-container.ql-snow {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .ql-toolbar.ql-snow .ql-formats {
    margin-right: 0px;

    &:first-child {
      margin-right: 32px;
    }
  }
  .ql-snow.ql-toolbar button,
  .ql-snow .ql-toolbar button,
  .ql-color-picker,
  .ql-snow .ql-icon-picker {
    margin-right: 2px;
    width: 24px;
    height: 24px;
    padding: 3px;
    border-radius: 4px;
    transition: background, 0.3s;
  }
  .ql-snow .ql-stroke {
    stroke: $secondary-text-color;
  }
  .ql-snow.ql-toolbar button:hover:not(.ql-active),
  .ql-snow .ql-toolbar button:hover:not(.ql-active),
  .ql-color-picker {
    transition: background-color 0.3s;

    &:hover {
      background: $text-links-color;
      transition: background, 0.3s;

      .ql-fill {
        fill: $blocks-bg-color !important;
      }
      .ql-stroke {
        stroke: $blocks-bg-color !important;
      }
      polyline,
      line {
        stroke: $blocks-bg-color !important;
      }
    }
  }
  .ql-snow .ql-fill,
  .ql-snow .ql-stroke.ql-fill {
    fill: $secondary-text-color;
  }
  .ql-snow .ql-picker.ql-header {
    width: 110px;
    height: 44px;
  }
  .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
    display: none;
  }
  .ql-snow .ql-icon-picker {
    &:hover {
      background: $text-links-color;
      transition: background, 0.3s;

      .ql-picker-label .ql-stroke {
        stroke: $blocks-bg-color !important;
      }
    }
  }
  .ql-snow a {
    color: $links-color;
  }
  .ql-snow .ql-transparent {
    opacity: 1 !important;
  }
  .ql-snow .ql-picker-label {
    padding: 0;
    transition: background, 0.3s;

    &::before {
      font-size: 16px;
      line-height: 24px;
      color: $main-text-color;
    }
  }
  .ql-snow.ql-toolbar .ql-picker-label.ql-active {
    polyline {
      stroke: $main-text-color;
    }
    line {
      stroke: $main-text-color;
    }
    &:hover {
      stroke: $blocks-bg-color !important;
    }
  }
  .ql-snow.ql-toolbar button.ql-active {
    .ql-stroke {
      stroke: $main-text-color;
    }
    .ql-fill {
      fill: $main-text-color;
    }
  }
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected {
    color: $secondary-text-color !important;

    .ql-stroke {
      color: $secondary-text-color !important;
      stroke: $secondary-text-color !important;
    }
  }
  .ql-snow.ql-toolbar .ql-picker-item .ql-picker-item.ql-selected {
    color: $secondary-text-color !important;
  }
  .ql-snow.ql-toolbar .ql-picker-item:hover {
    color: $secondary-text-color !important;
    .ql-stroke {
      stroke: $secondary-text-color !important;
    }
  }
  .ql-snow .ql-picker.ql-header .ql-picker-label::after {
    content: "";
    background-image: url("../../sprite/arrowDown.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -12px;
  }
  .ql-toolbar.ql-snow .ql-picker-options {
    margin-top: 4px !important;
  }

  #ql-picker-options-3 {
    left: 1%;
  }
  .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
    border-color: transparent;
  }
  .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    border-color: none;
    border-radius: 8px;
  }

  .ql-toolbar.ql-snow .ql-formats:first-child {
    margin-right: 12px;
    border-radius: 8px;
    border: 1px solid $border-secondary-color;
    box-shadow: 0 1px 2px 0 #1018280d;
    background-color: $blocks-bg-color;
  }
  .ql-snow .ql-picker-label::before {
    line-height: 44px;
    padding-left: 4px;
  }
  .ql-snow .ql-picker.ql-header .ql-picker-label::after {
    right: 4px;
  }
}

#emoji-palette {
  border-radius: 10px;

  #tab-toolbar {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    .active {
      border-bottom: none;
    }
  }

  #tab-panel {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    gap: 4px;
    padding: 4px;

    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-track {
      background: $blocks-bg-color;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      width: 10px;
      background: $border-secondary-color;
      border-radius: 10px;
    }
  }
}

@media screen and (max-width: 800px) {
  .customTextEditor {
    .ql-snow .ql-picker.ql-header {
      width: 120px;
    }
  }
}

@media screen and (max-width: 600px) {
  .customTextEditor {
    .ql-snow.ql-toolbar button,
    .ql-snow .ql-toolbar button,
    .ql-color-picker,
    .ql-snow .ql-icon-picker {
      margin-right: 16px;
    }
  }
}
