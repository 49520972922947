.companyCalendar {
  &__modal {
    max-width: 480px;
    width: 100%;
    margin: 5% auto 0;
    &--width-xl {
      max-width: 1046px;
    }

    .pageTabs {
      padding: 2px;
      margin: 0;
    }

    .pageTabs__link {
      padding: 9px 16px;
    }

    .pageTabs__link.selected {
      font-size: 24px;
      font-weight: bold;
    }

    .ic-info {
      display: block;
    }

    .ic-badge {
      margin: 12px;
    }

    .rs-modal-content {
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          #ffffff 100%
        );
        width: 90%;
        height: 80px;
      }
    }
  }

  &__modal-btn {
    width: 50px;
    height: 50px;
  }

  &__modal-icon {
    width: 33px;
    height: 33px;
  }

  &__modal-date {
    background-color: $button-selected-color;
    margin-right: 24px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    text-align: center;
    padding: 6px;
    line-height: 16px;
  }

  &__modal-day {
    font-size: 10px;
    line-height: 16px;
    font-weight: 700;
    color: $blue-label-text;
    padding: 0 4px;
  }

  &__modal-header-text {
    padding-left: 10px;
    font-size: 32px;
    line-height: 42px;
    font-weight: bold;
  }

  &__modal-header {
    display: flex;
    align-items: center;
  }

  &__modal-categoryTitle {
    max-width: 135px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__day-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;

    &--holiday {
      background-color: $blue-label-text;
      cursor: pointer;
      color: $blocks-bg-color;

      .companyCalendar__day {
        color: $blocks-bg-color;
      }
    }

    &--today {
      background-color: $green-color !important;
      .companyCalendar__day,
      .companyCalendar__today {
        color: $blocks-bg-color;
      }
    }
  }

  &__modal-list {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;

    .achievementListCard {
      max-width: 308px;
    }
    .achievementCard__list-label {
      align-items: center;
    }
  }

  &__achievements-list {
    .achievementCard {
      max-width: 308px;
      width: 100%;
    }

    .badge-label-secondary,
    .achievementCard__coins {
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__modal-count {
    font-size: 12px;
    line-height: 18px;
    color: $text-links-color;
    font-weight: 700;

    &--small {
      font-size: 12px;
      line-height: 14px;
      padding-left: 2px;
      cursor: pointer;
    }
  }

  &__modal-symbol {
    font-size: 18px;
    padding-right: 10px;
  }
}

@media screen and (max-width: 1100px) {
  .companyCalendar {
    &__modal {
      &--width-xl {
        max-width: 800px;
      }
    }

    &__modal-categoryTitle {
      max-width: 80px;
    }

    &__modal-list {
      justify-content: space-around;
    }
  }
}

@media screen and (max-width: 800px) {
  .companyCalendar {
    &__modal {
      .rs-modal-content {
        &::after {
          height: 70px;
        }
      }

      &--width-xl {
        max-width: 500px;
        .rs-modal-body {
          max-height: 400px !important;
        }
      }
    }

    &__modal-categoryTitle {
      max-width: 180px;
    }

    &__modal {
      .pageTabs {
        &__hiddenLink {
          display: block;
        }
        &__popoverLink {
          display: none;
        }
      }
    }

    &__modal-date {
      margin-right: 16px;
    }
  }
}

@media screen and (max-width: 480px) {
  .companyCalendar {
    &__modal-list {
      .achievementListCard {
        max-width: 100%;
      }
    }

    &__modal-categoryTitle {
      max-width: 100px;
    }

    &__achievements-list {
      .achievementCard {
        max-width: 100%;
      }
    }

    &__modal-date {
      margin-right: 10px;
    }
  }
}
