@import "../../theme-colors";

.coinsStatisticBlocks {
  margin: 36px 0 24px;

  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(368px, max-content));
    column-gap: 32px;
    row-gap: 24px;
  }

  &__content {
    gap: 10px;
    justify-content: flex-start;
    font-weight: 400;
    font-size: 48px;
    line-height: 56px;
    letter-spacing: -2%;
  }

  &__coins {
    width: 42px;
    height: 42px;
  }

  .negativeBalance {
    background-color: $pink-label-color;

    .ic-info {
      color: $red-color;
    }
  }
}

.customCarousel,
.merchItemsOverTime {
  margin-top: 40px;

  .ic-info {
    width: 16px;
    height: 16px;
    margin-left: 0;
  }

  .itemsManagement__title {
    color: $text-links-color;

    span {
      white-space: nowrap;
      margin-left: 16px;
    }
  }
}

.merchItemsOverTime {
  margin-bottom: 60px;

  &__menu {
    gap: 20px;
    margin-bottom: 24px;
  }

  &__img {
    width: 62px;
    height: 62px;
    border-radius: 4px;
    border: 1px solid $border-secondary-color;
    background-color: $main-bg-color;

    img {
      width: 100%;
      height: 100%;
      border-radius: 4px;
      object-fit: cover;
      object-position: center;
    }
  }

  &__itemInfo {
    margin-left: 24px;
  }

  &__itemName {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }

  &__itemCategory {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: $secondary-text-color;
  }

  &__table-content {
    overflow-y: auto;
    overflow-x: clip;
    min-width: 1400px;
    padding-bottom: 24px;

    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-track {
      background: $blocks-bg-color;
    }
    &::-webkit-scrollbar-thumb {
      width: 10px;
      background: $border-secondary-color;
      border-radius: 10px;
    }
  }

  .title-block {
    margin-bottom: 24px;
  }
  .table-wrap {
    margin-top: 24px;

    .table-inner {
      min-width: 1400px;
      .item-th {
        display: flex;
        align-items: center;
      }
      .item-col:nth-child(1) {
        width: 100%;
        min-width: 39%;
        padding-right: 70px;
      }

      .item-col:nth-child(2) {
        width: 100%;
        min-width: 12%;
      }

      .item-col:nth-child(3),
      .item-col:nth-child(4),
      .item-col:nth-child(6) {
        width: 100%;
        min-width: 10%;
      }

      .item-col:nth-child(5) {
        width: 100%;
        min-width: 19%;
      }
    }
    .table-header {
      border-color: $secondary-text-color;
      overflow-x: auto;
    }
    .item-col:last-child {
      padding: 0;
      text-align: left;
    }
    .badge-label-red {
      padding: 4px 8px;
    }
  }
  .table-wrap.borderBottom {
    border-bottom: 1px solid $secondary-text-color;
  }
}

.merchItemsExport {
  padding: 10px;
  border-radius: 10px;
}

@media screen and (max-width: 1600px) {
  .coinsStatisticBlocks {
    &__list {
      grid-template-columns: repeat(auto-fit, minmax(290px, max-content));
    }
  }
}

@media screen and (max-width: 1400px) {
  .coinsStatisticBlocks {
    &__list {
      grid-template-columns: auto auto;
    }
    .box {
      &:last-child {
        grid-column: span 2;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .coinsStatisticBlocks {
    &__list {
      grid-template-columns: 1fr;
    }
    .box {
      &:last-child {
        grid-column: span 1;
      }
    }
  }
}
