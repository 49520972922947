@import "../theme-colors";

.merchDeliveryBlock {
  margin-top: 32px;

  &__item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 20px;
  }

  &__label,
  &__value {
    font-size: 16px;
    line-height: 24px;
  }

  &__label {
    display: block;
    color: $secondary-text-color;
    font-weight: 700;
  }

  &__value {
    color: $main-text-color;
    font-weight: 400;
  }

  .field-label-required {
    margin-bottom: 0;
    font-size: 16px;
    line-height: 24px;
    color: $secondary-text-color;
    font-weight: 700;
  }
}

@media screen and (max-width: 500px) {
  .merchDeliveryBlock {
    &__item {
      display: block;
    }

    &__label {
      margin: 20px 0 5px 0;
    }

    &__value {
      margin-bottom: 20px;
    }
  }
}
