@import "../theme-colors";

.dashboardBlock {
  .title-block {
    margin-bottom: 24px;
  }

  &__box-title {
    font-size: 16px;
    line-height: 32px;
    font-weight: 400;
    margin-bottom: 8px;
  }

  &__box-count {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 18px;
    margin-top: 16px;
  }

  &__countPercentage {
    color: $green-color;
    font-weight: 700;
    padding: 0 8px;
  }

  &__countPercentage.negative {
    color: $red-color;
  }

  &__box-img {
    color: $green-color;
  }

  &__box-img.downArrow {
    color: $red-color;
    transform: rotate(180deg);
  }
}