.integration-details {
  .main-title {
    margin-bottom: 8px;
  }

  .customBreadcrumbs {
    margin-bottom: 32px;
  }

  .wrap {
    margin-top: 32px;
  }

  .info {
    display: flex;
    align-items: flex-start;
    .info-image {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 24px;
      margin-right: 32px;
      background: $main-bg-color;
      border-radius: 10px;
      border: 1px solid $border-secondary-color;
      max-width: 440px;
      height: 314px;
      width: 100%;

      img {
        max-height: 100%;
      }
    }

    .info-body {
      .status {
        display: flex;
        align-items: center;
        margin-top: 24px;
      }

      .description {
        padding: 24px 0 32px;

        p:first-child {
          font-weight: 700;
        }
      }

      .supportInfo {
        display: inline-block;
        margin: 8px 6px 0 0;
      }

      .form-style .field-label-required {
        font-size: 18px;
      }
    }
  }
  .integration-title {
    margin: 0 24px 0 0;
  }

  .connect-integration-btn-wrap {
    width: max-content;
  }

  .connect-slack-button {
    margin-top: 24px;
    max-width: 206px;

    svg {
      margin-right: 12px;
    }
  }
}

@media screen and (max-width: 1100px) {
  .integration-details {
    .info {
      flex-direction: column;
      .info-image {
        max-width: 100%;
        height: 250px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .integration-details {
    .info {
      .info-image {
        height: 200px;
      }
      .info-body {
        .description {
          padding: 16px 0;
        }
      }
    }
  }
}
