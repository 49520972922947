/* Styles for animation which appears after sending Request Feedback on Profile page */

.success-message {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(21, 39, 68, 0.4);
  display: none;
  z-index: 999;
}
.success-message.active {
  display: block;
}
.plane-wrap {
  position: fixed;
  left: 50%;
  bottom: -100px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateX(-50%) translateY(50%);
  width: 150px;
  height: 150px;
  justify-content: center;
  background: #fff;
  border-radius: 50%;
}

.success-message.active .plane-wrap {
  animation: plane 0.3s linear;
  animation-fill-mode: forwards;
}

.plane .svg-icon {
  width: 78px;
  height: 75px;
}

.air {
  display: flex;
  margin-top: 3px;
}
.air span {
  border-radius: 5px;
  display: block;
  width: 3px;
  background: #f0aa1a;
}
.success-message.active .air span {
  animation: air 0.3s linear forwards;
}
.air span:nth-child(2) {
  height: 11px;
  margin: 0 7px;
}
.success-message.active .air span:nth-child(2) {
  animation: air-line-center 0.3s linear forwards;
}
.star-1 .svg-icon {
  width: 11px;
  height: 11px;
  position: absolute;
  right: 45px;
  top: 48px;
  opacity: 0;
}
.success-message.active .star-1 .svg-icon {
  animation: star-show 0.3s ease 0.3s forwards;
}
.star-2 .svg-icon {
  width: 25px;
  height: 25px;
  position: absolute;
  right: 17px;
  top: 55px;
  opacity: 0;
}
.success-message.active .star-2 .svg-icon {
  animation: star-show 0.3s ease 0.3s forwards;
}
.star-3 .svg-icon {
  width: 17px;
  height: 16px;
  position: absolute;
  left: 23px;
  bottom: 59px;
  opacity: 0;
}
.success-message.active .star-3 .svg-icon {
  animation: star-show 0.3s ease 0.3s forwards;
}

/*    ANIMATION DELTA-PLANE
------------------------------------------------- */
@keyframes plane {
  100% {
    bottom: 50%;
  }
}

@keyframes air {
  100% {
    height: 16px;
  }

  50% {
    height: 30px;
  }

  0% {
    height: 50px;
  }
}

@keyframes air-line-center {
  100% {
    height: 11px;
  }

  50% {
    height: 20px;
  }

  0% {
    height: 40px;
  }
}

@keyframes star-show {
  100% {
    opacity: 1;
  }
}