@import "../theme-colors";

.structureCard {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__avatarPlaceholder {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    background-color: $border-secondary-color;
  }

  &__avatar {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__userInfo {
    flex-grow: 1;
  }

  &__name {
    color: $text-links-color;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 8px;
  }

  &__position {
    color: $secondary-text-color;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  &__menuBtn {
    margin-top: 10px;
  }
}

.structureCard__popover {
  padding: 16px 24px;
}

.structureCard__popover.placement-bottom-start {
  transform: translate(18px, 5px);
}

@media screen and (max-width: 1500px) {
  .structureCard__popover {
    padding: 16px;
  }
}

@media screen and (max-width: 800px) {
  .structureCard__popover {
    max-width: 170px;
  }
}