.achievementsEvents {
  .filtersBlock {
    .group-btn {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 24px;
      margin-bottom: 24px;
    }
  }

  .loader-container.refreshLoader {
    position: absolute;
    height: 100%;
    background-color: rgba(250, 250, 250, 0.5);
  }

  .asyncSelect__container {
    .asyncSelect__menu {
      z-index: 3;
    }
  }

  .achievementsEventsList {
    overflow-x: auto;

    .user-column {
      justify-content: flex-start;
      font-weight: 700;

      .user-avatar {
        width: 24px;
        height: 24px;
        min-width: 24px;
        margin-right: 16px;

        .avatarSign {
          font-size: 10px;
        }
      }

      .activeStatusIndicator::after {
        bottom: -6px;
        right: -3px;
      }
    }

    .badge-label {
      width: max-content;
      padding: 3px 12px;
    }

    .badge-label-primary-border {
      border: 1px solid $border-secondary-color;
    }

    .badge-label-tertiary {
      box-shadow: none;
    }

    .tagLabel-normal {
      height: 30px;
      padding: 3px 12px;
    }

    .actionBtn-regular:hover {
      box-shadow: 0px 4px 9px -2px #10182833;
      background-color: $border-color;
      border-color: transparent;
      color: $text-links-color;
    }

    .customTable_row {
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.applicationTooltip {
  max-width: 105px;
}

.reviewAchievementEventPopup {
  max-width: 650px;
  width: 100%;

  &__content {
    justify-content: space-between;
    display: flex;
    align-items: initial;
    flex-direction: column;
    height: 100%;
    padding-bottom: 50px;
  }

  .wrap {
    padding-right: 16px;
    margin-bottom: 40px !important;
  }

  &__userNameBlock {
    display: flex;
    align-items: center;
    gap: 24px;
    padding: 10px;
  }

  .achievementsEventsCard__userName {
    color: $text-links-color;
  }

  &__title {
    font-size: 24px;
    line-height: 32px;
    margin-right: 10px;
  }

  &__block {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    margin-bottom: 32px;

    &:nth-child(2) {
      align-items: flex-start;
    }
  }

  .achievementCard {
    height: 176px;
    padding: 12px;
    max-width: 283px;

    .title {
      margin: 0 !important;
      font-size: 16px;
      line-height: 20px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
    }
  }

  .ic-lock {
    width: 14px !important;
    height: 14px !important;
  }

  .achievementCard__indicators {
    padding: 0 8px;
    top: 8px;
  }

  .achievementLock {
    border-radius: 6px;
  }

  .achievementCard__statusLabel {
    text-transform: lowercase;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    padding: 4px 8px;
  }

  .achievementCard__imgWrap {
    height: 100px;
    margin-bottom: 12px;
  }

  .user-avatar {
    min-width: 32px;
    width: 32px;
    height: 32px;
    margin: 0;
  }

  .achievementsEventsCard__userName,
  .achievementsEventsCard__achievementName {
    font-weight: 700;
  }

  .tagLabel {
    margin: 0;
    padding: 3px 12px;
    height: 30px;
    border: none;
    font-size: 16px;
    line-height: 24px;
    cursor: default;
  }

  &__coins {
    display: flex;
    align-items: center;
    padding: 4px 16px;
    gap: 10px;
    border-radius: 40px;
    border: 1px solid $border-secondary-color;
    width: max-content;
    color: $text-links-color;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;

    .ic-coins {
      width: 23px;
      height: 23px;
    }
  }

  .ql-editor {
    padding: 0;
  }

  &__docs {
    .docLink {
      display: flex;
      gap: 10px;
      align-items: center;
      font-family: "Open Sans", sans-serif;
      font-size: 14px;
      margin-top: 10px;
    }
    .docIcon {
      width: 40px;
      height: 40px;
    }
  }
  .form-style .form-textarea {
    min-height: 200px;
  }

  .form-style .label + .label {
    margin-top: 32px;
  }

  .uploadPhotosWrap {
    margin: 0;
  }

  .group-btn button + button {
    margin: 0 !important;
  }

  .group-btn {
    gap: 44px;
    padding-right: 16px;

    &--between {
      justify-content: space-between !important;
    }
  }

  .popup-footer .btn-regular {
    width: max-content;
  }

  .cancelBtn {
    margin-left: auto;
  }

  &__no-data {
    color: $secondary-text-color;
  }

  .previewPhotoContainer {
    padding: 32px;
    height: 100%;

    img {
      height: 100%;
    }
  }
}

.uploadPhotosWrap__btn {
  &:hover {
    color: $main-text-color;
  }
}

@media screen and (max-width: 1400px) {
  .achievementsEvents {
    .filtersBlock .filter-row {
      display: grid;
      grid-template-columns: 90% 45px;
    }
  }
}

@media screen and (max-width: 1300px) {
  .achievementsEvents {
    .filtersBlock {
      .statusFilter__number {
        font-size: 48px;
        line-height: 60px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .achievementsEvents {
    .filtersBlock {
      .group-btn {
        grid-template-columns: repeat(2, 1fr);
        gap: 24px;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .achievementsEvents {
    margin-top: 20px;

    .filtersBlock {
      .statusFilter,
      .statusFilter__number {
        font-size: 18px;
        line-height: 30px;
      }
    }

    .filters-form {
      .group {
        .selects-wrap {
          width: 80%;
        }
      }
    }
  }
  .reviewAchievementEventPopup {
    &__title {
      font-size: 20px;
    }

    &__content {
      padding-bottom: 60px;
    }
  }

  .uploadPhotosWrap {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 650px) {
  .reviewAchievementEventPopup {
    .uploadPhotosWrap {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      flex-wrap: nowrap;
      width: 100%;
    }
  }
}

@media screen and (max-width: 500px) {
  .reviewAchievementEventPopup {
    .popup-footer .group-btn {
      flex-direction: column;
      gap: 20px;
      padding: 0;
    }
  }
}

@media screen and (max-width: 480px) {
  .achievementsEvents {
    .filtersBlock .filter-row {
      display: grid;
      grid-template-columns: 85% 45px;
    }
  }

  .reviewAchievementEventPopup {
    &__block {
      row-gap: 8px;
      margin-bottom: 24px;
      grid-template-columns: 1fr;
    }

    .achievementCard {
      max-width: 100%;
    }
  }
}
