@import "../theme-colors";

.popup.notificationPopup {
  padding-left: 16px;

  .popup-head {
    padding-left: 16px;
  }

  .wrap {
    padding: 0 16px;
  }

  .notification {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    margin-bottom: 8px;
    cursor: pointer;

    &:hover {
      box-shadow: 0 4px 9px -2px rgba(16, 24, 40, 0.1),
        0px 4px 16px 0px rgba(16, 24, 40, 0.1),
        0px 8px 8px -4px rgba(16, 24, 40, 0.03),
        0px 20px 24px -4px rgba(16, 24, 40, 0.08);
    }

    &__main {
      display: flex;
      gap: 16px;
    }

    &__iconWrap {
      display: block;
      padding: 10px;
    }

    &__date {
      color: $secondary-text-color;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }

    &__message {
      margin-top: 4px;
      color: $main-text-color;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    &__delete {
      background-color: transparent;
      padding: 12px 0 12px 12px;
      align-self: center;
    }
  }

  .notification.unread {
    border: 1px solid $border-secondary-color;
    background: $border-color;
  }

  .notificationPopup__footer {
    display: flex;
    gap: 24px;
    margin: 0 auto;
  }

  .notificationPopupBtn {
    white-space: break-spaces;
    height: auto;
  }
}

.notification-toast {
  min-height: 68px;
  padding: 10px;
  box-shadow: 0px 4px 9px -2px rgba(16, 24, 40, 0.1),
  0px 2px 13px -2px rgba(84, 148, 189, 0.75),
  0px 4px 9px -2px rgba(84, 148, 189, 0.25);
  cursor: pointer;
  &__body {
    .Toastify__toast-icon {
      max-width: 53px;
      width: 100%;
      height: 100%;
      justify-content: center;

      .ic-notification {
        margin-left: 3px;
      }
    }
  }

  &__text {
    font-size: 14px;
    line-height: 20px;
    color: $main-text-color;
  }

  &__label {
    border-radius: 20px;
    padding: 1px 6px 1px 6px;
    height: 18px;
    width: 33px;
    font-size: 12px;
    line-height: 16px;
  }
  .Toastify__close-button--light {
    color: $text-links-color;
  }
  .actionBtn-tertiary {
    border: none;
    
    &:hover {
      background-color: transparent;
      box-shadow: none;
      border: none;
    }
  }
}