@import "../../../theme-colors";

.manageAutoEvents {
  .pageTitleBlock {
    flex-wrap: wrap;
    gap: 24px;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(346px, 346px));
    justify-content: space-between;
    column-gap: 32px;
    row-gap: 24px;
    margin-top: 32px;
  }
  .autoEventCard {
    padding: 19px 24px;

    &:hover {
      transition: box-shadow 0.3s;
      box-shadow: 0 20px 24px -4px rgba(16, 24, 40, 0.0784313725);
    }
    .badge-label-primary {
      border: 1px solid $border-secondary-color;
      box-shadow: 0px 4px 9px -2px #1018281A;
    }
    .badge-label-secondary {
      box-shadow: 0px 4px 9px -2px #1018281A;
    }
    &__imgWrap {
      border-radius: 10px;
      border: 1px solid $border-secondary-color;
      width: 100%;
      height: 280px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 24px;
      background-color: $main-bg-color;

      img {
        max-height: 200px;
        max-width: 200px;
        height: auto;
      }
    }

    &__statusLabel {
      position: absolute;
      top: 20px;
      left: 20px;
    }

    &__eventName {
      font-size: 24px;
      line-height: 32px;
      color: $main-text-color;
      padding-bottom: 16px;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .eventTypeWrap {
      margin-top: 16px;

      .autoEventCard__infoLabel {
        background-color: $main-bg-color;
        box-shadow: none;
      }

      .autoEventCard__infoLabel.bordered {
        border: 1px solid $orange-border-soft;
      }

      .autoEventCard__infoLabel.coinsLabel {
        display: flex;
        gap: 10px;
        font-weight: 700;
      }
    }

    &__control {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 22px;
    }

    &__group-btns {
      display: flex;
      gap: 10px;
      z-index: 1;
    }

    .btn-regular {
      width: 44px;
      min-width: 44px;
      padding: 0 !important;
    }

    .btn-regular .svg-icon {
      width: 24px;
      height: 24px;
      display: block;
    }
  }
}

.popup.auto-event-form {
  max-width: 630px;
  width: 100%;
  padding-left: 16px;

  .form-smartEvent {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 70px;

    .popup-panel-form {
      overflow: auto;
      padding-bottom: 0;
    }
  }

  .main-title {
    padding-left: 16px;
  }

  .wrap {
    padding: 0 16px 24px;
  }

  .privacySettingsForm {
    margin-top: 24px;
  }

  .badgeGroupMsg {
    margin-top: 5px;
  }

  .autoEvent_users {
    margin-top: 20px;
  }

  .infoWrap {
    span {
      display: inline-block;
    }
  }

  .autoEvent_mention {
    &__input {
      border: none;
      padding: 18px;
    }
  }

  .groupField {
    margin-top: 24px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;

    .label + .label {
      margin-top: 0;
    }
  }
  .rs-radio-inline {
    &:last-child {
      margin-left: 24px;
    }
  }

  .news-item {
    grid-template-columns: 200px 1fr;
    column-gap: 40px;
  }
}

@media screen and (max-width: 1460px) {
  .manageAutoEvents {
    &__list {
      grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    }
  }
}

@media screen and (max-width: 800px) {
  .manageAutoEvents {
    margin-top: 20px;
  }
  .popup.auto-event-form {
    max-width: 100%;

    .groupField {
      display: block;

      .label + .label {
        margin-top: 24px;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .manageAutoEvents {
    .autoEventCard {
      display: block;

      &__eventName {
        margin-bottom: 16px;
      }

      &__control {
        width: max-content;
        margin-left: auto;
      }
    }
  }
}
