@import "../../../theme-colors";

.employeePage {
  .pageTabs {
    margin-bottom: 36px;
  }
  .customCarousel {
    margin: 0;
  }
  .badgesBlock {
    margin-bottom: 32px;

    &__title-block {
      display: flex;
      align-items: center;
      gap: 16px;

      .main-title {
        margin: 0;
      }
    }
    .badge-label-primary {
      box-shadow: 0 4px 9px -2px rgba(16, 24, 40, 0.1019607843);
    }
    &__image-wrap {
      position: relative;
    }
    .customCarousel__menu {
      flex-direction: row;
      align-items: center;
    }
    .customCarouselItem {
      position: relative;
    }

    .boxItemImg__imgWrap.lock {
      opacity: 0.25;
    }
    .lockWrapIcon {
      position: absolute;
      background-color: $links-color;
      padding: 11px 12px 12px;
      border-radius: 50%;
      color: $blocks-bg-color;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
      opacity: 1;
    }
    .lockIcon {
      width: 46px;
      height: 46px;
    }
    .customCarouselItem__label {
      position: absolute;
      bottom: 34px;
      right: 34px;
      color: $blocks-bg-color;
      background-color: $yellow-border-label-color;
      border: 1px solid $yellow-border-label-color;
    }
  }
  .employeeAchievements {
    display: grid;
    grid-template-columns: 1fr 535px;
    grid-column-gap: 125px;


    .employeeAchievementsMsgs,
    .feedbackRequests {
      .main-title {
        margin-bottom: 32px;
      }
    }
    .feedbackRequests {
      .user-card.box {
        margin-bottom: 24px;
      }
    }

    .feedbacksBtns {
      margin-bottom: 32px;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 32px;

      &__feedback {
        width: 248px;
      }
    }
  }
  .employeeProfile {
    margin-bottom: 32px;
    position: relative;

    .coverImageWrap {
      height: 200px;
      border-radius: 20px 20px 0 0;
      margin-bottom: 0;

      .coverImage {
        border-radius: 20px 20px 0 0;
      }
    }

    &__userInfo {
      display: flex;
      gap: 80px;
      align-items: center;
      background-color: $blocks-bg-color;
      border-radius: 0 0 20px 20px;
      padding: 28px 60px;

      p + p {
        margin-top: 0;
      }

    }

    &__location {
      display: flex;
      gap: 10px;
      align-items: center;

      .ic-address {
        color: $secondary-text-color;
      }
    }

    &__location-active {
      cursor: pointer;

      .secondary-title {
        color: $links-color;

        &:hover {
          transition: all 0.5s;
          text-decoration: underline;
        }
      }

      .ic-address {
        color: $links-color;
      }
    }

    &__avatarBtn {
      visibility: hidden;
      opacity: 0;
      width: max-content;
      background-color: $blocks-bg-color;
      display: flex;
      gap: 10px;
      align-items: center;
      position: absolute;
      border: 1px solid $border-secondary-color;
      bottom: -10px;
      padding: 10px;
      border-radius: 10px;
      box-shadow: 0 4px 9px -2px rgba(16, 24, 40, 0.1),
        0px 4px 9px -2px rgba(16, 24, 40, 0.1);
      color: #272727;
      font-family: Open Sans, sans-serif;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      transition: opacity 0.3s;
    }

    &__avatarIcon {
      width: 24px;
      height: 24px;
    }

    &__avatar {
      position: absolute;
      top: 50px;
      right: 90px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 200px;
      border-radius: 50%;
      background-color: $border-color;
      border: 5px solid $blocks-bg-color;

      &:hover {
        .employeeProfile__avatarBtn {
          visibility: visible;
          opacity: 1;
        }
      }
    }

    &__avatarImg {
      border-radius: 50%;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__avatarName {
      font-size: 36px;
      color: $text-links-color;
    }
  }
  &__item {
    margin-top: 32px;
    display: grid;
    grid-template-columns: 30% auto;
    gap: 16px;

    .field-label-required {
      margin-bottom: 0;
      font-size: 16px;
      line-height: 24px;
      color: $secondary-text-color;
      font-weight: 700;
    }
  }

  &__label,
  &__value {
    font-size: 16px;
    line-height: 24px;
  }

  &__label {
    display: block;
    color: $secondary-text-color;
    font-weight: 700;

    &--flex {
      display: flex;
      align-items: center;
      gap: 14px;
    }
  }

  &__value {
    display: block;
    color: $main-text-color;
    font-weight: 400;
  }
  .formButtons {
    @include d-flex();
    justify-content: flex-end;

    &__formBtn {
      margin-top: 20px;
      margin-left: 20px;
    }
  }
}

@media screen and (max-width: 1800px) {
  .employeePage {
    .employeeAchievements {
      column-gap: 70px;
    }
  }
}

@media screen and (max-width: 1600px) {
  .employeePage {
    .employeeProfile {
      &__avatar {
        right: 30px;
      }

      &__userInfo {
        padding: 28px 30px;
        gap: 50px;
      }
    }
    .employeeAchievements {
      grid-template-columns: 60% 350px;
      justify-content: space-between;

      .receivedBadgesWrap {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
}

@media screen and (max-width: 1450px) {
  .employeePage {
    .employeeProfile {
      &__userInfo {
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
      }
    }

    .employeeAchievements {
      display: flex;
      gap: 32px;
      flex-direction: column-reverse;

      .feedbacksBtns,
      .receivedBadgesWrap {
        gap: 24px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .employeePage {
    .pageTabs.mainTabs {
      width: 100%;
      justify-content: space-between;
    }
    .pageTabs {
      &__hiddenLink {
        display: block;
      }
      &__popoverLink {
        display: none;
      }
    }
    &__popover.placement-bottom-end {
      padding: 8px;
      transform: translate(-28px, 16px);
    }
  }
}

@media screen and (max-width: 800px) {
  .receivedBadgesWrap {
    .boxItemImg {
      padding: 24px;
    }
  }
}

@media screen and (max-width: 950px) {
  .employeePage {
    .employeeProfile {
      &__userInfo {
        align-items: center;
        padding-top: 70px;
      }

      &__avatarBtn {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .employeePage {
    .employeeProfile {
      &__avatar {
        right: initial;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .employeePage {
    &__item {
      display: block;
      margin-top: 24px;
    }

    &__label {
      margin-bottom: 5px;
      margin-top: 16px;
    }
    &__value {
      margin-bottom: 16px;
    }
  }
}
