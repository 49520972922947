@import "../theme-colors";

.filtersBlock {
  &__radio {
    display: none;
  }
  &__control {
    gap: 34px;
  }
  &__wrap {
    align-items: flex-start;
    gap: 24px;
  }
  &__icon {
    width: 24px;
    height: 24px;
  }
  .actionBtn-regular {
    color: $text-links-color;

    &:hover {
      color: #fff;
    }
  }
  .group-btn {
    flex-grow: 1;
    justify-content: flex-start;
    gap: 24px;
  }
  .date-range-picker {
    max-width: 440px;
  }
  .selects-wrap {
    display: flex;
    gap: 24px;
  }
  .main-filter-select,
  .additional-filters-select{
    width: 440px;
  }
  .filter-row {
    display: flex;
    gap: 24px;
    margin-bottom: 24px;
  }
  .btn-info {
    width: 440px;
    cursor: default;

    &:hover{
      background-color: transparent;
      color: $text-links-color;
      border: 1px solid $border-secondary-color;
      box-shadow: none;
    }
  }
  .filtersBlock__error {
    color: $error-text-color;
    text-align: center;
    font-family: "Open Sans", sans-serif;
  }
  .filtersBlock__fieldError {
    margin-bottom: 24px;
    text-align: center;
    color: $error-text-color;
  }

  .statusFilter {
    font-size: 24px;
    line-height: 36px;
    padding: 16px 24px;
    border-radius: 10px;
    box-shadow: 0px 4px 9px -2px #1018281A;
    background-color: $main-bg-color;
    color: $text-links-color;
    overflow: hidden;
    transition: all 0.3s;

    p {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &:hover {
      cursor: pointer;
      box-shadow: 0px 4px 9px -2px #10182833;
    }

    &__number {
      font-size: 60px;
      line-height: 72px;
      letter-spacing: -0.02em;
    }
  }

  .statusFilter.blue {
    border: 1px solid $blue-color;
  }

  .statusFilter.yellow {
    border: 1px solid $yellow-border-label-color;
  }

  .statusFilter.green {
    border: 1px solid $green-color;
  }

  .statusFilter.red {
    border: 1px solid $red-color;
  }

  .statusFilter.active {
    border: 1px solid transparent;
  }

  .statusFilter.blue.active {
    background-color: $blue-light;
  }

  .statusFilter.yellow.active {
    background-color: $light-yellow;
  }

  .statusFilter.green.active {
    background-color: $green-light;
  }

  .statusFilter.red.active {
    background-color: $light-pink;
  }
}

@media screen and (max-width: 1400px) {
  .filtersBlock {
    .group,
    .group-btn {
      flex-wrap: wrap;
    }

    .date-range-picker {
      max-width: 100%;
    }

    .selects-wrap {
      flex-direction: column;
      width: 100%;
    }

    .filter-row {
      align-items: center;
    }

    .main-filter-select,
    .additional-filters-select,
    .btn-info {
      width: 100%;
    }
  }
}

@media screen and (max-width: 600px) {
  .filtersBlock {
    &__wrap {
      flex-direction: column-reverse;
      align-items: center;
    }

    .filter-row {
      gap: 10px;
    }
    .btn-info {
      white-space: normal;
      height: max-content;
    }
  }
}