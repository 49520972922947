@import "../theme-colors";

.customCarousel {
  &__tabsWrap {
    display: flex;
    gap: 24px;
    align-items: center;

    .pageTabs {
      margin-bottom: 0;
    }
  }

  &__scrollBtnsWrap {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__scrollBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;

    .arrowIconLeft,
    .arrowIconRight {
      width: 20px;
      height: 20px;
    }

    .arrowIconLeft {
      transform: rotate(90deg);
    }

    .arrowIconRight {
      transform: rotate(-90deg);
    }
  }

  &__carousel {
    position: relative;
    border-right: 2px solid $border-color;

    &:before,
    &:after {
      display: none;
      content: "";
      position: absolute;
      top: 0;
      height: 100%;
      width: 66px;
    }
  }

  &__wrap {
    display: flex;
    gap: 32px;
    overflow-x: auto;
    padding-bottom: 32px;

    &::-webkit-scrollbar {
      height: 16px;
    }
    &::-webkit-scrollbar-track {
      background: $blocks-bg-color;
    }
    &::-webkit-scrollbar-thumb {
      width: 10px;
      background: $border-secondary-color;
      border-radius: 10px;
    }
  }

  &__carousel.carouselLeftScroll {
    border-right: none;

    &:before {
      display: block;
      left: 0;
      background: linear-gradient(
        270deg,
        rgba(250, 250, 250, 0) 0%,
        $main-bg-color 91%,
        $main-bg-color 100%
      );
      z-index: 2;
    }
  }

  &__carousel.carouselRightScroll {
    &:after {
      display: block;
      right: 0;
      background: linear-gradient(
        90deg,
        rgba(250, 250, 250, 0) 0%,
        $main-bg-color 91%,
        $main-bg-color 100%
      );
    }
  }

  .customCarouselItem {
    .boxItemImg__imgWrap {
      padding: 0;
      position: relative;

      img {
        border-radius: 10px;
        object-fit: cover;
        object-position: center;
        height: 100%;
        width: 100%;
      }

      .customCarouselItem__statuses {
        display: flex;
        gap: 8px;
        flex-wrap: wrap;
        position: absolute;
        top: 8px;
        left: 8px;
      }
    }

    &__label {
      padding: 4px 16px;
      margin-top: 16px;
      border-radius: 40px;
      border: 1px solid $border-secondary-color;
      background: $main-bg-color;
      color: $text-links-color;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }

    &__place {
      font-weight: 700;
    }
  }
}

@media screen and (max-width: 1300px) {
  .customCarousel {
    .pageTabs.mainTabs {
      width: 100%;
      justify-content: space-between;
    }
    .pageTabs {
      &__hiddenLink {
        display: block;
      }
      &__popoverLink {
        display: none;
      }
    }
    &__popover.placement-bottom-end {
      padding: 8px;
      transform: translate(-28px, 16px);
    }
  }
}

@media screen and (max-width: 1300px) {
  .customCarousel {
    &__menu {
      flex-direction: column;
      gap: 16px;
      align-items: flex-start;
    }

    &__tabsWrap {
      width: 100%;
    }
  }
}
