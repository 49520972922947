.container {
  margin-left: 280px;
  padding: 44px 32px;
}

.containerOrgChart {
  padding: 44px 0;
}

@media screen and (max-width: 1000px) {
  .container {
    margin-left: 225px;
    padding: 32px 19px;
  }
  .containerOrgChart {
    padding: 32px 0;
  }
}

@media screen and (max-width: 800px) {
  .container {
    margin-left: 0;
  }
}
