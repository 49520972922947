/* used as part Request Feedback animation on Profile Page */

.preloader:after {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.9);
  z-index: 99991;
}
.preloader-wrap {
  display: none;
}
.preloader .preloader-wrap {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99992;
  display: block;
}
.preloader .preloader-wrap svg {
  width: 100px;
  height: 100px;
}