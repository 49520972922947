@import "../theme-colors";

.customFiltersFormWrap {
  display: flex;
  gap: 30px;
  align-items: center;
  margin-bottom: 24px;

  .customFiltersForm {
    display: flex;
    flex-grow: 1;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    padding: 8px;
    border: 2px solid $border-secondary-color;
    border-radius: 8px;

    &__dateRange {
      width: 300px;
      .rs-input-group.rs-input-group-inside,
      .rs-input {
        background-color: $border-secondary-color;
        color: $main-text-color;
      }
      .rs-picker-label.rs-input-group-addon {
        color: $main-text-color;
      }
      .rs-picker-clean:hover,
      .rs-picker-clean:focus {
        color: $gray-color;
      }
      .rs-btn-close svg path,
      .rs-btn-close:hover svg path {
        stroke: $gray-color;
        stroke-width: 1.25;
      }
      .datePickers__calendarIcon {
        width: 20px;
        height: 20px;
        color: $gray-color;
      }
    }

    .addFilterBtn {
      color: $gray-color;
      background-color: $border-secondary-color;
      width: 32px;
      height: 32px;

      &__icon {
        width: 24px;
        height: 24px;
      }
    }

    .addCustomFiltersBtn.addFilterBtn {
      width: max-content;
      border-radius: 8px;
      padding: 0 8px;
      font-weight: 400;

      .addFilterBtn__icon {
        margin-left: 8px;
      }
    }

    &__mainFilter {
      width: max-content;
      padding: 0 4px 0 10px;
      border-radius: 8px;
      font-size: 16px;
      font-weight: 400;
      line-height: 32px;
    }

    .asyncSelect__container {
      min-width: 200px;
      width: max-content;
    }

    .asyncSelect__container .asyncSelect__control {
      border-radius: 0;
      min-height: 34px;
    }

    .asyncSelect__container .asyncSelect__value-container {
      padding: 0 14px;
    }

    &__filterValue {
      display: flex;
      align-items: center;
      gap: 8px;
      border-radius: 8px;
      font-size: 16px;
      font-weight: 400;
      line-height: 32px;
      padding: 0 4px 0 10px;


      .removeFilterBtn {
        display: flex;
        background-color: transparent;

        svg {
          width: 22px;
          height: 22px;
        }
      }
    }

    .filterLabel__red {
      background-color: $pink-label-color;

      .asyncSelect__container .asyncSelect__control,
      .asyncSelect__container .asyncSelect__control:hover {
        border: 1px solid $pink-border-label-color;
      }

      .removeFilterBtn {
        color: $pink-border-label-color;
      }
    }

    .filterLabel__blue {
      background-color: $button-selected-color;

      .asyncSelect__container .asyncSelect__control,
      .asyncSelect__container .asyncSelect__control:hover {
        border: 1px solid $links-secondary-color;
      }

      .removeFilterBtn {
        color: $links-secondary-color;
      }
    }

    .filterLabel__purple {
      background: $purple-label-color;

      .asyncSelect__container .asyncSelect__control,
      .asyncSelect__container .asyncSelect__control:hover {
        border: 1px solid $purple-border-label-color;
      }

      .removeFilterBtn {
        color: $purple-border-label-color;
      }
    }

    .filterLabel__yellow {
      background: $yellow-label-color;

      .asyncSelect__container .asyncSelect__control,
      .asyncSelect__container .asyncSelect__control:hover {
        border: 1px solid $yellow-border-label-color;
      }

      .removeFilterBtn {
        color: $yellow-border-label-color;
      }
    }
  }
}


.customFiltersFormPopover {
  width: 185px;
  transform: translate(12px, 7px);

  .customPopover__btn:disabled {
    color: $gray-color;
    cursor: not-allowed;
  }
}

@media screen and (max-width: 600px) {
  .customFiltersFormWrap {
    gap: 10px;

    .customFiltersForm {
      &__dateRange {
        width: 100%;
      }
      &__selectWrap {
        min-width: 100%;
      }
      .asyncSelect__container {
        flex-grow: 1;
        min-width: fit-content;
      }
      .addFilterBtn {
        padding: 0;
      }
    }
  }
}