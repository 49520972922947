@import "../../theme-colors";

/*  05. manage-badges-page
------------------------------------------------- */
.manage {
  .badgesManagementWrap {
    gap: 16px;
  }
  .badgeGroup {
    margin-top: 32px;
  }

  .badgeGroupTitle {
    display: flex;
    gap: 24px;
    align-items: center;

    .badgeGroupLine {
      flex-grow: 1;
      height: 1px;
      background-color: $text-links-color;
    }
  }

  .editRemoveGroupWrap {
    gap: 16px;
  }

  .no-content-message.empty-content-message {
    margin: 24px 0;
  }

  .manage-item {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 250px));
    justify-content: space-between;
    column-gap: 32px;
    row-gap: 24px;
    margin: 24px 0;
  }

  .badgeItem {
    padding: 24px 24px 16px;
  }

  .badgeImgWrap {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 200px;
    border-radius: 10px;
    border: 1px solid $border-secondary-color;
    background: $main-bg-color;
    margin-bottom: 16px;

    img {
      max-height: 100%;
    }
  }
}

.badgeTooltip.rs-tooltip[class*="placement-top"].rs-anim-in {
  box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  transition: opacity 0.15s linear;
}
.badgeTooltip.rs-tooltip.placement-top-start:after {
  left: 15px;
}

.addBadgeForm .new-wrap {
  padding-right: 16px;
  display: flex;
  flex-direction: column;
}

.addBadgeForm .btn-additional {
  margin-top: 16px;
}

.addBadgeForm .select__container .select__menu-list {
  max-height: 130px;
}

.addBadgeGroupPopup {
  .privacySettingsForm {
    margin-top: 20px;

    .rs-radio-inline:last-child {
      margin-left: 24px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .manage {
    .manage-item {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

      .badgeImgWrap {
        max-width: 200px;
        width: 100%;
        margin: 0 auto 16px;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .badgeManagementTooltip {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .manage {
    .manage-item {
      margin: 24px auto;
      width: max-content;

      .badgeImgWrap {
        margin: 0 auto 16px;
      }
    }

    .badgeGroupTitle {
      .title-block {
        max-width: 50%;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .manage {
    .badgeGroupTitle {
      flex-wrap: wrap;
      gap: 10px;

      .badgeGroupLine {
        display: none;
      }
    }
  }
}
