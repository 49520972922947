@import "../../../theme-colors";

.addEditAchievement {
  .addEditAchievementForm {
    &__content {
      display: grid;
      grid-template-columns: 1fr 534px;
      column-gap: 64px;
    }

    &__block {
      display: flex;
      flex-direction: column;
      max-width: 912px;
      width: 100%;
      height: 100%;

      &:last-child {
        justify-content: space-between;
      }
    }

    .achievementNameField {
      position: relative;
      box-sizing: border-box;
      margin-bottom: 24px;
      border: none;

      &__label {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        user-select: none;
        cursor: text;

        .field-label-required {
          font-size: 36px;
          line-height: 44px;
          font-weight: 700;
          color: $secondary-text-color;
        }
      }

      &__label.hide {
        display: none;
      }

      .form-textarea {
        border: none;
        padding: 0;
        font-size: 36px;
        line-height: 44px;
        font-weight: 700;
        background-color: transparent;
        min-height: 44px;

        // hide original placeholder
        &::placeholder {
          color: transparent;
        }
      }
    }

    &__divider {
      width: 100%;
      border-bottom: 1px solid $text-links-color;
      margin-top: 10px;
    }

    .coverImageBlock {
      margin-bottom: 32px;

      .coverImageWrap {
        margin-bottom: 0;
      }
    }

    .achievementCategoriesSelect {
      .select__multi-value {
        color: $text-links-color;
      }

      .select__multi-value__label {
        color: $text-links-color;
      }

      .select__multi-value__remove:hover {
        background-color: transparent;
        color: $text-links-color;
      }

      .select__multi-value__remove:hover svg {
        fill: $text-links-color;
      }
    }

    .privacySettingsForm {
      margin-top: 24px;
    }

    .group-btn {
      margin-top: 100px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      gap: 16px;
      width: 100%;
    }
    .rs-radio-inline {
      &:last-child {
        margin-left: 24px;
      }
    }
    .rs-radio-checker {
      padding-left: 32px;
    }
    .customBreadcrumbs {
      margin-bottom: 24px;
    }
    .checkboxLabel {
      margin-right: 32px;
    }
  }

  .ql-editor {
    min-height: 437px;
  }
}

@media screen and (max-width: 1564px) {
  .addEditAchievement {
    .addEditAchievementForm {
      &__content {
        grid-template-columns: 1fr 400px;
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .addEditAchievement {
    .addEditAchievementForm {
      &__content {
        grid-template-columns: 1fr;
        margin-bottom: 24px;
      }

      &__block {
        max-width: 100%;

        &:last-child {
          margin-top: 24px;
          max-width: 100%;
        }
      }
      .group-btn {
        justify-content: flex-start;
      }
    }
    .ql-editor {
      min-height: 300px;
    }
  }
}

@media screen and (max-width: 800px) {
  .addEditAchievement {
    margin-top: 20px;
  }
}
