@import "../../../theme-colors";

.achievementPage {
  .customBreadcrumbs {
    margin-bottom: 24px;
  }

  &__coverBlock {
    position: relative;
    margin-bottom: 32px;

    .popoverBtn {
      position: absolute;
      top: 24px;
      right: 24px;
      background-color: $blocks-bg-color;
      border: 1px solid $border-color;

      &:hover {
        background-color: $border-color;
      }
    }
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr 35%;
    gap: 127px;
  }

  .achievementTimeline .box {
    padding: 12px;
  }

  &__sideContent {
    .sideBtn {
      width: 100%;
      margin-bottom: 18px;
    }

    .achievementReactions {
      margin-bottom: 24px;
    }
  }

  &__coinsBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__coins {
    gap: 16px;
    justify-content: flex-start;
    margin-bottom: 16px;

    .statisticBlock__value {
      margin-bottom: 0;
    }
  }

  .ql-editor {
    padding: 0;
  }

  .achievementDetails {
    .achievementTimeline {
      display: none;
    }
    &__title {
      margin-bottom: 24px;
    }

    &__shortDesc {
      margin-bottom: 30px;
      color: $main-text-color;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
    }

    &__line {
      width: 100%;
      height: 0.5px;
      background-color: $text-links-color;
      margin-bottom: 48px;
    }
  }

  &__statsWrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;
    margin-top: 34px;
  }

  .preview-btns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 32px;

    .btn-primary {
      margin-left: 32px;
    }
  }

  &__list-label {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    position: absolute;
    top: 30px;
    left: 32px;
    margin-right: 84px;
  }
}

.achievementParticipantsCounter {
  display: flex;
  gap: 8px;
  margin-bottom: 32px;
  padding: 6px 12px;
  width: max-content;
  max-width: 100%;
  border-radius: 75px;
  border: 1.5px solid $border-secondary-color;
  background: $main-bg-color;
  transition: box-shadow 0.3s;

  &:hover:enabled {
    box-shadow: 0 4px 9px -2px rgba(16, 24, 40, 0.1),
      0px 4px 9px -2px rgba(16, 24, 40, 0.1);
  }

  &:disabled {
    background: $main-bg-color;
    color: $secondary-text-color;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
  }

  .participantsAvatars {
    display: flex;
  }

  .userBlock {
    gap: 8px;
  }

  .user-avatar {
    margin-right: 0;
    border: 1px solid $blocks-bg-color;
    position: relative;
  }

  .user-name {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
  }

  .changeCountUsers {
    position: relative;
    left: -25px;
  }
}

.achievementParticipantsCounter.active {
  background: $border-secondary-color;
  box-shadow: 0 4px 9px -2px rgba(16, 24, 40, 0.1),
    0px 4px 9px -2px rgba(16, 24, 40, 0.1);
}

.achievementParticipantsCounter.smallCounter {
  padding: 6px 0 6px 12px;
}

.achievementParticipantsModal {
  &__tabsWrap {
    gap: 16px;
    padding: 0 24px 16px 12px;

    .pageTabs {
      margin-bottom: 0;
    }
  }
}

.achievementParticipants {
  .achievementParticipantsList {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 32px;
    row-gap: 16px;

    .user-avatar {
      margin-right: 0;
    }

    .user-card.box {
      margin-bottom: 0;
    }
  }

  &__popover {
    padding: 16px 24px;
    transform: translate(-18px, 5px);
  }

  &__menuPopover.customPopover {
    z-index: 1060;
  }
}

.applyAchievementPopup {
  max-width: 650px;
  width: 100%;

  &__proofs {
    margin-bottom: 8px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 24px;
  }

  &__form {
    justify-content: space-between;
    display: flex;
    align-items: initial;
    flex-direction: column;
    height: 100%;
    padding-bottom: 50px;

    .wrap {
      padding-right: 16px;
    }
  }

  &__eventWrap {
    margin-bottom: 16px;
  }

  &__eventName {
    font-size: 24px;
    line-height: 32px;
  }

  &__eventInfo {
    margin-bottom: 32px;
  }

  .achievementCard__list-label {
    margin-bottom: 0;
  }

  .tagLabel {
    cursor: auto;
  }

  &__coins {
    display: flex;
    align-items: center;
    color: $blocks-bg-color;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    padding: 2px 10px;
    gap: 10px;
    background-color: $text-links-color;
    border-radius: 40px;

    .ic-coins {
      width: 14px;
      height: 14px;
    }
  }

  &__divider {
    width: 100%;
    height: 2px;
    background-color: $border-secondary-color;
    margin-bottom: 32px;
  }

  .customTextEditor {
    .ql-toolbar.ql-snow,
    .ql-container.ql-snow {
      transition: border-color 0.3s;
    }
  }

  .richTextError.customTextEditor {
    .ql-toolbar.ql-snow,
    .ql-container.ql-snow {
      border: 2px solid $red-color;
    }

    .ql-toolbar.ql-snow + .ql-container.ql-snow {
      border-top: 0;
    }
  }

  .attachedFiles__wrap {
    margin-top: 32px;
  }

  .attachedFiles__title {
    display: flex;
    position: relative;

    .formatsTooltip {
      width: 100%;
    }

    .field-label-required {
      margin-bottom: 0;
    }

    .attachedFiles__infoWrap {
      margin-left: 24px;
    }

    .ic-info {
      width: 12px;
      height: 12px;
      margin-bottom: 3px;
      margin-left: 0;
    }
  }

  .errorDocuments {
    margin-top: 16px;
  }

  .uploadPhotosWrap {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .uploadEventDocuments {
    transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
  }

  .uploadEventDocumentsError {
    border: 0.5px solid $red-color;
    box-shadow: inset 0 0 20px rgba(224, 79, 73, 0.5),
      0 0 20px rgba(224, 79, 73, 0.2);
    animation: shake 0.2s 0.7 linear;

    &:hover {
      border: 1px solid $text-links-color;
      box-shadow: 0 4px 9px -2px rgba(16, 24, 40, 0.1),
        0px 4px 9px -2px rgba(16, 24, 40, 0.2);
    }
  }
}

@keyframes shake {
  0% {
    transform: translate(10px);
  }
  20% {
    transform: translate(-10px);
  }
  40% {
    transform: translate(5px);
  }
  60% {
    transform: translate(-5px);
  }
  80% {
    transform: translate(2px);
  }
  100% {
    transform: translate(0px);
  }
}

@media screen and (max-width: 1600px) {
  .achievementPage {
    &__content {
      gap: 50px;
    }
  }
}

@media screen and (max-width: 1400px) {
  .achievementPage {
    .achievementDetails {
      margin-top: 0;

      .achievementTimeline {
        display: flex;
      }
    }

    &__content {
      display: flex;
      flex-direction: column-reverse;
    }
    &__sideContent {
      .sideBtn {
        width: max-content;
        margin: 0 auto 32px;
      }
      .achievementTimeline {
        display: none;
      }
    }
  }
  .achievementParticipantsModal {
    margin: 60px auto 0;

    &__tabsWrap {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .achievementParticipants {
    .achievementParticipantsList {
      grid-template-columns: 100%;
    }
  }
}

@media screen and (max-width: 800px) {
  .achievementParticipants__popover {
    padding: 16px;
  }
  .applyAchievementPopup {
    &__form {
      padding-bottom: 100px;
    }
  }

  .achievementParticipantsModal {
    .rs-modal-body {
      max-height: 300px !important;
    }
  }
}

@media screen and (max-width: 600px) {
  .achievementPage {
    &__list-label {
      top: 24px;
      margin-right: 32px;
      flex-direction: column;
      flex-wrap: nowrap;
    }
  }
  .applyAchievementPopup {
    .attachedFiles__wrap {
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
    }
  }
}

@media screen and (max-width: 500px) {
  .achievementPage {
    &__statsWrap {
      grid-template-columns: 1fr;
    }
  }
  .applyAchievementPopup {
    &__proofs {
      display: flex;
      flex-direction: column-reverse;
      gap: 16px;
    }
  }
}

@media screen and (max-width: 400px) {
  .applyAchievementPopup {
    .form-style .textBlock {
      .textBlock__value {
        margin-bottom: 0;
      }
    }
  }
  .achievementParticipantsCounter {
    .user-avatar {
      width: 24px;
      min-width: 24px;
      height: 24px;
    }

    .avatarSign {
      font-size: 10px;
    }

    .user-name {
      font-size: 14px;
      line-height: 20px;
    }
  }
  .achievementParticipantsModal {
    .rs-modal-body {
      padding: 0 10px 12px;
    }
  }
}
