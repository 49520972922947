/* Import Users popup styles*/

.popup-import {
  max-width: 1320px;
  width: 100%;

  .wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .customTableWrap .rs-table-cell-header .rs-table-cell-content {
    align-items: flex-start;
    padding: 12px 24px;
  }

  .customTableWrap .rs-table-cell-content {
    padding: 16px 24px;
  }

  .customTableWrap .rs-table-cell-last .rs-table-cell-content {
    justify-content: flex-start;
    padding: 16px 24px;
  }

  .import-results {
    .imported-successfully {
      margin-bottom: 50px;
    }

    dl.import-errors-dl {
      margin-bottom: 50px;

      dt {
        margin-top: 15px;
        font-weight: bold;
      }

      dd {
        margin-left: 30px;
        margin-bottom: 5px;
      }
    }
  }
}

.tableTooltip {
  width: max-content;
  visibility: hidden;
  background-color: $blocks-bg-color;
  color: $text-links-color;
  box-shadow: 0px 4px 9px -2px #1018281a;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  top: -110%;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
}

.managerEmailColumn .tableTooltip {
  right: 0;
}

.tooltipColumn:hover .tableTooltip {
  visibility: visible;
  opacity: 1;
}
