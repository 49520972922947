@import "../../theme-colors";

/*  13. page analytics
------------------------------------------------- */
.page-analytics {
  .insightsBlock {
    margin: 36px 0 24px;

    .title-block {
      margin-bottom: 24px;
    }

    &__list {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(368px, max-content));
      column-gap: 32px;
      row-gap: 24px;
    }

    &__box-title {
      font-size: 16px;
      line-height: 32px;
      font-weight: 400;
      margin-bottom: 8px;
    }

    &__box-subtitle {
      font-size: 16px;
      line-height: 32px;
      margin: 8px 0 4px;
    }

    &__box-content {
      font-weight: 400;
      line-height: 54px;
      font-size: 36px;
      color: $text-links-color;

      &:last-child {
        margin: 0;
      }
    }

    &__box-content.boxContentWrap {
      word-break: break-all;
      font-size: 24px;
      line-height: 36px;
    }
  }
}

.peopleOverBlock,
.activeUsers,
.assignedPeople {
  max-width: 100%;
  &__subtitle {
    line-height: 32px;
    margin: 8px 0 24px;
  }

  &__name {
    font-weight: bold;
  }

  &__position {
    font-size: 12px;
    line-height: 18px;
    color: $secondary-text-color;
  }

  &__table-content {
    overflow-y: auto;
    overflow-x: clip;
    max-height: 480px;
    min-width: 1200px;
    padding-bottom: 24px;

    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-track {
      background: $blocks-bg-color;
    }
    &::-webkit-scrollbar-thumb {
      width: 10px;
      background: $border-secondary-color;
      border-radius: 10px;
    }
  }

  .title-block {
    margin-bottom: 24px;
  }
  .table-wrap {
    .table-inner {
      min-width: 1200px;
      .item-th {
        display: flex;
        align-items: center;
      }
      .item-col:nth-child(1) {
        width: 100%;
        min-width: 40%;
        justify-content: flex-start;
      }

      .item-col:nth-child(2) {
        width: 100%;
        min-width: 18%;
      }
      .item-col:nth-child(3),
      .item-col:nth-child(4),
      .item-col:nth-child(5) {
        width: 100%;
        min-width: 10%;
      }
    }
    .table-header {
      border-color: $secondary-text-color;
      overflow-x: auto;
    }
    .item-col:last-child {
      padding: 0;
      text-align: left;
    }
    .user-avatar {
      width: 62px;
      height: 62px;
      margin-right: 24px;
    }
  }
  .table-wrap.borderBottom {
    border-bottom: 1px solid $secondary-text-color;
  }
  .activeStatusIndicator::after {
    width: 22px;
    height: 22px;
    border: 2px solid $blocks-bg-color;
  }
}
.assignedPeople {
  max-height: 570px;
  .table-wrap .user-avatar {
    width: 48px;
    height: 48px;
  }

  .activeStatusIndicator::after {
    width: 18px;
    height: 18px;
    border: 2px solid $blocks-bg-color;
  }
}

@media screen and (max-width: 1600px) {
  .page-analytics {
    .insightsBlock {
      &__list {
        grid-template-columns: repeat(auto-fit, minmax(290px, max-content));
      }
    }
  }

  @media screen and (max-width: 1400px) {
    .page-analytics {
      .insightsBlock {
        &__list {
          grid-template-columns: auto auto;
        }
        .box {
          &:last-child {
            grid-column: span 2;
          }
        }
      }
    }
  }

  @media screen and (max-width: 800px) {
    .page-analytics {
      .mobileAnalyticsHeader {
        .pageTabs.mainTabs {
          width: 100%;
          justify-content: space-between;
        }
        .pageTabs {
          &__hiddenLink {
            display: block;
          }
          &__popoverLink {
            display: none;
          }
        }
        &__popover.placement-bottom-end {
          padding: 8px;
          transform: translate(-28px, 16px);
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    .page-analytics {
      .insightsBlock {
        &__list {
          grid-template-columns: 1fr;
        }
        .box {
          &:last-child {
            grid-column: span 1;
          }
        }
        &__box-content {
          word-break: break-all;
          font-size: 24px;
          line-height: 36px;
        }
      }
    }
  }
}
