@import "../../../theme-colors";

/*  10. account-settings page
------------------------------------------------- */
.head-settings {
  margin-bottom: 48px;
  display: flex;
  justify-content: space-between;
}
.head-settings .left {
  width: 28%;
}
.head-settings .right {
  width: 64%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 127px;
}
.account-settings .head-settings .file-upload {
  .file-upload__image-block {
    width: 100%;
    max-width: 100%;
    height: 314px;
    box-shadow: none;
    border: 1px solid $border-secondary-color;
    padding: 0 32px;
    margin-bottom: 8px;
  }
  .form-style,
  .btn-regular {
    width: 100%;
  }
  .file-upload__image-preview {
    background-color: $blocks-bg-color;
    border: none;
  }

  .file-upload__image {
    border-radius: 0;
  }
}

.head-settings .language {
  margin-bottom: 32px;

  .label-title {
    margin-bottom: 24px;
  }

  .language-wrap {
    .group-btn {
      margin-top: 24px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 40px;
    }
    .select__container {
      min-width: 100px;
      width: 100%;
    }
  }
  .language .form-style,
  .label-title {
    font-weight: 400;
  }
}

.head-settings .import {
  .label-title {
    margin-bottom: 24px;
  }

  .addMembersInfo {
    color: $text-links-color;
    font-size: 14px;
  }

  .form-import {
    &__upload-buttons,
    .group-btn {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 40px;
    }
    .file-upload {
      width: 100%;
    }
    .icon svg {
      position: static;
      transform: none;
    }

    .import-file-text {
      margin-right: 24px;
    }

    .group-btn {
      margin-top: 24px;
    }
  }

  .form-style,
  .label-title {
    font-weight: 400;
  }
  .btn-regular {
    margin: 0;
  }
}

.head-setting,
.add-member-block {
  margin-bottom: 24px;

  .label-title {
    font-weight: 400;
    margin-bottom: 0;
  }

  .btn-regular {
    width: 100%;
  }
}

.account-settings {
  .membersTitleWrap {
    flex-wrap: wrap;
    gap: 16px;

    .label-title {
      font-weight: 400;
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: 1900px) {
  .head-settings .right {
    column-gap: 50px;
  }
}

@media screen and (max-width: 1700px) {
  .head-settings .right {
    column-gap: 40px;
  }
}

@media screen and (max-width: 1620px) {
  .head-settings {
    gap: 80px;
    justify-content: flex-start;

    .left {
      width: 40%;
    }
    .right {
      grid-template-columns: 1fr;
    }
    .import {
      margin-top: 48px;
    }
    .language {
      margin-bottom: 48px;
    }
  }
}

@media screen and (max-width: 1400px) {
  .head-settings {
    flex-direction: column;
    gap: 24px;

    .left {
      width: 100%;
      margin-right: 0;
    }
    .right {
      width: 100%;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 50px;
    }
    .language,
    .import {
      width: 100%;
    }
    .import {
      margin-top: 0;
    }
  }
  .head-settings
    .head-settings
    .account-settings
    .head-settings
    .file-upload
    .file-upload__image-block {
    height: 250px;
    padding: 32px;
  }
}

@media screen and (max-width: 1200px) {
  .head-settings {
    .right {
      grid-template-columns: 100%;
    }
    .import {
      margin-top: 48px;
    }
  }
}

@media screen and (max-width: 650px) {
  .head-settings .import .form-import__upload-buttons,
  .head-settings .language .language-wrap .group-btn,
  .head-settings .import .form-import .group-btn {
    gap: 16px;
  }
}

@media screen and (max-width: 450px) {
  .head-settings {
    .import .form-import__upload-buttons {
      .btn-regular,
      .btn-tertiary {
        padding: 10px 16px;
        min-width: max-content;
      }

      .ic-file-upload {
        width: 16px;
        height: 16px;
      }
    }
  }
}
