@import "../theme-colors";

/*  POPUP
------------------------------------------------- */
.popup-overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(21, 39, 68, 0.4);
  z-index: -900;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.popup-overlay.opened {
  opacity: 1;
  z-index: 900;
}
.popup {
  position: fixed;
  top: 0;
  right: -300%;
  bottom: 0;
  z-index: 901;
  background: $main-bg-color;
  padding: 24px 32px;
  box-shadow: 0 4px 9px -2px #1018281a;
  display: flex;
  flex-direction: column;
  transition: all 0.5s ease-in;

  &-head {
    margin-bottom: 32px;
  }

  .closeBtn:hover {
    color: $text-links-color;
  }
}
.popup.opened {
  right: 0;
}
.popup .wrap {
  overflow-y: auto;
  height: 100%;
  margin-bottom: 20px;
}
.popup-import.popup .wrap {
  overflow-y: auto;
}
.popup .wrap {
  scrollbar-width: thin;
  scrollbar-color: $border-secondary-color #fff;
}
.popup .wrap::-webkit-scrollbar {
  width: 16px;
}
.popup .wrap::-webkit-scrollbar-track {
  background: #fff;
}
.popup .wrap::-webkit-scrollbar-thumb {
  width: 10px;
  background: $border-secondary-color;
  border-radius: 10px;
}
.popup-footer {
  margin-top: auto;
}
.popup-footer .group-btn {
  justify-content: flex-end;
}
.popup-footer .group-btn button + button,
.popup-footer .group-btn .btn-primary + button,
.popup-footer .group-btn .btn-primary + .btn-regular {
  margin-left: 20px;
}

.popup {
  .new-wrap {
    overflow-y: auto;
    height: 100%;
    margin-bottom: 20px;
    scrollbar-width: thin;
    scrollbar-color: $border-secondary-color #fff;
  }

  .new-wrap::-webkit-scrollbar {
    width: 16px;
  }
  .new-wrap::-webkit-scrollbar-track {
    background: #fff;
  }
  .new-wrap::-webkit-scrollbar-thumb {
    width: 10px;
    background: $border-secondary-color;
    border-radius: 10px;
  }

  // not used now, but if we have several page popups it will be needed for "Back" to prev step button
  .btn-back {
    background-color: transparent;
    font-size: 16px;
    justify-content: flex-start;
    color: $text-links-color;
    font-weight: 700;
    line-height: 24px;

    svg {
      display: inline-block;
      margin-right: 10px;
      width: 24px;
      height: 24px;
    }
  }

  .tabs {
    height: 100%;
  }

  .tabs .react-tabs__tab-panel--selected {
    height: 100%;
    padding-bottom: 60px;
  }

  .popup-panel-form {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 50px;
  }
}

.popup-small {
  max-width: 650px;
  width: 100%;
}

@media screen and (max-height: 1000px) {
  .popup {
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 800px) {
  .popup {
    padding: 24px;
  }
  .popup-footer .group-btn {
    justify-content: space-between;
  }
  .popup-small {
    max-width: 100%;
  }
}