@import "../theme-colors";

// Styles for radioButtons from rsuite library

.rs-radio-inner:before {
  border: 1px solid $border-secondary-color;
  background: #fff;
}
.rs-radio:hover .rs-radio-inner:before {
  border-color: $text-links-color;
  background: $main-bg-color;
}
.rs-radio.rs-radio-checked .rs-radio-inner:before {
  background-color: $main-bg-color;
  border-color: $text-links-color;
}
.rs-radio.rs-radio-checked .rs-radio-inner:after {
  background-color: $text-links-color;
}
.rs-radio [type=radio]:focus ~ .rs-radio-inner:before {
  box-shadow: 0 0 0 4px #EEE;
  border-color: $secondary-text-color;
  background-color: #fff;
}
.rs-radio.rs-radio-checked [type=radio]:focus ~ .rs-radio-inner:before {
  background-color: $main-bg-color;
  border-color: $text-links-color;
}
.rs-radio.rs-radio-disabled .rs-radio-inner:before {
  border-color: $border-secondary-color;
  background: $border-color;
}
.rs-radio.rs-radio-disabled.rs-radio-checked .rs-radio-inner:after {
  background-color: $border-secondary-color;
}
.rs-radio.rs-radio-disabled.rs-radio-checked .rs-radio-inner:before {
  opacity: 1;
}
.rs-radio-checker {
  padding-left: 42px;
}
.rs-radio-checker label {
  color: $text-links-color;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
}