@import "../../../../theme-colors";

.addNewRole {
  margin-top: 32px;
  max-width: 1200px;
  width: 100%;

  &__form {
    margin-top: 48px;
  }

  .rs-steps-item-icon-wrapper {
    font-size: 14px;
    font-weight: 700;
    line-height: normal;
  }

  .rs-steps-item-status-process .rs-steps-item-icon-wrapper {
    background-color: $text-links-color;
    border-color: $text-links-color;
    color: #fff;
  }

  .rs-steps-item-content {
    color: $text-links-color;
  }

  .rs-steps-item-status-process .rs-steps-item-title:after,
  .rs-steps-item-title:after {
    border-color: $border-secondary-color;
  }

  .rs-steps-item-title:after {
    border-top-width: 2px;
  }

  .rs-steps-item-status-wait .rs-steps-item-icon-wrapper {
    border: 2px solid $border-secondary-color;
    color: $secondary-text-color;
  }

  .rs-steps-item-status-finish .rs-steps-item-icon-wrapper {
    color: #fff;
    background-color: $secondary-text-color;
  }

  .rs-steps-vertical .rs-steps-item-tail {
    border-left-width: 2px;
    border-color: $border-secondary-color;
  }

  &__btnGroup {
    margin-top: 30px;
    width: max-content;
    display: flex;
    gap: 20px;
    margin-left: auto;
  }

  &__stepBtn {
    min-width: 100px;
  }
}

@media screen and (max-width: 350px) {
  .addNewRole {
    &__btnGroup {
      flex-direction: column;
    }
  }
}