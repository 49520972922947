@import "../../theme-colors";

.achievementsAnalytics {
  &__charts {
    margin-top: 40px;
    display: grid;
    grid-template-columns: 46% 46%;
    column-gap: 100px;
    row-gap: 32px;

    .emptyChartData__msg.no-content-message {
      text-align: left;
    }
  }

  .achievementsCategoryChart,
  .categoriesByAchievementsChart {
    margin-bottom: 10px;
    .title-block {
      margin-bottom: 24px;
    }
  }

  &__filtersWrap {
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 24px;
    margin-bottom: 24px;
  }
}

.peopleEngagementChart {
  padding-top: 24px;
  border-top: 1px solid $secondary-text-color;
  .employeeEngagementTooltip {
    &__statusWrap {
      justify-content: flex-start;
      gap: 10px;
    }
    &__badge {
      border-radius: 50%;
      width: 10px;
      height: 10px;
    }
  }
}

.achievementsRating {
  padding-top: 24px;
  border-top: 1px solid $secondary-text-color;

  .title-block {
    margin-bottom: 24px;
  }

  .table-wrap .table-header {
    border-bottom: 1px solid $secondary-text-color;
  }

  .table-wrap .table-inner {
    min-width: 950px;

    .item-col:nth-child(1) {
      width: 5%;
    }
    .item-col:nth-child(2) {
      width: 40%;
    }
    .item-col:nth-child(3) {
      width: 15%;
    }
    .item-col:nth-child(4) {
      width: 12%;
    }
    .item-col:nth-child(5) {
      width: 8%;
    }
    .item-col:last-child {
      text-align: left;
    }
  }
}

@media screen and (max-width: 1600px) {
  .achievementsAnalytics {
    &__charts {
      grid-template-columns: 48% 48%;
      column-gap: 50px;
    }
  }

  @media screen and (max-width: 1400px) {
    .achievementsAnalytics {
      &__charts {
        grid-template-columns: 100%;
        max-width: 800px;
      }
    }
  }
}
