@import "../../../theme-colors";

.merchDeliveryInfoPage {
  .merchDeliveryInfoDesktop {
    display: grid;
    grid-template-columns: 60% 37%;
    column-gap: 32px;
    row-gap: 48px;

    .employeeOrdersTable {
      margin-top: 48px;
    }
  }

  .merchDeliveryInfoTablet {
    display: none;
  }

  .employeeOrdersTable {
    .table-wrap .table-inner {
      min-width: 880px;
    }
    .item-th {
      display: flex;
      align-items: center;
    }
  }

  .boxItemImg {
    padding: 24px;
    max-width: 248px;
    width: 100%;
  }

  p + p {
    margin-top: 0;
  }

  .merchDeliveryHistoryPanel {
    &__currentTitle {
      font-size: 16px;
      text-align: center;
      margin-bottom: 20px;
    }

    .userBlock {
      justify-content: flex-start;
    }

    .merchDeliveryHistoryPanel__content {
      .merchDeliveryHistoryPanel__delete {
        margin-left: auto;
      }
    }
  }
  .actionBtn-tertiary {
    margin-left: 16px;
  }
  .deliveryInfoBlock {
    &__item {
      grid-template-columns: 35% auto;
      align-items: center;
      column-gap: 32px;
    }
  }
}

.employeeOrdersTable {
  .table-wrap {
    margin: 0;
  }

  .table-wrap .table-inner .item-col:nth-child(1) {
    width: 100%;
    min-width: 26%;
  }
  .table-wrap .table-inner .item-col:nth-child(2),
  .table-wrap .table-inner .item-col:nth-child(3) {
    min-width: 20%;
    width: 100%;
  }
  .table-wrap .table-inner .item-col:nth-child(4) {
    min-width: 17%;
    width: 100%;
  }
  .table-wrap .table-inner .item-col:nth-child(5) {
    min-width: 17%;
    width: 100%;
  }
  .table-wrap .item-col:last-child {
    text-align: left;
  }
}

.receivedItemsWrap {
  display: grid;
  grid-template-columns: repeat(2, 250px);
  column-gap: 32px;
  row-gap: 24px;
}

.seeMoreReceivedItemsBtn {
  margin: 32px auto 0;
}

@media screen and (max-width: 1600px) {
  .merchDeliveryInfoPage {
    .merchDeliveryInfoDesktop {
      grid-template-columns: 60% 36%;
    }
  }
}

@media screen and (max-width: 1500px) {
  .merchDeliveryInfoPage {
    .merchDeliveryInfoTablet {
      display: flex;
      flex-direction: column;
      gap: 48px;
    }

    .receivedItemsWrap {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
    }

    .merchDeliveryInfoDesktop {
      display: none;
    }
  }
}

@media screen and (max-width: 500px) {
  .merchDeliveryInfoPage {
    .deliveryInfoBlock {
      &__radio {
        margin-bottom: 16px;
      }
    }
  }
}
