@import "../theme-colors";

.checkboxLabel {
  display: inline-flex;
  align-items: center;
  user-select: none;
  color: $text-links-color;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;

  &::before {
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid $border-secondary-color;
    border-radius: 4px;
    background-color: #fff;
    margin-right: 16px;
    background-repeat: no-repeat;
    background-position: center center;
  }
}
.checkboxInput {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkboxInput:checked + .checkboxLabel::before {
  border-color: $text-links-color;
  background-color: $main-bg-color;
  background-image: url("../../sprite/checmark.svg");
}
.checkboxInput:not(:disabled):not(:checked) + .checkboxLabel:hover::before {
  border-color: $text-links-color;
}
.checkboxInput:focus + .checkboxLabel::before {
  box-shadow: 0 0 0 4px #eee;
}
.checkboxInput:focus:not(:checked) + .checkboxLabel::before {
  border-color: $secondary-text-color;
}
.checkboxInput:disabled + .checkboxLabel::before {
  border: 1px solid $border-secondary-color;
  background-color: $border-color;
}
.checkboxInput:disabled + .checkboxLabel {
  cursor: not-allowed;
}
.checkboxInput:checked:disabled + .checkboxLabel::before {
  background-image: url("../../sprite/checkmarkDisabled.svg");
}