@import "../../theme-colors";

.timeOffPolicyTypes {
  .pageTitleBlock {
    margin-bottom: 24px;
  }
  
  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(289px, 1fr));
    gap: 32px;
    grid-auto-flow: dense;
    grid-auto-rows: 172px;
    flex: 1;
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 172px;
    padding: 16px;

    cursor: pointer;
    transition: max-height 0.3s ease-out;
    &:hover {
      max-height: 364px;
      height: max-content;
      transition: max-height 0.3s ease-in;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  &__description {
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 16px;
    max-height: 100px;
    overflow: hidden;

    .ql-editor {
      padding: 0;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    margin-top: 24px;
  }

  &__policies {
    font-weight: 700;
  }

  .policyTypesIcon {
    width: 28px;
    height: 28px;
    margin-right: 16px;
  }
}

.popup-timeOffPolicyType {
  max-width: 630px;
  width: 100%;

  .addEditTimeOffPolicyTypeForm {
    .wrap {
      padding: 0 16px 100px 0;
    }

    &__iconsBlock {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      .policyTypesIconBtn {
        border-radius: 10px;
        width: 44px;
        padding: 0;
      }
    }

    .customTextEditor .ql-editor {
      min-height: 80px;
    }

    .policyTypesNameLabel,
    .label + .label {
      margin-top: 32px;
    }

    .wrap .timeOffsLabel {
      margin-top: 16px;
    }

    .colorSelect {
      width: 250px;
    }

    &__divider {
      margin-top: 48px;
      width: 100%;
      height: 2px;
      background-color: $border-secondary-color;
    }

    &__selectBadge {
      margin: 32px 0;
    }

    .policyTypeMsgTitle {
      font-weight: 700;
      color: $main-text-color;
      margin: 32px 0 16px 0;
    }

    .policyTypeMsgInfo {
      color: $secondary-text-color;
      margin-bottom: 32px;
    }

    .feedbackBadgesWrap {
      margin-bottom: 8px;
      padding-left: 8px;
    }

    .policyTypesIcon {
      width: 24px;
      height: 24px;
      margin: 0;
    }
  }
}

.timeOffPolicyTypePage {
  display: grid;
  grid-template-columns: 1fr 30%;
  gap: 32px;

  .customBreadcrumbs {
    margin-bottom: 0;
  }

  &__policyTypeInfo {
    .policyTypeInfoMenu {
      margin-bottom: 24px;
    }

    .customBreadcrumbs {
      display: none;
    }

    .createNewPolicyBtn {
      width: max-content;
      margin-left: auto;
    }

    .policyTypeInfoWrap {
      display: flex;
      gap: 8px;
      align-items: center;

      .svg-icon {
        width: 24px;
        height: 24px;
      }
    }

    .policyTypeIcon {
      width: 28px;
      height: 28px;
    }

    .ql-editor {
      padding: 0;
    }

    .quill {
      margin-bottom: 32px;
    }

    .policyTypeParams {
      display: flex;
      align-items: center;
      gap: 32px;
    }

    .policyTypeParamsTimeOffs {
      margin-bottom: 32px;
    }
  }

  &__policiesList {
    .pageTabs {
      margin-bottom: 0;
    }
    .timeOffPoliciesWrap {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      justify-content: space-between;
      gap: 32px;

      .timeOffPolicyCard {
        padding: 16px;
        height: max-content;
        cursor: pointer;

        &__date {
          font-size: 12px;
          font-weight: 400;
          line-height: 20px;
          color: $secondary-text-color;
        }

        &__name {
          font-size: 20px;
          font-weight: 400;
          line-height: 28px;
          margin-bottom: 16px;
        }

        &__buttons {
          display: flex;
          align-items: center;
          gap: 8px;
        }

        &__criteriaWrap {
          display: flex;
          flex-wrap: wrap;
          gap: 8px;
          margin-bottom: 16px;
        }

        &__criteria {
          padding: 2px 10px;
          border-radius: 40px;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          color: $text-links-color;
        }

        .criteria__locations {
          background-color: $button-selected-color;
          border: 1px solid $links-secondary-color;
        }

        .timeOffPolicyAssignPeople {
          padding: 4px 8px;
          margin-bottom: 24px;

          .user-avatar {
            width: 24px;
            height: 24px;
            min-width: 24px;
          }

          .user-avatar .avatarSign {
            font-size: 8px;
          }

          .user-name,
          .changeCountUsers {
            font-size: 12px;
            line-height: 18px;
          }
        }

        .viewPolicyBtn {
          height: 0;
          transition: height 0.3s;
          overflow: hidden;
          margin-top: 24px;

          .btn-regular {
            height: 0;
            padding: 0;
          }
        }
      }

      .timeOffPolicyCard:hover {
        .viewPolicyBtn {
          height: 44px;
          overflow: visible;

          .btn-regular {
            height: 44px;
            padding: 10px 32px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .timeOffPolicyTypePage {
    gap: 50px;
  }
}

@media screen and (max-width: 1400px) {
  .timeOffPolicyTypePage {
    display: flex;
    flex-direction: column-reverse;

    &__policyTypeInfo {
      .customBreadcrumbs {
        display: flex;
      }
    }

    &__policiesList {
      .customBreadcrumbs {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .timeOffPolicyTypePage {
    &__policiesList {
      .timeOffPoliciesWrap {
        .timeOffPolicyCard {
          .viewPolicyBtn {
            height: 44px;

            .btn-regular {
              height: 44px;
              padding: 10px 32px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .popup.popup-timeOffPolicyType {
    max-width: 100%;

    .addEditTimeOffPolicyTypeForm {
      padding-bottom: 70px;
    }
  }
  .timeOffPolicyTypes {
    &__list {
      gap: 24px;
    }
  }
  .timeOffPolicyTypePage {
    .policyTypeParams {
      .title {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .timeOffPolicyTypes {
    &__list {
      grid-auto-rows: auto;
    }
    &__item {
      padding: 16px;
      max-height: max-content;
    }
  }

  .popup-timeOffPolicyType {
    .addEditTimeOffPolicyTypeForm {
      .colorSelectWrap {
        flex-direction: column;
        align-items: flex-start;

        .colorSelect {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .timeOffPolicyTypePage {
    &__policyTypeInfo {
      .policyTypeInfoMenu {
        align-items: flex-start;
        gap: 32px;
        flex-direction: column;
      }
      .createNewPolicyBtn {
        width: 100%;
      }
      .ql-editor {
        font-size: 14px;
        line-height: 20px;
      }
      .policyTypeParamsTimeOffs {
        margin-bottom: 24px;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .timeOffPolicyTypes {
    .pageTitleBlock {
      flex-direction: column;
      align-items: flex-start;

      .btn-primary {
        margin-top: 32px;
        width: 100%;
      }
    }
  }
}
