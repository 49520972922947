@import "../../theme-colors";

.newsCalendar {
  margin-top: 0;
  margin-bottom: 48px;
  display: block;

  &__content {
    max-width: 534px;
    width: 100%;
  }
  &__tooltip-block {
    position: absolute;
    top: 0;
    width: 24px;
    height: 24px;
    z-index: 1;
  }
  .main-title {
    margin-bottom: 32px;
  }
  .box {
    padding: 0;
  }
  .rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar {
    border-bottom: none;
  }
  .rs-calendar-header-title {
    padding: 5px 10px;
  }
  .rs-calendar-table-cell-content {
    position: relative;
    height: 24px !important;

    &:hover {
      background-color: initial;
    }
  }
  .rs-calendar-table-cell-day {
    margin-top: 2px !important;
  }
  .rs-calendar
    .rs-calendar-table-cell-selected
    .rs-calendar-table-cell-content {
    border-radius: 40px;
    background-color: $text-links-color;
    width: 24px;
    height: 24px !important;
    color: $blocks-bg-color !important;
    pointer-events: inherit;
  }
  .rs-calendar-table-cell-selected {
    pointer-events: inherit;

    span {
      color: $blocks-bg-color !important;
    }
  }
  .rs-calendar-panel .rs-calendar-table-cell.oneDay {
    pointer-events: inherit;

    .rs-calendar-table-cell-content {
      margin: 10px auto 0;
      border-radius: 40px;
      background-color: $secondary-text-color;
      width: 24px;
      height: 24px !important;
      color: $blocks-bg-color !important;
      transition: all 0.5s;

      &:hover {
        background-color: $text-links-color !important;
        transition: all 0.5s;
      }
    }
  }
  .rs-calendar-table-cell-is-today {
    div:first-child {
      margin: 10px auto 0;
      background-color: $text-links-color !important;
      border-radius: 40px;
      width: 24px;
      height: 24px;
      span {
        color: $blocks-bg-color !important;
      }
      &:hover {
        background-color: $text-links-color;
      }
    }
  }
  .rs-calendar
    .rs-calendar-table-cell-un-same-month
    .rs-calendar-table-cell-content {
    visibility: hidden;
  }
}
.newsCalendarTooltip {
  &__title-block {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    margin: 8px 0 2px;

    &:first-of-type {
      margin-top: 0;
    }
  }

  &__small-title {
    color: $secondary-text-color;
    font-size: 12px;
    line-height: 18px;
    margin-left: 4px;
    display: block;
    font-weight: 400;
  }

  &__text {
    color: $text-links-color;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__userCard {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 2px;
  }

  &--min-width {
    min-width: 240px;

    @media (max-width: 800px) {
      min-width: 150px;
    }
  }
  .user-avatar {
    width: 16px;
    height: 16px;
    min-width: auto;
    margin-right: 4px;
  }
  .avatarSign {
    font-size: 10px;
    line-height: 15px;
  }
}

@media (max-width: 1300px) {
  .newsCalendar {
    margin-bottom: 32px;
  }
}
