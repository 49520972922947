.sketch-picker {
  position: absolute;
  z-index: 1000;
}

.popup-customTheme {
  .customThemeForm {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 50px;

    .wrap {
      background-color: transparent;
      display: block;
      padding: 0;
    }

    .columnWrap {
      display: flex;
      gap: 100px;
      padding-top: 20px;
      padding-right: 20px;
    }
    .colorPickerBlock {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
    }
    .labelWrap {
      display: flex;
      align-items: center;
    }
    .numberField + .labelField {
      margin-top: 0;
    }
    .numberField {
      margin-right: 10px;
    }
    .colorNumber,
    .numberField {
      min-width: 20px;
      min-height: 20px;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      border: 1px solid #3d3d3dd6;
      color: #3d3d3dd6;
      padding: 3px;
      border-radius: 50%;
    }
    .colorDisplay {
      width: 36px;
      height: 14px;
      border-radius: 2px;
    }
    .swatch {
      position: relative;
      margin-left: 15px;
      padding: 5px;
      background: #fff;
      border-radius: 1px;
      box-shadow: 0 0 0 1px rgba(0,0,0,.1);
      display: inline-block;
      cursor: pointer;
    }
  }
  .graphics-activity {
    margin: 0 20px 0 0;

    .react-tabs__tab-list {
      justify-content: flex-end;
    }
  }
  .previewBlock {
    position: relative;
    padding: 35px;
    border: 1px solid #ebf2f7;
    background-color: var(--mainBgColor);

    .filtersBlock .group,
    .box,
    .btn-regular,
    .btn-primary {
      position: relative;
    }

    .colorNumber {
      position: absolute;
    }

    .mainBgColor {
      top: 10px;
      left: 10px;
    }

    .formBgColor {
      top: -24px;
      left: 10px;
    }

    .secondaryBgColor {
      bottom: 3px;
      left: 8px;
    }

    .boxShadowMainColor {
      bottom: 0;
      left: 46px;
    }

    .boxShadowSecondaryColor {
      bottom: -30px;
      left: 40px;
    }

    .btn-search {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      box-shadow: 0 10px 16px var(--boxShadowMainColor);
    }
  }
  .previewChart {
    margin-top: 10px;
    position: relative;
    border: 1px solid #ebf2f7;
    padding: 20px;

    .barChartMainColor {
      position: absolute;
      bottom: 107px;
      left: 125px;
      z-index: 3;
    }

    .barChartSecondaryColor {
      position: absolute;
      bottom: 107px;
      left: 208px;
      z-index: 3;
    }
  }
}

@media screen and (max-width: 1300px) {
  .popup-customTheme {
    .customThemeForm {
      .columnWrap {
        flex-direction: column;
        gap: 0;
      }

      .previewBlock {
        margin-right: 20px;
        padding: 30px;

        .secondaryBgColor {
          bottom: 0;
          left: 0;
        }

        .mainBgColor {
          top: 3px;
          left: 3px;
        }
      }
    }
    .previewChart {
      .barChartMainColor {
        left: 110px;
      }

      .barChartSecondaryColor {
        left: 155px;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .popup-customTheme {
    .customThemeForm {
      .previewBlock {
        .box {
          padding: 16px;
        }
        .boxShadowSecondaryColor {
          bottom: 10px;
          left: -25px;
        }
      }
  }
    .previewChart {
      padding: 10px 10px 10px 0;

      .recharts-default-legend {
        display: flex;
        flex-direction: column;
      }

      .barChartMainColor {
        top: 15px;
        right: 50px;
        left: initial;
      }

      .barChartSecondaryColor {
        top: 40px;
        left: initial;
        right: 50px;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .popup-customTheme {
    .customThemeForm {
      .colorPickerBlock {
        justify-content: space-between;
      }
      .previewBlock {
        .filter-row {
          gap: 0;
        }
      }
      .previewChart {
        .barChartMainColor,
        .barChartSecondaryColor {
          right: 5px;
        }
      }
    }
  }
}