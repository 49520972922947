@import "../../../theme-colors";

.achievementCategoryPage {
  &__popover {
    padding: 16px 24px;
  }
  &__coverBlock {
    position: relative;

    .categoryMenuBtn {
      position: absolute;
      top: 24px;
      right: 24px;
      background-color: $blocks-bg-color;
      border: 1px solid $border-color;

      &:hover {
        background-color: $border-color;
      }
    }
  }
  .coverImageWrap {
    margin-bottom: 32px;
  }
  .customBreadcrumbs {
    margin-bottom: 24px;
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr 535px;
    column-gap: 125px;
  }

  .sortAchievementsBtn {
    text-align: left;
  }

  .categoryAchievements {
    &__header {
      flex-wrap: wrap;
      gap: 20px;
    }

    &__control {
      gap: 8px;
    }

    .pageTabs {
      margin-bottom: 0;
    }

    &__btns {
      display: flex;
      align-items: center;
    }

    &__divider {
      height: 24px;
      border: 0.5px solid $secondary-text-color;
      margin: 0 24px;
    }
  }

  .categoryDescription {
    margin-bottom: 50px;

    .ql-editor {
      padding: 0;
    }
  }

  &__achievements {
    margin-top: 32px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;
  }

  &__column {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__mobileList {
    display: none;
  }
}

.achievementCard {
  cursor: pointer;
  transition: box-shadow 0.3s;
  &:hover {
    transition: box-shadow 0.3s;
    box-shadow: 0 20px 24px -4px #10182814;
  }

  &__imgWrap {
    position: relative;
    border-radius: 10px;
    width: 100%;
    height: 260px;
    border: 1px solid $border-secondary-color;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
  }

  &__indicators {
    position: absolute;
    top: 16px;
    width: 100%;
    padding: 0 16px;
  }

  &__statusLabel {
    margin-left: auto;
  }

  &__img {
    border-radius: 10px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__info {
    flex-wrap: wrap;
    gap: 16px;
  }

  &__label {
    padding: 4px 16px;
    border-radius: 40px;
    border: 1px solid $border-secondary-color;
    background: $main-bg-color;
    color: $text-links-color;
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;

    &--complete {
      color: $blocks-bg-color;
      background-color: $blue-color;

      .ic-checkmark,
      .ic-coins {
        margin-right: 10px;
      }
    }

    .ic-hourglass {
      margin-right: 10px;
    }
  }

  &__coins {
    font-weight: 700;
  }

  &__coinsRight {
    margin-left: auto;
  }

  &__footer {
    flex-wrap: wrap;
    gap: 16px;
  }

  .achievementLock {
    display: flex;
    padding: 4px 6px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: $blocks-bg-color;
    color: $text-links-color;
    box-shadow: 0 4px 9px -2px rgba(16, 24, 40, 0.1);
  }

  .achievementLockPrivate {
    color: $blocks-bg-color;
    background-color: $links-color;
  }

  &__list-label {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 16px;
  }

  .ic-coins {
    margin-right: 10px;
  }
}

.achievementListCard {
  display: flex;
  flex-direction: column;
  height: max-content;
}

@media screen and (min-width: 2400px) {
  .achievementCategoryPage {
    &__achievements {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

@media screen and (min-width: 2048px) and (max-width: 2400px) {
  .achievementCategoryPage {
    &__achievements {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@media screen and (max-width: 1800px) {
  .achievementCategoryPage {
    &__content {
      grid-template-columns: 65% 30%;
      column-gap: 50px;
    }
  }
}

@media screen and (max-width: 1350px) {
  .achievementCategoryPage {
    .categoryAchievements {
      margin-top: 32px;
    }

    &__content {
      display: flex;
      flex-direction: column-reverse;
    }
  }
}

@media screen and (max-width: 800px) {
  .achievementCard {
    padding: 16px;
    flex-direction: column;

    &__imgWrap,
    &__info {
      width: 100%;
    }

    &__coins,
    &__name {
      text-align: center;
    }
  }
}

@media screen and (max-width: 600px) {
  .achievementCategoryPage {
    &__achievements {
      grid-template-columns: 1fr;
    }

    &__column {
      display: none;
    }

    &__mobileList {
      display: block;

      .achievementCard {
        margin-bottom: 24px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .achievementCategoryPage {
    .categoryAchievements {
      &__control {
        width: 100%;
      }
      .pageTabs {
        &__hiddenLink {
          display: block;
        }
        &__popoverLink {
          display: none;
        }
      }
      &__popover.placement-bottom-end {
        padding: 8px;
        transform: translate(-28px, 16px);
      }
    }
  }
}
