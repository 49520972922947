@import "../theme-colors";

.autoEvent_mention {
  &__control {
    font-family: "Open Sans", sans-serif;
    min-height: 200px;
    background-color: #fff;
    border: 2px solid $border-secondary-color;
    border-radius: 10px;
  }

  &__highlighter {
    padding: 15px;
    border: 1px solid transparent;
  }

  &__input {
    font-family: "Open Sans", sans-serif;
    padding: 15px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;

    &::placeholder {
      font-family: "Open Sans", sans-serif;
      color: $secondary-text-color;
    }
  }

  &__suggestions {
    border-radius: 10px;

    &__list {
      background-color: #fff;
      border: 1px solid $border-secondary-color;
      border-radius: 10px;
      font-size: 14px;
    }

    &__item {
    padding: 5px 15px;
    border-bottom: 1px solid $border-secondary-color;
    font-family: "Open Sans", sans-serif;

      &--focused {
        background-color: $main-bg-color;
      }
    }

    &__item:first-child {
      border-radius: 10px 10px 0 0;
    }

    &__item:last-child {
      border-bottom: none;
      border-radius: 0 0 10px 10px;
    }
  }
}

.mentionComponent {
  background-color: $border-secondary-color;
}