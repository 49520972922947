.ratingList {
  &__header {
    display: grid;
    grid-template-columns: 20% auto 25%;
    align-items: center;
    column-gap: 20px;
    padding: 0 20px 20px;
    letter-spacing: 0.5px;
    margin: 0;

    li:last-child {
      text-align: right;
    }
  }

  .ratingCard {
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: 20% auto 25%;
    align-items: center;
    column-gap: 20px;
    padding: 20px;

    &__name {
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
    }

    &__rating {
      font-weight: bold;
      font-size: 18px;
    }

    &__countWrap {
      text-align: right;
    }

    &__count {
      font-size: 18px;
      font-weight: bold;
    }

    &__countLabel {
      display: none;
    }
  }
}

@media screen and (max-width: 1300px) {
  .ratingList {
    .ratingCard {
      grid-template-columns: 20% 1fr auto;
    }
  }
}

@media screen and (max-width: 800px) {
  .ratingList {
    &__header {
      column-gap: 10px;
      padding: 0 16px 16px;
    }

    .ratingCard {
      column-gap: 10px;
      padding: 16px;

      &__rating {
        font-size: 16px;
      }

      &__count {
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .ratingList {
    &__header {
      display: none;
    }

    .ratingCard {
      display: flex;
      gap: 10px;
      flex-direction: column;
      justify-content: center;

      &__name {
        text-align: center;
      }

      &__countLabel {
        display: inline-block;
        margin-right: 10px;
      }
    }
  }
}