@import "../../../theme-colors";

.popup.addMerchPopup {
  max-width: 650px;
  width: 100%;
  padding-left: 16px;

  .popup-head {
    padding-left: 16px;
  }

  .new-wrap {
    padding: 0 16px;
  }

  .merchImages {
    max-width: max-content;
  }

  .merchNewCategory {
    .form-textarea {
      padding: 10px 14px;
    }
  }

  .btn-additional {
    margin-top: 16px;
  }

  .popup-panel-form {
    margin-top: 10px;
    padding-bottom: 90px;
  }

  .merchItemQuantityInfo {
    align-self: flex-end;
    color: $secondary-text-color;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;

    p + p {
      margin-top: 5px;
    }

    &__details {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
    }

    &__count {
      font-weight: 700;
    }
  }
  .merchItemQuantityErrorMobile {
    display: none;
  }

  .ql-editor {
    padding: 16px 15px;
  }
}

@media screen and (max-width: 800px) {
  .popup.addMerchPopup {
    max-width: 100%;

    .popup-panel-form {
      padding-bottom: 110px;
    }
    .merchItemQuantityErrorMobile {
      display: block;
    }
    .merchItemQuantityErrorDesktop {
      display: none;
    }
  }
}
