@import "../theme-colors";

.emojiBlock {
  &__wrap {
    display: flex;
    align-items: center;
    gap: 12px;
    flex-wrap: wrap;
  }

  &__emoji {
    display: flex;
    padding: 6px 12px;
    align-items: center;
    gap: 12px;
    border-radius: 75px;
    border: 1.5px solid $border-secondary-color;
    background: $main-bg-color;
    transition: box-shadow 0.3s;

    &:hover {
      box-shadow: 0 4px 9px -2px rgba(16, 24, 40, 0.10), 0px 4px 9px -2px rgba(16, 24, 40, 0.10);
    }
  }

  &__emoji.selected {
    background-color: $border-secondary-color;
  }

  &__count {
    color: $text-links-color;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  &__img {
    width: 24px;
    height: 24px;
  }

  &__addEmoji {
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__addEmojiIcon {
    width: 24px;
    height: 24px;
  }
}

.usersReactionsTooltip {
  padding: 8px 12px;
  box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  max-height: 100px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: $border-secondary-color $blocks-bg-color;

  &::-webkit-scrollbar {
    width: 16px;
  }
  &::-webkit-scrollbar-track {
    background: $blocks-bg-color;
  }
  &::-webkit-scrollbar-thumb {
    width: 10px;
    background: $border-secondary-color;
    border-radius: 10px;
  }

  &__user {
    display: flex;
    align-items: center;
    margin-bottom: 2px;
  }

  &__user:last-child {
    margin-bottom: 0;
  }

  &__userAvatar {
    width: 16px;
    height: 16px;
    min-width: 16px;
    margin-right: 4px;
  }

  .avatarSign {
    font-size: 9px;
  }

  &__userName {
    color: $text-links-color;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }
}

.emojisTooltip {
  padding: 8px 12px;
  box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);

  &__wrap {
    display: flex;
    gap: 10px;
  }

  &__btn {
    background-color: transparent;
  }

  &__img {
    width: 24px;
    height: 24px;
  }
}

@media screen and (max-width: 1200px) {
  .usersReactionsTooltip {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .emojisTooltip {
    &__wrap {
      flex-direction: column;
    }
  }
}