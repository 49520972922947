.achievement-categories {
  margin-top: 32px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  justify-content: space-between;
  gap: 32px;
  width: 100%;

  .achievement-category {
    padding: 24px;
    height: 363px;
    transition: box-shadow 0.3s;
    cursor: pointer;

    &:hover {
      transition: box-shadow 0.3s;
      box-shadow: 0px 20px 24px -4px #10182814;
    }

    &__title {
      margin-top: 24px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__img {
      object-fit: cover;
      width: 100%;
      height: 259px;
      border-radius: 10px;
    }

    &__symbol {
      display: inline-block;
      margin-right: 16px;
    }
  }
}

.add-category-popup {
  max-width: 650px;
  width: 100%;

  .add-category-form {
    justify-content: space-between;
    display: flex;
    align-items: initial;
    flex-direction: column;
    height: 100%;
    padding-bottom: 70px;

    .file-upload__image {
      object-fit: cover;
    }

    .categoryName {
      .form-textarea {
        padding: 10px 14px;
      }
    }
  }

  .wrap {
    padding-right: 16px;
  }

  .achievement-category__description {
    padding: 20px;
    resize: none;
  }

  .customTextEditor .ql-editor {
    min-height: 80px;
  }
  .colorSelectWrap {
    padding-top: 8px;

    .field-label-required {
      margin: 0;
    }
  }
  .colorSelect  {
    min-width: 250px;
  }
}

@media screen and (max-width: 600px) {
  .achievement-categories {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }

  .add-category-popup {
    .colorSelectWrap {
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;

      .field-label-required {
        margin-bottom: 16px;
      }
    }
    .colorSelect {
      min-width: 100%;
    }
  }
}