@import "../theme-colors";

// styles for CheckTree component from rsuite library used on AccessManagement tab (Roles page)

.rs-check-tree {
  .rs-check-tree-view {
    max-height: max-content;
    height: 100% !important;
    overflow-y: visible;
  }

  .rs-check-tree-node-children {
    .rs-check-tree-node .rs-check-tree-node-text-wrapper {
      font-size: 16px;
      color: $main-text-color;
    }

    .rs-check-tree-children .rs-check-tree-node-text-wrapper {
      font-size: 14px;
      color: $text-links-color;
    }
  }

  .rs-checkbox-control:before  {
    border-radius: 4px;
    border: 1px solid $border-secondary-color;
    background: #fff;
  }

  .rs-check-item.rs-check-item-focus,
  .rs-check-item .rs-checkbox-checker > label:before {
    background-color: transparent;
  }

  .rs-check-tree-node > .rs-check-tree-node-label .rs-check-tree-node-label-content {
    white-space: initial;
  }

  .rs-checkbox-checked .rs-checkbox-control .rs-checkbox-inner:after {
    border-color: $text-links-color;
  }

  .rs-checkbox-checked .rs-checkbox-control .rs-checkbox-inner:before {
    background-color: $main-bg-color;
    border-color: $text-links-color;
  }

  label:hover .rs-checkbox-control .rs-checkbox-inner:before {
    border-color: $text-links-color;
  }

  label:hover .rs-checkbox-control input {
    cursor: pointer;
  }

  .rs-check-tree-node .rs-check-item.rs-checkbox-checked .rs-checkbox-checker > label {
    font-weight: normal;
  }

  .rs-check-item:not(.rs-checkbox-disabled):hover {
    background-color: transparent;
    color: $text-links-color;
  }

  .rs-check-tree-node .rs-check-item-focus .rs-checkbox-label,
  .rs-check-tree-node .rs-check-item:hover .rs-checkbox-label {
    background-color: transparent;
    color: $text-links-color;
  }
}