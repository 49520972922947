@import "../theme-colors";

.rs-modal-wrapper:has(> .cropImageModal) {
  display: flex;
  align-items: center;
}

.cropImageModal {
  width: 866px;
  margin: 0 auto;

  .divider {
    width: 100%;
    height: 1px;
    background-color: $border-secondary-color;
  }

  .divider.bottom {
    margin-bottom: 16px;
  }

  &__body {
    margin-top: 0;
    padding: 0 28px;
  }

  .rs-modal-content {
    border-radius: 10px;
  }

}

.cropImageModal.squareCrop {
  width: 560px;

  .cropModal {
    .avatarCropper,
    .cropper {
      height: 400px;
      width: 400px;
      margin: 0 auto;
    }
  }

  .uploadedImageWrap {
    .uploadImgWrap {
      margin: 0;
    }
    
    &__img {
      width: 400px;
      height: 400px;
    }
  }

  .zoomSlider {
    margin-top: 24px;
  }
}

.cropModal {
  .cropper {
    width: 100%;
    height: 400px;
  }

  .cropper-modal {
    background-color: $blocks-bg-color;
    opacity: 0.75;
  }

  .cropper-view-box {
    outline: 3px solid $blue-label-text;
    outline-color: rgba(84, 149, 189, 75%);
  }

  .avatarCropper {
    .cropper-crop-box,
    .cropper-view-box {
      border-radius: 50%;
    }

    /* The css styles for `outline` do not follow `border-radius` on iOS/Safari (#979). */
    .cropper-view-box {
      outline: 0;
      box-shadow: 0 0 0 1px $blue-label-text;
    }
  }

  .zoomSlider {
    margin-top: 32px;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: $text-links-color;

    &__title {
      margin-bottom: 10px;
    }

    .zoomSlider__slider {
      width: 100%;
  
      .rs-slider-bar {
        height: 4px;
        background-color: $border-color;
      }
      .rs-slider-handle:before {
        border: 1px solid $blue-label-text;
        width: 10px;
        height: 10px;
        margin-left: 0;
      }
      .rs-slider-progress-bar {
        background-color: $blue-label-text;
      }
      .rs-slider-handle:focus:before,
      .rs-slider-handle:hover:before {
        box-shadow: 0 0 0 8px rgba(84, 149, 189, 0.1);
      }
    }
  }

  .cropTitle {
    text-align: center;
    font-size: 24px;
    line-height: 32px;
    font-weight: bold;
    color: $main-text-color;
    margin-bottom: 12px;
  }

  .uploadImgWrap {
    margin: 32px 0;
  }

  .uploadedImageWrap {
    .uploadImgWrap {
      margin: 0;
    }

    .d-flex {
      margin: 32px 0;
      justify-content: center;
      gap: 12px;
    }

    &__img {
      display: block;
      margin: 0 auto;
      height: 300px;
      width: 100%;
      object-fit: cover;
    }
  }

  .uploadedImageWrap.active {
    margin-top: 12px;

    .d-flex {
      margin: 12px 0;
    }
  }

  .uploadImage {
    max-width: 300px;
    margin: 0 auto;
  }

  .imageRequirements {
    margin-bottom: 12px;
    text-align: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $secondary-text-color;

    p + p {
      margin-top: 0;
    }
  }

  .cropperWrap {
    margin-top: 32px;
  }

  &__footer {
    padding-top: 16px;
  }

  &__footerCentered {
    justify-content: center;
    gap: 32px;
  }
}

@media screen and (max-width: 800px) {
  .cropModal {
    .cropTitle {
      font-size: 16px;
    }
  }
}

@media screen and (max-height: 830px) {
    .cropImageModal.squareCrop {
      .cropModal {
        .avatarCropper,
        .cropper {
          height: 300px;
          width: 300px;
        }
      }

      .cropperWrap {
        margin-top: 16px;
      }
    
      .uploadedImageWrap {
        &__img {
          width: 300px;
          height: 300px;
        }
      }
    
      .zoomSlider {
        margin-top: 16px;
      }
    }
  }

  @media screen and (max-height: 720px) {
    .cropImageModal.squareCrop {
      .cropModal {
        .avatarCropper,
        .cropper {
          height: 200px;
          width: 200px;
        }
      }
    
      .uploadedImageWrap {
        &__img {
          width: 200px;
          height: 200px;
        }
      }
    }
  }

  @media screen and (max-height: 770px) {
    .cropImageModal {
      .cropModal {
        .cropperWrap,
        .zoomSlider {
          margin-top: 16px;
      }
        .cropper {
          height: 350px;
        }
      }
    
      .uploadedImageWrap {
        &__img {
          height: 200px;
        }
      }
    }
  }
