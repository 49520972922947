@import "../theme-colors";

.magicButtonWrap {
  position: relative;
}

.magicButton {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 32px;
  padding: 8px 16px;
  width: 141px;
  border-radius: 20px;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  background: linear-gradient(141.97deg, #A540FF 4.96%, #FE00B7 120.06%);
  transition: all 0.5s;

  .iconsWrap {
    display: inline-flex;
    width: max-content;
    opacity: 1;
    transition: all 0.3s;
  }

  .magicUnion {
    width: 21px;
    height: 24px;
    margin: 0 10px;
    transition: all 0.3s;
  }

  .magicStar {
    width: 7px;
    height: 7px;
    opacity: 1;
    transition: all 0.3s;
  }

  .magicText {
    display: inline-block;
    font-size: 16px;
    line-height: 16px;
    color: $blocks-bg-color;
    opacity: 0;
    text-indent: -75px;
    transition: all 0.3s;
  }
}

.magicButton:hover,
.magicButton.gradient,
.magicButton.active:hover {
  background-image: linear-gradient(142.27deg, #3A70C1 4.92%, #A540FF 31.57%, #FFA2A2 61.6%, #FE00B7 89.52%);
  background-size: 200% auto;

  .magicStar {
    width: 0;
    opacity: 0;
  }

  .magicUnion {
    margin-left: 0;
  }

  .magicText {
    opacity: 1;
    text-indent: 0;
  }
}

.magicButton:hover {
  background-position: right center;
}

.magicButton.gradient {
  animation: gradient_move 3s ease;
}

.magicButton:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  width: 16px;
  flex: 0 0 16px;
  height: 16px;
  margin-left: -8px;
  margin-top: -8px;
  background-repeat: no-repeat;
  background-position: center;
}

.magicButton:after {
  transform: rotate(-30deg);
  transition: transform 0.3s linear 0s, opacity 0.08s linear 0s;
  background-size: auto;
  background-image: url(../../sprite/magicButton/magicButtonClose.svg);
  opacity: 0;
}

.magicButton.active {
  background-color: $blue-label-text;
  background-image: none;
  animation: none;
}

.magicButton.active .iconsWrap {
  opacity: 0;
  transform: rotate(30deg) scale(0);
}

.magicButton.active:after {
  transform: rotate(0deg);
  opacity: 1;
}

.magicButton__content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 6px;
  position: absolute;
  top: 46px;
  right: 0;
  z-index: 2;
  visibility: hidden;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;

  .magicOption {
    display: flex;
    width: max-content;
    align-items: center;
    padding: 8px 16px;
    gap: 12px;
    border-radius: 40px;
    background-color: $blue-label-text;
    box-shadow: 0 4px 9px -2px #10182833;
    letter-spacing: -0.02em;
    color: $blocks-bg-color;
    background-size: 200% auto;
    transition: 0.5s;
  }

  .magicOption:hover {
    background-position: right center;
    background-image: linear-gradient(142.27deg, #3A70C1 4.92%, #A540FF 31.57%, #FFA2A2 61.6%, #FE00B7 89.52%);
  }

  .magicOptionIcon {
    width: 20px;
    height: 20px;
  }
}

.magicButton__content.fadeInUpMagicOptions {
  visibility: visible;
}

.fadeInUpMagicOptions {
  -webkit-animation-name: fadeInUpMagicOptions;
  animation-name: fadeInUpMagicOptions;
}

@keyframes fadeInUpMagicOptions {
  0% {
    opacity: 0;
    transform: translate3d(0, 200%, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes gradient_move {
  0% {
    background-position: 0 92%
  }

  50% {
    background-position: 100% 9%
  }

  100% {
    background-position: 0 92%
  }
}

@media screen and (max-width: 1200px) {
  .magicButton {
    margin-left: 22px;
  }
}