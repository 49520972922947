/*  These styles used on UserForm, CompanyForm, AddBadgeForm popups
------------------------------------------------- */
.add-company-user {
  width: 100%;
  max-width: 700px;
}
.popup-company {
  max-width: 650px;
  width: 100%;
}
.form-block-title {
  margin-bottom: 16px;
  margin-top: 32px;
  color: #000;
  font-family: Open Sans, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

.columnBlock {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 32px;
  row-gap: 24px;
}
.columnBlock label + label {
  margin-top: 0;
}

.popup-company .checkboxWrap {
  display: grid;
  grid-template-columns: 48% 48%;
  column-gap: 20px;

  .checkboxLabel {
    display: flex;
    margin-bottom: 10px;
  }
}

.popup {
  .form-new-member {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 50px;

    .wrap {
      padding: 0 16px 24px 0;
    }

    .file-upload {
      margin-bottom: 32px;
    }

    .adminSettings {
      margin-bottom: 48px;
    }

    .btn-additional {
      margin-top: 16px;
    }
  }
}

@media screen and (max-width: 800px) {
  .popup-company {
    max-width: 100%;
    width: 100%;
  }
  .columnBlock {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 600px) {
  .popup-company .checkboxWrap {
    display: block;
  }
  .popup .form-new-member {
    padding-bottom: 100px;
  }
}
