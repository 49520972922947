@import "../theme-colors";

.customBreadcrumbs {
  display: flex;
  color: $text-links-color;
  margin-bottom: 52px;

  .rs-breadcrumb-separator {
    margin: 0 8px;
  }

  .breadcrumbLink {
    color: $text-links-color;
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    align-items: flex-start;
  }
  .breadcrumbLink.active {
    font-weight: 700;
  }
  .breadcrumbsIcon {
    width: 24px;
    height: 24px;
    transform: rotate(-90deg);
  }
}

@media screen and (max-width: 400px) {
  .customBreadcrumbs {
    flex-wrap: wrap;
  }
}