.popup {
    .badge-wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow: auto;
    }
  
    .form-feedback {
      height: 100%;
      display: flex;
      flex-direction: column;
      padding-bottom: 60px;
    }
  }
  
  .popup-badge .feedbackBadgesWrap {
    display: grid;
    grid-template-columns: 250px 250px;
    column-gap: 32px;
    row-gap: 16px;
    margin-bottom: 24px;
  }
  .popup-badge .feedbackBadge {
    padding: 24px;
    transition: box-shadow 0.3s;
  
    &:hover {
      box-shadow: 0 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
    }
  
    .boxItemImg__imgWrap {
      position: relative;
    }
  
    &__icon {
      visibility: hidden;
      opacity: 0;
      position: absolute;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      background-color: $text-links-color;
      bottom: -13px;
      transition: opacity 0.3s;
  
      &::after {
        content: url("../../sprite/badgeCheckmark.svg");
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        left: 5px;
        top: 3px;
      }
    }
  }
  .popup-badge .feedbackBadge.active {
    box-shadow: 0 4px 9px -2px rgba(16, 24, 40, 0.10), 0px 2px 13px -2px rgba(84, 148, 189, 0.75), 0px 4px 9px -2px rgba(84, 148, 189, 0.25);
  
    .feedbackBadge__icon {
      visibility: visible;
      opacity: 1;
    }
  }
  .popup-badge .feedbackBadge.active:hover {
    .feedbackBadge__icon {
      &::after {
        left: 1px;
        top: 1px;
        content: url("../../sprite/badgeClose.svg");
      }
    }
  }
  
  .wrap-item {
    padding: 10px 0 32px 0;
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
  }

@media screen and (max-width: 600px) {
  .popup.popup-badge .feedbackBadgesWrap {
    grid-template-columns: 250px;
    width: max-content;
    margin: 0 auto 24px;
  }
}
  