@import "../../../theme-colors";

/*  popups EditUserRole on Assign role tab and Assign Role History on Access Management tab
------------------------------------------------- */
.popup-roles-history,
.popup-role-edit {
  max-width: 650px;
  width: 100%;
}
.popup-role-edit .wrap {
  margin: 0 0 30px;
}

.popup {
  .form-assign-role {
    justify-content: space-between;
    display: flex;
    align-items: initial;
    flex-direction: column;
    height: 100%;
  }
}
.userName-role {
  font-size: 18px;
  font-weight: 700;
  color: $text-links-color;
}
.popup-roles-history {
  .timelineDatePoint {
    max-width: 140px;
    width: 100%;
    display: inline-block;
    margin-right: 10px;
    color: $secondary-text-color;
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    font-style: normal;
    line-height: 24px;
  }
  .timelineItemContent {
    color: $text-links-color;
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  .rs-timeline-item-content {
    display: flex;
  }
  .rs-timeline-item-last .rs-timeline-item-dot:before {
    background-color: $text-links-color;
  }
  .rs-timeline-align-left .rs-timeline-item {
    padding-left: 44px;
  }
  .rs-timeline-item-dot:before {
    width: 12px;
    height: 12px;
    background-color: $border-secondary-color;
  }
  .rs-timeline-align-left .rs-timeline-item-tail {
    left: 5px;
    background-color: $border-secondary-color;
  }
  .btn-regular {
    margin-left: auto;
  }
}

@media screen and (max-width: 800px) {
  .popup-roles-history {
    .rs-timeline-item-content {
      flex-direction: column;
      gap: 10px;
    }
    .timelineDatePoint {
      margin-right: 0;
      max-width: max-content;
    }
    .rs-timeline-align-left .rs-timeline-item {
      padding-left: 24px;
    }
  }
}
