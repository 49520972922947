@import "../theme-colors";

.customPhoneInput {
  font-family: "Open Sans", sans-serif !important;
  color: $main-text-color;

  &__input {
    width: 100% !important;
    border: 2px solid $border-secondary-color !important;
    border-left: 1px solid $border-secondary-color !important;
    height: 44px !important;
    background-color: $blocks-bg-color !important;
    border-radius: 0 8px 8px 0 !important;
    font-size: 14px !important;
    line-height: 24px;

    &:disabled {
      border-color: $border-color !important;
      color: $secondary-text-color;
    }
    &::placeholder {
      color: $secondary-text-color !important;
    }
  }

  .react-international-phone-country-selector-dropdown {
    background-color: $blocks-bg-color;
    border: 2px solid $border-secondary-color;
    border-radius: 8px;
    box-shadow: none;
    margin: 0 0 10px -1px;
    padding: 0;

    .react-international-phone-country-selector-dropdown__list-item {
      background-color: $blocks-bg-color;
      padding: 7px 9px;
      cursor: pointer;
      
      transition: background-color 0.3s;

      &:hover {
        background-color: $main-bg-color;
      }

      .react-international-phone-country-selector-dropdown__list-item-dial-code,
      .react-international-phone-country-selector-dropdown__list-item-country-name {
        font-size: 16px;
        left: 24px;
      }
      .react-international-phone-country-selector-dropdown__list-item-country-name {
        color: $main-text-color;
      }
      .react-international-phone-country-selector-dropdown__list-item-dial-code {
        color: $secondary-text-color;
      }

      .react-international-phone-country-selector-dropdown__list-item-flag-emoji {
        width: 16px;
        height: 16px;
      }
    }

    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-track {
      background: $blocks-bg-color;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      width: 10px;
      background: $border-secondary-color;
      border-radius: 10px;
    }
  }
  .country.highlight {
    background-color: $main-bg-color !important;
  }
  .react-international-phone-country-selector {
    border: 2px solid $border-secondary-color !important;
    border-right: 1px solid $border-secondary-color !important;
    border-radius: 8px 0 0 8px !important;
    background-color: $border-color !important;
    width: 42px;
    &:hover {
      background-color: $main-bg-color !important;
    }

    .react-international-phone-country-selector-button {
      background-color: inherit;
      border: none;
      margin: 2px 4px 0 6px;
    }

    .react-international-phone-country-selector-button__flag-emoji {
      width: 16px;
      height: 16px;
    }
  }

  .react-international-phone-country-selector-button__dropdown-arrow--disabled {
    display: none;
  }

  .react-international-phone-country-selector-button--disabled:hover,
  .react-international-phone-country-selector:hover {
    background-color: $border-color !important;
  }
}
