.companyCalendar {
  display: grid;
  grid-template-columns: 1fr 450px;
  grid-template-areas: "first-block second-block";
  column-gap: 32px;

  .avatarSign {
    font-size: 8px;
  }

  &__header {
    margin: 32px 0 24px;

    .actionBtn-regular {
      padding: 0;
    }
  }

  &__calendar {
    max-width: 1100px;
    width: 100%;
    margin-bottom: 48px;

    position: relative;
    overflow-x: auto;
    grid-area: first-block;

    .rs-calendar {
      padding-top: 0;
      min-width: 1100px;
    }

    .rs-calendar-table {
      border-radius: 10px;
    }

    .rs-picker-popup
      .rs-calendar
      .rs-calendar-month-dropdown-cell-active
      .rs-calendar-month-dropdown-cell-content:hover {
      background-color: $text-links-color !important;
    }

    .rs-calendar-panel .rs-calendar-month-dropdown {
      top: 82px;
      left: 182px;
      background: transparent;
      border-color: transparent;
    }

    .rs-calendar-table-header-row {
      border-top: 0.5px solid $border-color;
    }

    .rs-calendar-table-header-cell {
      border: 0.5px solid $border-color;
      max-width: 157px;
      width: 100%;
      height: 32px;
      background: $blocks-bg-color;
      font-size: 12px;
      line-height: 16px;

      .rs-calendar-table-header-cell-content {
        color: $main-text-color;
        font-weight: bold;
      }

      &:first-child {
        border-radius: 10px 0 0 0;
      }

      &:last-child,
      &:nth-child(6n) {
        background-color: $beige-color;
      }

      &:last-child {
        border-radius: 0 10px 0 0;
      }
    }

    .rs-calendar-table-cell-content {
      max-width: 157px;
      padding: 0;
      width: 100%;
      cursor: default;

      &:hover {
        background: transparent;
      }
    }
    .rs-calendar-panel.rs-calendar-compact
      .rs-calendar-table-row:not(.rs-calendar-table-header-row)
      .rs-calendar-table-cell-content {
      height: 157px;
      background: $blocks-bg-color;
    }

    .rs-calendar-body {
      padding: 24px 0 0;
    }

    .rs-calendar-table-row {
      &:nth-child(6n) {
        border-bottom: 0.5px solid $border-color;

        .rs-calendar-table-cell:first-child .rs-calendar-table-cell-content {
          border-radius: 0 0 0 10px;
        }

        .rs-calendar-table-cell:last-child .rs-calendar-table-cell-content {
          border-radius: 0 0 10px 0;
        }
      }
    }

    .rs-calendar-bordered
      .rs-calendar-table-row:not(:last-child)
      .rs-calendar-table-cell {
      border-bottom: none;
    }

    .rs-calendar-table-cell-content {
      border: 0.5px solid $border-color;
    }

    .rs-calendar-panel .rs-calendar-table-cell {
      padding: 0;
      position: relative;
    }

    .rs-calendar-table-cell .rs-calendar-table-cell-is-today {
      background-color: $light-green-color !important;
      border-radius: 0;
    }

    .rs-calendar-panel .rs-calendar-table-cell-day {
      display: none;
    }

    .rs-calendar
      .rs-calendar-table-cell-is-today
      .rs-calendar-table-cell-content {
      box-shadow: none;
      border: 2px solid $green-color !important;
      background-color: $light-green-color !important;
      border-radius: 0;
    }

    .rs-calendar-panel
      .rs-calendar-table-cell-is-today
      .rs-calendar-table-cell-day {
      color: $main-text-color;
      text-align: left;
      margin: 0;
      background-color: $light-green-color;
    }

    .rs-calendar
      .rs-calendar-table-cell-selected
      .rs-calendar-table-cell-content {
      background: transparent;
      border: none;
      box-shadow: none;
      color: $main-text-color;
    }

    .rs-calendar-table-row {
      .rs-calendar-table-cell:not(
          .rs-calendar-table-cell-un-same-month
        ):nth-last-child(-n + 2) {
        .rs-calendar-table-cell-content {
          background: $beige-color !important;
        }
      }
    }

    .rs-calendar {
      .rs-calendar-table-cell-un-same-month {
        .rs-calendar-table-cell-content {
          background: inherit !important;
          color: $secondary-text-color;
          border: 0.5px solid $border-color !important;
        }
      }
    }

    .rs-calendar-bordered .rs-calendar-table {
      border: 1px solid $border-color;
    }

    .rs-calendar-header-has-month {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: row-reverse;
      padding: 0;
    }

    .rs-calendar-panel .rs-calendar-header .rs-calendar-btn-today {
      height: 44px;
      padding: 12px 18px;
      font-size: 14px;
      line-height: 20px;
      color: $main-text-color;
      font-weight: bold;
      background-color: $border-color;
      border-radius: 40px;
      margin-right: 12px;
    }

    .rs-calendar-header-has-month:not(.rs-calendar-header-has-time)
      .rs-calendar-header-month-toolbar {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .rs-calendar-header-error {
      color: $main-text-color !important;
    }

    .rs-calendar
      .rs-calendar-table-cell-selected
      .rs-calendar-table-cell-content {
      border: 0.5px solid $border-color;
      border-radius: 0;
    }

    .datePickers__calendarIcon {
      color: $text-links-color;
      width: 20px;
      height: 20px;
    }

    .rs-picker .rs-input-group {
      border: none;
      border-radius: 10px;
      background-color: $border-color;
    }

    .rs-picker-date > .rs-input-group.rs-input-group-inside .rs-input {
      cursor: pointer;
    }

    .rs-input:focus {
      outline: none;
    }

    .rs-input-group-lg > .rs-input {
      padding: 12px 0 12px 24px;
      font-size: 16px;
      line-height: 20px;
      color: $text-links-color;
      height: 44px;
      background-color: transparent;
    }
    .rs-picker .rs-input-group:focus-within,
    .rs-picker
      .rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus {
      background-color: $border-color;
    }
    .rs-input-group.rs-input-group-lg > .rs-input-group-addon {
      padding: 12px 12px 12px 0;
    }

    .rs-calendar-header-has-month {
      display: none;
    }

    &--filter-btn {
      position: relative;
      left: 1055px;
      top: 32px;
      z-index: 10;
      margin-bottom: -45px;
    }

    &--filter-icon {
      width: 24px;
      height: 24px;
    }
  }

  &__calendar-header {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 32px;
  }

  &__today-btn {
    font-size: 14px;
    line-height: 20px;
    color: $text-links-color;
    font-weight: bold;
    border-radius: 40px;
    width: max-content;
    padding: 12px 18px;
    margin-right: 4px;
  }

  &__day {
    line-height: 16px;
    font-size: 16px;
  }

  &__today {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__people {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    padding: 8px 8px 0;
  }

  &__month-dropdown {
    max-width: 174px;
    height: 44px;
    width: 100%;
    padding: 0;
  }

  &__counter {
    background: $blue-birthday-color;
    max-width: 86px;
    height: 22px;
    border-radius: 40px;
    padding: 4px 4px 4px 8px;
    gap: 6px;
    align-items: center;
    cursor: pointer;
    border: none;
    margin-bottom: 4px;

    transition: all 0.5s;

    &--gradient {
      background: linear-gradient(
        142.27deg,
        #3a70c1 4.92%,
        #a540ff 31.57%,
        #ffa2a2 61.6%,
        #fe00b7 89.52%
      );

      &:hover {
        animation: abruptGradient 1s linear;
      }

      .companyCalendar__icon {
        color: $blocks-bg-color;
      }
    }

    &--bg-yellow {
      background-color: $yellow-border-label-color;
    }

    &:hover {
      box-shadow: none !important;
      background: $text-links-color;
      color: $blocks-bg-color;
    }

    .user-avatar {
      width: 14px;
      height: 14px;
      min-width: fit-content;

      img {
        width: 12px;
      }
    }
  }

  &__people-count {
    font-size: 10px;
    line-height: 14px;
    margin: 0 6px 0 -4px;
  }

  &__avatars-block {
    display: flex;
    align-items: center;
  }

  &__user-block {
    margin-right: 6px;
  }

  &__icon {
    width: 12px;
    height: 12px;
  }

  &__popover {
    .pageTabs__link {
      justify-content: flex-start;
    }
    .pageTabs__link.selected {
      font-size: 24px;
      font-weight: bold;
    }
    &.placement-bottom-end {
      z-index: 1050;
    }
  }

  &__right-panel {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    gap: 32px;
    grid-area: second-block;
  }
}

.rs-picker-popup {
  margin-top: 4px;
}

.rs-picker-popup .rs-calendar {
  min-width: 280px;
}

.rs-calendar-month-view
  .rs-calendar-header-title-date.rs-calendar-header-error {
  color: $main-text-color !important;

  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    color: $main-text-color !important;
  }
}

.rs-calendar-header-title-date.rs-calendar-header-error:hover:active {
  color: $main-text-color !important;
}

@keyframes abruptGradient {
  0% {
    background: linear-gradient(
      142.27deg,
      #3a70c1 4.92%,
      #a540ff 31.57%,
      #ffa2a2 61.6%,
      #fe00b7 89.52%
    );
  }
  100% {
    background: linear-gradient(142.27deg, $text-links-color);
  }
}

@media screen and (min-width: 1921px) {
  .companyCalendar {
    &__calendar {
      max-width: 100%;
    }

    &__calendar--filter-btn {
      left: 96.5%;
    }
    .rs-calendar-table-header-cell,
    .rs-calendar-table-cell-content {
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 1920px) {
  .companyCalendar {
    column-gap: 16px;
  }
}

@media screen and (max-width: 1900px) {
  .companyCalendar {
    grid-template-columns: 1fr;
    grid-template-areas:
      "second-block"
      "first-block";

    &__right-panel {
      display: grid;
      grid-template-columns: 450px 1fr;
      gap: 24px;
    }

    &__calendar {
      max-width: 100%;
    }

    &__calendar--filter-btn {
      left: 96.5%;
    }

    .rs-calendar-table-header-cell,
    .rs-calendar-table-cell-content {
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 1440px) {
  .companyCalendar {
    &__calendar--filter-btn {
      left: 1055px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .companyCalendar {
    &__right-panel {
      grid-template-columns: 1fr;
    }
  }
}
