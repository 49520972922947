@import "../theme-colors";

.achievementTimeline {
  margin-top: 32px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    .achievementCard__label {
      width: max-content;
    }
  }

  .achievementCalendar,
  .achievementSingleCalendar {
    max-width: 535px;
  }

  .rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar {
    border-bottom: 0.8px solid $border-secondary-color;
  }

  .rs-calendar-header-title {
    padding: 0 10px 16px;
  }

  .rs-calendar-panel .rs-calendar-table-cell {
    padding: 0;
    pointer-events: none;
  }
  .rs-calendar-panel .rs-calendar-table-cell-day {
    margin-top: 0;
  }

  .rs-calendar .rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
    box-shadow: none;
  }
  .rs-calendar-panel .rs-calendar-table-cell-is-today .rs-calendar-table-cell-day {
    margin: 0;
    border-radius: 0;
    width: 100%;
    background-color: transparent;
    color: #000;
  }

  .rs-calendar-panel .rs-calendar-table-cell.timelineCell {
    .rs-calendar-table-cell-day {
      background-color: $text-links-color;
      color: $blocks-bg-color;
      width: 100%;
    }
  }

  .rs-calendar-panel .rs-calendar-table-cell.timelineCell.startDate {
    .rs-calendar-table-cell-day {
      border-top-left-radius: 40px;
      border-bottom-left-radius: 40px;
    }
  }

  .rs-calendar-panel .rs-calendar-table-cell.timelineCell.endDate {
    .rs-calendar-table-cell-day {
      border-top-right-radius: 40px;
      border-bottom-right-radius: 40px;
    }
  }

  .rs-calendar-panel .rs-calendar-table-cell.timelineCell.rs-calendar-table-cell-un-same-month {
    .rs-calendar-table-cell-day {
      background-color: $secondary-text-color;
    }
  }

  .rs-calendar-panel .rs-calendar-table-cell.timelineCell.rs-calendar-table-cell-un-same-month.nextMonthDay {
    .rs-calendar-table-cell-content {
      background-color: $text-links-color;
    }
    .rs-calendar-table-cell-day {
      border-top-left-radius: 40px;
      border-bottom-left-radius: 40px;
    }
  }

  .rs-calendar-panel .rs-calendar-table-cell.timelineCell.nextMonthDay {
    .rs-calendar-table-cell-content {
      background-color: $secondary-text-color;
      border-top-right-radius: 40px;
      border-bottom-right-radius: 40px
    }
    .rs-calendar-table-cell-day {
      border-top-left-radius: 40px;
      border-bottom-left-radius: 40px;
    }
  }

  .rs-calendar-panel .rs-calendar-table-cell.timelineCell.nextMonthDay.startDate {
    .rs-calendar-table-cell-content {
      background-color: #fff;
    }
  }

  .rs-calendar-panel.rs-calendar-compact .rs-calendar-table-row:not(.rs-calendar-table-header-row) .rs-calendar-table-cell-content {
    margin-top: 10px;
    min-height: 20px;
    padding: 0;
  }

  .rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-backward,
  .rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-forward,
  .rs-calendar-panel .rs-calendar-header .rs-calendar-btn-today {
    display: none;
  }

  .rs-calendar-header-month-toolbar {
    float: none;
    pointer-events: none;
  }

  .rs-calendar .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    border-radius: 0;
    background: transparent;
    box-shadow: none;
  }
}

@media screen and (max-width: 1400px) {
  .achievementTimeline {
    display: flex;
    gap: 20px;
    justify-content: space-between;

    &__header {
      flex-direction: column;
      justify-content: flex-start;
      gap: 16px;
    }
  }
}

@media screen and (max-width: 1000px) {
  .achievementTimeline {
    flex-direction: column;
    gap: 0;

    &__header {
      width: 100%;
      flex-direction: row;
      align-items: center;
    }

    .achievementSingleCalendar {
      margin: 0 auto;
    }

    .achievementCalendar {
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 500px) {
  .achievementTimeline {
    .achievementCalendar {
      flex-direction: column;
    }
  }
}