@import "../../theme-colors.scss";

.teamTimeOffsCalendar {
  &__menu {
    margin-bottom: 32px;
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: flex-start;
    gap: 24px;
  }

  .customFiltersFormWrap {
    margin-bottom: 0;
    min-width: 650px;
    justify-self: end;
    align-items: flex-start;
  }

  &__monthBtnsWrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;

    .select__container {
      width: 210px;

      .select__control {
        background-color: $border-color;
        border: 2px solid $border-color;
        border-radius: 10px;
      }
    }
  }

  &__todayBtn {
    padding: 12px 18px;
    width: max-content;
    border-radius: 40px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
  }

  &__monthBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;

    .arrowIconLeft,
    .arrowIconRight {
      width: 20px;
      height: 20px;
    }

    .arrowIconLeft {
      transform: rotate(90deg);
    }

    .arrowIconRight {
      transform: rotate(-90deg);
    }
  }
}

.timeOffCalendarWrap {
  display: flex;
  position: relative;

  .loading-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
    border-radius: 8px;
    justify-content: center;
    background-color: rgba(255,255,255,0.8);
  }

  &__user {
    width: 380px;
    height: 63px;
    display: flex;
    border-bottom: 1px solid $border-color;
  }

  .userBlock {
      display: flex;
      align-items: center;
      line-height: 100%;
  
      .user-avatar {
        margin-right: 16px;
        min-width: 48px;
        width: 48px;
        height: 48px;
      }

      .userDetails {
        overflow: hidden;

        p + p {
          margin-top: 8px;
        }
      }
  
      .userName {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
      }
  
      .userPosition {
        font-size: 12px;
        line-height: 18px;
        color: $secondary-text-color;
      }
    }
  }
  
  .timelineSidebarHeader {
    padding-bottom: 19px;
    border-bottom: 1px solid $border-color;
  }

  .tableWrap {
    overflow: hidden;
    position: relative;
  }
  
  .timeOffCalendar {
    overflow-x: auto;
    display: block;
    padding-bottom: 24px;

    &::-webkit-scrollbar {
        height: 8px;
    }
    &::-webkit-scrollbar-track {
      background: $blocks-bg-color;
    }
    &::-webkit-scrollbar-thumb {
      width: 10px;
      background: $border-secondary-color;
      border-radius: 10px;
    }

    &__headerDateCellWrap {
        padding-bottom: 19px;
        padding-right: 8px;
        border-bottom: 1px solid $border-color;
    }
  
    .customDateCell {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      min-width: 50px;
      height: 44px;
      border-radius: 5px;
      background-color: $blocks-bg-color;

      p + p {
        margin-top: 0;
      }
    }

    .customDateCell.customWeekendCell {
        background-color: #FFF3D6;

        .dayWeek {
          color: $yellow-border-label-color;
        }
      }

    .customDateCell.customTodayCell {
      background-color: $blue-label-text;

      .dayNumber {
        font-weight: 700;
      }

      .dayNumber,
      .dayWeek {
        color: $blocks-bg-color;
      }
    }

    .dayNumber {
      font-size: 14px;
      line-height: 20px;
      color: $text-links-color;
    }

    .dayWeek {
      font-size: 10px;
      line-height: 16px;
      color: $secondary-text-color;
    }

  &__userDateCellWrap {
    padding: 6px 8px 6px 0;
    border-bottom: 1px solid $border-color;
  }

  .userDateCell {
      min-width: 50px;
      height: 50px;
      border-radius: 5px;
      background-color: $blocks-bg-color;
  }

  .userDateCell.userWeekendCell {
      background-color: #FFFDF6;
  }

  .userDateCell.userTodayCell {
      background-color: $button-selected-color;
  }

  &__userDateRow {
    position: relative;
  }

  &__itemContainer {
      display: block;
  }
  
  .timeOffItem {
    position: absolute;
    height: 50px;
    top: 5px;
    border-radius: 5px;
    padding: 5px 10px;
    overflow: hidden;
    white-space: nowrap;
    background: linear-gradient(90deg, #7DD8E9 0%, #CCF7FF 100%); // default color of timeOffIntervalItems

    &__policyInfo {
      justify-content: flex-start;
    }

    &__policyIcon {
      min-width: 18px;
      width: 18px;
      height: 18px;
      margin-right: 8px;
    }

    &__policyName {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      color: $text-links-color;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &__interval {
      font-size: 10px;
      line-height: 16px;
      margin-top: 5px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .timeOffItem.yellow {
    background: linear-gradient(90deg, #FFDD8A 0%, #FFFAEE 100%);
  }
  .timeOffItem.orange {
    background: linear-gradient(90deg, #FFAF57 0%, #FFDFBB 100%);
  }
  .timeOffItem.red {
      background: linear-gradient(90deg, #FFA5A0 0%, #FFE9E8 100%);
  }
  .timeOffItem.blue {
      background: linear-gradient(90deg, #7DD8E9 0%, #CCF7FF 100%);
  }
  .timeOffItem.green {
      background: linear-gradient(90deg, #B1FFB0 0%, #E9FFE9 100%);
  }
  .timeOffItem.purple {
      background: linear-gradient(90deg, #D0A2FD 0%, #EEDDFF 100%);
  }
  .timeOffItem.indigo {
    background: linear-gradient(90deg, #B4ADFF 0%, #E6E4FF 100.01%);
  }
  .timeOffItem.pink {
    background: linear-gradient(90deg, #FF99D1 0%, #FFE6F4 100%);
  }
  .timeOffItem.turquoise {
    background: linear-gradient(90deg, #88F2EB 0%, #DAFFFD 100%);
  }
  .timeOffItem.primrose {
    background: linear-gradient(90deg, #F1FD59 0%, #FCFFD5 91.96%);
  }


  .timeOffItem__interval.yellow {
    color: $yellow-label-text;
  }
  .timeOffItem__interval.orange {
    color: $orange-label-text;
  }
  .timeOffItem__interval.red {
      color: $red-color;
  }
  .timeOffItem__interval.green {
      color: $green-color;
  }
  .timeOffItem__interval.blue {
      color: $blue-label-text;
  }
  .timeOffItem__interval.purple {
      color: $purple-label-text;
  }
  .timeOffItem__interval.indigo {
    color: $indigo-label-text;
  }
  .timeOffItem__interval.pink {
    color: $pink-label-text;
  }
  .timeOffItem__interval.turquoise {
    color: $turquoise-label-text;
  }
  .timeOffItem.primrose {
    color: $primrose-label-text;
  }
}

.tableWrap.tableWrapLeftShadow {
  &::before {
    display: block;
    left: 0;
    background: linear-gradient(270deg, rgba(250, 250, 250, 0) 0%, #FAFAFA 91%, #FAFAFA 100%);
    border-left: 2px solid $border-color; 
  }
}

.tableWrap.tableWrapRightShadow {
  &::after {
    display: block;
    right: 0;
    background: linear-gradient(90deg, rgba(250, 250, 250, 0) 0%, #FAFAFA 91%, #FAFAFA 100%); 
    border-right: 2px solid $border-color;
  }
}

.tableWrap:before, .tableWrap:after {
  height: calc(100% - 24px);
  display: none;
  content: "";
  position: absolute;
  z-index: 2;
  top: 0;
  width: 62px;
}

.timeOffCalendarPaging {
  margin-top: 17px;
  font-size: 10px;

  .rs-pagination-xs .rs-pagination-btn {
    font-size: 10px;
    line-height: 16px;
    border-radius: 4px;
    background-color: $border-color;
    color: $main-text-color;
    min-width: 20px;
    padding: 2px 4px;
    margin: 2px;
  }

  .rs-pagination-btn .rs-pagination-symbol {
    height: 16px;
  }

  .rs-pagination-btn.rs-pagination-btn-active {
    background-color: $text-links-color;
    color: $blocks-bg-color;
    border: none;
  }

  .rs-pagination-btn.rs-pagination-btn-active:focus,
  .rs-pagination-btn.rs-pagination-btn-active:hover {
    box-shadow: none;
  }

  .rs-pagination-btn:focus-visible {
    outline: none;
  }
}

@media screen and (max-width: 1600px) {
  .teamTimeOffsCalendar {
    &__menu {
      grid-template-columns: 1fr;
    }

    .customFiltersFormWrap {
      justify-self: start;
    }
  }
}

@media screen and (max-width: 1200px) {
  .teamTimeOffsCalendar {
    .customFiltersFormWrap {
      min-width: 100%;
    }
  }
  .timelineSidebarHeader {
    padding-right: 10px;

    .searchBar {
      max-width: 100%;
    }
  }
  .timeOffCalendarWrap {
    &__user {
      width: 250px;

      .userBlock {
        .userDetails {
          p + p {
            margin-top: 5px;
          }
        }
        .user-avatar {
          min-width: 40px;
          width: 40px;
          height: 40px;
        }

        .userName {
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .timeOffCalendarWrap {
    &__user {
      width: 200px;

      .userBlock {
        .user-avatar {
          display: none;
        }
      }
    }
  }
}